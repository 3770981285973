import { Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { createSelector, select, Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";

import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { ValidatorsUtil } from "@helper/validators-util";
import { attachDatamarkAccount, attachDatamarkAccountSuccess, attachDatamarkAccountError } from "@app/user/customization/customization-store/customization.actions";
import { UserState } from "@app/user/user.reducer";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";

@Component({
	selector: "app-data-mark-popup",
	templateUrl: "./data-mark-popup.component.html",
	styleUrls: ["./data-mark-popup.component.scss"]
})
export class DataMarkPopupComponent extends DefaultPopupComponent implements OnDestroy {
	@ViewChild(OverlayComponent, { static: true }) public overlayComponent?: OverlayComponent;
	@Input() public error?: string;
	public pending$: Observable<boolean | undefined>;
	public organizationUserState$: Observable<string | undefined>;
	public form: FormGroup;
	private unsubscribe$$ = new Subject<void>();
	private subscription?: Subscription;

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private actions: Actions,
		private userErrorsService: UserErrorsService,
	) {
		super();
		this.form = this.formBuilder.group({
			username: [null, [Validators.required]],
			password: [null, [Validators.required]]
		});

		const user = (appState: any): UserState => appState.user;
		const getPending = createSelector(user, (state: UserState): boolean | undefined => state.formPending);
		this.pending$ = this.store.pipe(select(getPending));

		const organizationUserState = createSelector(user, (state: UserState): string | undefined => state.organizationInfo?.dataMarkAccountLogin);
		this.organizationUserState$ = this.store.pipe(select(organizationUserState));
		this.organizationUserState$.pipe(takeUntil(this.unsubscribe$$)).subscribe(dataMarkLogin => this.form.patchValue({ username: dataMarkLogin || "" }, { emitEvent: false }));
	}

	public ngOnInit(): void {
		if (this.error) this.overlayComponent?.showNotification$(this.error || "", "error");
		this.userName.value ? this.userName.disable() : this.userName.enable();
	}

	public get userName(): FormControl {
		return this.form.controls.username as FormControl;
	}

	public bindSymbol(): void {
		if (this.form.invalid) {
			ValidatorsUtil.triggerValidation(this.form);
			return;
		}
		this.store.dispatch(attachDatamarkAccount(this.form.getRawValue()));

		this.actions.pipe(
			ofType(attachDatamarkAccountSuccess),
			take(1)
		).subscribe(() => {
			this.close();
		});

		this.actions.pipe(ofType(attachDatamarkAccountError), take(1)).subscribe((error) => {
			const errors = this.userErrorsService.errorExceptionMessage(error.error.error);
			this.overlayComponent?.showNotification$(errors, "error");
		});
	}

	public ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
