import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GridComponent } from "@shared/grid/grid.component";

@Component({
	selector: "app-goods-grid",
	templateUrl: "./goods-grid.component.html",
	styleUrls: ["./goods-grid.component.scss"]
})
export class GoodsGridComponent extends GridComponent<any> {
	@Input() public withSelectedItemsBlock = false;
	@Input() public hideOrderCode = false;
	@Input() public isEwaybillGoods = false;
	@Input() public gtin?: string;
	@Output() public actionOrderCode: EventEmitter<{ action: string; items: any }> = new EventEmitter<{ action: string; items: any }>();
	public get filteredList(): any[] {
		return this.items.filter(el => el?.tradeItemType !== 1);
	}

	public orderCode(): void {
		this.actionOrderCode.emit({ action: "orderCode", items: this.selectedItems });
	}

	public ngOnChanges(): void {
		if (this.gtin)
			super.selectedItem(this.items.find(el => el.gtin === this.gtin));
	}

	public selectedItem(item: any): void {
		if (item?.tradeItemType === 1 && !this.isEwaybillGoods) {
			return;
		}
		super.selectedItem(item);
	}

	public selectAllItems(documents: any[]): void {
		if (!documents.length) return;
		this.selectedItems = documents.filter(el => el?.tradeItemType !== 1);
		this.selectItems.emit(this.selectedItems);
	}
}
