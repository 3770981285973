
<div class="container">
	<main [formGroup]="form">

		<p class="error">Не удалось подключиться к SC-CryptoProxy на порт '{{port}}'.</p>
		<p>Проверьте пожалуйста запущена ли программа или укажите другой порт</p>

		<div class="column">
			<label class="required" i18n>Порт SC-CryptoProxy</label>
			<app-numberbox formControlName="portControl" name="Порт SC-CryptoProxy"></app-numberbox>
		</div>

	</main>

	<footer>
		<button appSkin="action" (click)="enter()"> Войти</button>
		<button i18n type="button" appSkin="simple no-shadow bold bordered" (click)="close()">Отмена</button>
	</footer>
</div>
