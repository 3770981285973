import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

export class AppDropdownContext {
	public $implicit: any = null;
	constructor(close: () => void) {
		this.$implicit = { close };
	}
}

@Directive({
	selector: "[appDropdown]"
})
export class DropdownDirective implements OnInit, OnDestroy {
	@Input("appDropdown") public dropdownTemplate?: TemplateRef<AppDropdownContext>;
	@Input("container") public mainBlock: any;
	private unsubscribe$$ = new Subject();
	private closeUnsubscribe$$ = new Subject();
	private context = new AppDropdownContext(this.close.bind(this));

	constructor(
		private viewContainerRef: ViewContainerRef,
		private elementRef: ElementRef
	) { }

	public ngOnInit(): void {
		this.viewContainerRef.clear();
		fromEvent(this.elementRef.nativeElement, "click").pipe(
			filter(() => this.viewContainerRef.length === 0),
			takeUntil(this.unsubscribe$$)
		).subscribe(() => {
			this.show();
			const parentBlock = this.topElement;

			if (this.mainBlock) {
				const mainBlockClientRect = this.mainBlock.getBoundingClientRect();
				const mainBlockBottom = mainBlockClientRect.bottom;

				const dropBlockClientRect = this.dropdownTemplate?.elementRef.nativeElement.previousElementSibling.getBoundingClientRect();
				const dropBlockHeight = dropBlockClientRect.height;
				const dropBlockBottom = dropBlockClientRect.bottom;

				if (dropBlockBottom + dropBlockHeight > mainBlockBottom) {
					const topStyle = `-${dropBlockHeight}px`;
					(this.dropdownTemplate?.elementRef.nativeElement.previousElementSibling as HTMLElement).style.top = topStyle;
				}
			}

			parentBlock.style.position = "relative";

			fromEvent(document, "click").pipe(
				takeUntil(this.closeUnsubscribe$$)
			).subscribe(targetElement => {
				const target = (targetElement.target as HTMLElement);
				if (!parentBlock.contains(target)) {
					this.close();
				}
			});
		});
	}

	public show(): void {
		if (this.dropdownTemplate) {
			const viewRef = this.viewContainerRef.createEmbeddedView<AppDropdownContext>(this.dropdownTemplate, this.context);
			viewRef.detectChanges();
		}
	}

	public close(): void {
		this.viewContainerRef.remove(0);
		const parentBlock = this.topElement;
		parentBlock.removeAttribute("style");
		this.closeUnsubscribe$$.next();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();

		this.closeUnsubscribe$$.next();
		this.closeUnsubscribe$$.complete();
	}

	private get topElement(): HTMLElement {
		return this.elementRef.nativeElement.parentElement;
	}
}
