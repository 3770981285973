import { Component } from "@angular/core";
import { ImportTradeItemListDto, TradeItemList } from "@app/user/marks/marks-store/marks-types";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";

@Component({
	selector: "app-mark-codes-report-popup",
	templateUrl: "./mark-codes-report-popup.component.html",
	styleUrls: ["./mark-codes-report-popup.component.scss"]
})
export class MarkCodesReportPopupComponent extends DefaultPopupComponent {
	public report?: ImportTradeItemListDto;
	public openTradeItemList = false;

	public get tradeItemList(): TradeItemList[] {
		return this.report?.tradeItemList?.length ? this.report?.tradeItemList.filter(el => !el.successful) : [];
	}

}
