import { Status } from "@helper/abstraction/status";
import { DocumentsParams, DocumentState, DocumentType, DocType } from "@helper/abstraction/documents";

export interface DocumentsFilterConstructorParams {
	documentTypeId: DocumentType;
	documentState: DocumentState;
	page?: number;
	size?: number;
	documentDateEnd?: Date;
	documentDateStart?: Date;
	processingStatusList?: Status[];
	documentNumber?: string;
	edocumentNumber?: string;
	partner?: Status;
	storage?: Status;
	documentType?: DocType;
	formType?: DocType;
}

export class DocumentsFilter implements DocumentsParams {
	public documentTypeId: DocumentType;
	public documentState: DocumentState; // Состояние документа: Черновик, Входящие, Исходящие

	public page: number;
	public size: number;
	public documentDateEnd: Date;
	public documentDateStart: Date;

	public processingStatusList?: Status[];
	public documentNumber?: string;
	public edocumentNumber?: string;
	public partner?: Status;
	public storage?: Status;
	public documentType?: DocType;
	public formType?: DocType;
	private createAt?: Date;

	constructor(filter: DocumentsFilterConstructorParams) {
		this.createAt = new Date();
		this.documentTypeId = filter.documentTypeId;
		this.documentState = filter.documentState;
		this.page = filter.page || 1;
		this.size = filter.size || 50;
		this.documentDateEnd = filter.documentDateEnd || new Date(this.createAt.getFullYear(), this.createAt.getMonth(), this.createAt.getDate(), 23, 59, 59, 59);
		this.documentDateStart = filter.documentDateStart || new Date(this.createAt.getFullYear(), this.createAt.getMonth() - 1, this.createAt.getDate(), 0, 0, 0, 0);
		this.processingStatusList = filter.processingStatusList;
		this.documentNumber = filter.documentNumber;
		this.edocumentNumber = filter.edocumentNumber;
		this.partner = filter.partner;
		this.storage = filter.storage;
		this.documentType = filter.documentType;
		this.formType = filter.formType;
	}
}
