import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appKeydown]'
})
export class KeydownDirective {
  @Input() public set limit(setting: { size: number, editable: boolean }) {
    this.limitDigit = setting.size;
    this.el.nativeElement.contentEditable = setting.editable ?? false;
    this.el.nativeElement.className = setting.editable && "content-editable-tag";
    // this.el.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  private limitDigit?: number;

  constructor(
    private el: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) { }

	@HostListener("keydown", ["$event"])
	public onKeyDown(e: any): void {
    if (e.key === "Backspace" || e.key === "Delete") {
			return;
		}
		if (e.key === "Enter" || e.target.textContent.trim().length === this.limitDigit!) {
			e.preventDefault();
		}
	}
}
