<div (appClickOutside)="onClickOutside()">
	<main (click)="switchExpandState($event)" [class.error]="showError">
		<div class="container">
			<div class="element" *ngFor="let el of value; let i = index; trackBy: trackByFn">
				<div>{{valueTransformFn ? valueTransformFn(el) : el}}</div>
				<app-icon (click)="deleteItem(i, $event)">close</app-icon>
			</div>
		</div>
		<app-icon *ngIf="value?.length < 2" (click)="switchExpandState($event)">{{!expand? "unfold_more": "unfold_less"}}</app-icon>
	</main>
	<app-list-with-checkbox *ngIf="expand" [selectedElements]="selectedElements" [data]="list" (appSelect)="addItems($event)"
	(appScrolled)="onScrolled()"></app-list-with-checkbox>
</div>
