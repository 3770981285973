<main>
	<section>
		<app-icon>schedule</app-icon>
		<span *ngIf="!isTimerEnd">Время сессии истекает. Для продолжения работы нажмите на кнопку "Продолжить".</span>
		<span *ngIf="isTimerEnd">Время сессии истекло. Для продолжения работы войдите в систему.</span>
		<span *ngIf="!isTimerEnd" class="timer">{{ strTimer }}</span>
	</section>
	<footer>
		<button *ngIf="!isTimerEnd" class="save" appSkin="action-save bold" (click)="close(false)">Продолжить</button>
		<button *ngIf="isTimerEnd" class="save" appSkin="action-save bold" (click)="close(true)">Войти</button>
	</footer>
</main>