import { OperatorFunction } from "rxjs";
import { filter, scan } from "rxjs/operators";

export function notNull<T>(): OperatorFunction<T | undefined | null, NonNullable<T>> {
	return filter(f => !!f) as any;
}

//накапливает данные до первого undefined или null
// todo: test
export function scanData<T>(doingWhenDataReceived?: () => void): OperatorFunction<T[] | null | undefined, NonNullable<T[]>> {
	const isCallbackExist = typeof doingWhenDataReceived === "function";
	return scan((acc, curr): T[] => {
		if (!curr) {
			if (isCallbackExist) {
				doingWhenDataReceived!(); // eslint-disable-line
			}
			return [];
		}
		if (!curr.length) {
			if (isCallbackExist && !!acc.length) {
				doingWhenDataReceived!(); // eslint-disable-line
			}
			return acc;
		}
		return [...acc, ...curr] as T[];
	}, [] as T[]);
}
