<header>
	<app-icon [class.disabled]="isDisabled" class="left-icon" (click)="switchDecade(date, false);$event.stopPropagation();">
		arrow_back</app-icon>
	<div class="button" (click)="changePicker.emit(date);$event.stopPropagation();">{{date?.getFullYear() - 5}}
		- {{date?.getFullYear() + 6}}</div>
	<app-icon [class.disabled]="isDisabled" class="right-icon" (click)="switchDecade(date, true);$event.stopPropagation();">
		arrow_forward</app-icon>
</header>
<app-grid-drawer [columns]="4" [rows]="3" [items]="decades">
	<ng-template #itemTemplate let-item>
		<div class="grid-item" [class.today]="isToday(item.date)" [class.selected]="isSelected(item.date)"
		[class.disabled]="disabled(item.date)" (click)="setDecade(item.date);$event.stopPropagation();">
			{{item.date.getFullYear()}}</div>
	</ng-template>
</app-grid-drawer>
