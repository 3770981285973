<section>
	<main>
    <table>
      <tbody>
        <tr *ngIf="dts?.receipt?.serialNumber">
          <td i18n>Серийный номер:</td>
          <td>{{ dts?.receipt?.serialNumber }}</td>
        </tr>
        <tr *ngIf="dts?.receipt?.issueDate">
          <td i18n>Дата выдачи:</td>
          <td>{{ dts?.receipt?.issueDate | date : "short" }}</td>
        </tr>
        <tr *ngIf="dts?.receipt?.hasOwnProperty('valid')">
          <td i18n>Результат проверки:</td>
          <td>
            <i class="pi {{ dts?.receipt?.valid ? 'pi-check-circle' : 'pi-ban' }}" [class.not-valid]="!dts?.receipt?.valid">
              {{ dts?.receipt?.valid ? "Действительно" : "Недействительно" }}
            </i>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
			<h3>Сведения о проверке подписи</h3>
      <tbody>
        <tr *ngIf="dts?.certificateUserName">
          <td i18n>Имя субъекта:</td>
          <td>{{ dts?.certificateUserName }}</td>
        </tr>
        <tr *ngIf="dts?.securityParty">
          <td i18n>Имя издателя:</td>
          <td>{{ dts?.securityParty }}</td>
        </tr>
        <tr *ngIf="dts?.certificate">
          <td i18n>Серийный номер:</td>
          <td>{{ dts?.certificate }}</td>
        </tr>
        <tr *ngIf="dts?.certificateExpireDate">
          <td i18n>Срок действия:</td>
          <td>по {{ dts?.certificateExpireDate | date : "short" }}</td>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="dts?.receipt?.certificateUserName || dts?.receipt?.securityParty || dts?.receipt?.certificate || dts?.receipt?.certificateExpireDate">
      <table>
        <h3>Квитанция заверена сертификатом службы ДТС</h3>
        <tbody>
          <tr *ngIf="dts?.receipt?.certificateUserName">
            <td i18n>Имя субъекта:</td>
            <td>{{ dts?.receipt?.certificateUserName }}</td>
          </tr>
          <tr *ngIf="dts?.receipt?.securityParty">
            <td i18n>Имя издателя:</td>
            <td>{{ dts?.receipt?.securityParty }}</td>
          </tr>
          <tr *ngIf="dts?.receipt?.certificate">
            <td i18n>Серийный номер:</td>
            <td>{{ dts?.receipt?.certificate }}</td>
          </tr>
          <tr *ngIf="dts?.receipt?.certificateExpireDate">
            <td i18n>Срок действия:</td>
            <td>с {{ dts?.receipt?.issueDate | date : "short" }} по {{ dts?.receipt?.certificateExpireDate | date : "short" }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
	</main>
	<footer>
		<button appSkin="no-shadow bold bordered" class="blue-action" type="button" (click)="downloadDts(dts)">Скачать</button>
		<button appSkin="simple no-shadow bold bordered" type="button" (click)="close()">Закрыть</button>
	</footer>
</section>
