import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	Output,
	Type
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { BoxValueAccessor } from "../box-value-accessor/BoxValueAccessor";

@Component({
	selector: "app-checkbox",
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<CheckboxComponent> => CheckboxComponent)
	}],
	template: `
	<app-icon>{{ value ? "check_box" : "check_box_outline_blank" }}</app-icon>
	`,
	styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent extends BoxValueAccessor {
	public value = false;
	public isDisabled = false;
	@Input() public set setDisabled(value: any) {
		this.isDisabled = value || value === "";
	}
	// eslint-disable-next-line  @angular-eslint/no-output-native
	@Output() public change = new EventEmitter<boolean>();

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	public writeValue(value: boolean): void {
		this.value = value;
		this.changeDetectorRef.detectChanges();
	}

	@HostListener("click") public click(): void {
		if (this.isDisabled && this.value) {
			return;
		}
		this.value = !this.value;
		if (this.onChange)
			this.onChange(this.value);
		this.change.emit(this.value);
	}
}
