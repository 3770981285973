<div (appClickOutside)="onClickOutside()" >
	<main [class.error]="showError && !expand" [class.deleteIcon]="disabled">
		<div class="container" [class.expand]="expand">
			<div class="element" [class.deleteIcon]="!!value && !disabled && isValueFromList" >
				<input type="text" #input [value]="value" [class.isValue]="!!value && isValueFromList" [placeholder]="placeholder"
					[readonly]="isValueFromList" [attr.maxlength]="maxLength"	(input)="handInput($event)">
				<app-icon *ngIf="!!value && !disabled && isValueFromList" (click)="deleteItem($event)">close</app-icon>
			</div>
		</div>
		<app-icon *ngIf="!withoutSelection" (click)="switchExpandState($event)">search</app-icon>
	</main>
		<app-table-in-select *ngIf="expand" [headers]="headers" [requestObs]="request$" [setFilterValue]="value"
			[style.left]="isRowView? '0': '-85%'"
			[style.width]="isRowView? '305%': '215%'"
			(filterChange)="onInput($event)" (selectItem)="selectItem($event)" (showMore)="onScrolled()" (closeTable)="expand = false">
		</app-table-in-select>
</div>
