import { ChangeDetectionStrategy, Component, forwardRef, Type } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextboxComponent } from "@shared/textbox/textbox.component";

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<PhoneTextboxComponent> => PhoneTextboxComponent),
	}],
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-phone-textbox",
	templateUrl: "./phone-textbox.component.html",
	styleUrls: ["./phone-textbox.component.scss"]
})
export class PhoneTextboxComponent extends TextboxComponent {
	public maxLength = 9;
	private previousValue?: string | null = "";

	public inputValidator(event: any): void {
		this.previousValue = this.value;
		this.inputValidatorInt(event);
	}

	public inputValidatorInt(event: any): void {
		const pattern = /^[0-9]*$/;
		if (!pattern.test(event.target.value)) {
			event.target.value = this.previousValue;
		} else {
			this.previousValue = event.target.value;
		}
		this.writeValue(event.target.value);
		if (this.onChange)
			this.onChange(event.target.value);
		if (this.onTouched)
			this.onTouched();
	}

}
