import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

import { MarkingCode, MarkingCodesList } from "@app/user/marks/marks-store/marks-types";
import { DocumentProperty } from "@helper/abstraction/documents";

@Component({
	selector: "app-grid-tree",
	templateUrl: "./grid-tree.component.html",
	styleUrls: ["./grid-tree.component.scss"]
})
export class GridTreeComponent {
	@Output() public ellipsisActions: EventEmitter<{ action: string; markingCode: MarkingCode }> = new EventEmitter<{ action: string; markingCode: MarkingCode}>();
	@Output() public isSelect = new Subject<MarkingCodesList>();

	@Input() public container?: any;
	@Input() public branch?: MarkingCode;
	@Input() public childrenFieldName = "children";
	@Input() public step = 0;
	@Input() public gtin = "";
	@Input() public withoutCheckBox = false;
	@Input() public selectedItems: MarkingCodesList = [];
	@Input() public properties: DocumentProperty[] | HTMLElement = [];
	public showBranch = true;
	public isIndeterminateCheckBox = false;

	public handleEllipsisActions(action: string, markingCode: MarkingCode): void {
		this.isIndeterminateCheckBox = true;
		this.ellipsisActions.emit({ action, markingCode });
	}

	public selectedItem(flag: boolean, item: MarkingCode): void {
		item.checkState = flag;
		flag ? this.addInListOfSelection(item) : this.removeFromListOfSelection(item);
		this.isSelect.next(this.selectedItems);
	}

	public addInListOfSelection(item: MarkingCode): void {
		if (!this.selectedItems.some(el => el.id === item.id)) {
			this.selectedItems.push(item);
		}
	}

	public removeFromListOfSelection(item: MarkingCode): void{
		if (!this.selectedItems.some(el => el.id === item.id)) {
			this.selectedItems.push(item);
			return;
		}
		this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
	}

	public checkedItem(item?: MarkingCode): boolean {
		return this.selectedItems && item
			? this.selectedItems.some(selectItem => JSON.stringify(selectItem) === JSON.stringify(item))
			: false;
	}

	public setSelected(selectedItemsList: MarkingCodesList): void {
		this.selectedItems.concat(selectedItemsList);
		this.isSelect.next(selectedItemsList);
	}

}
