import { ConfigurationService } from "@core/configuration.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProviderInfo } from "@helper/abstraction/config";

export class Config {

	public providerInfo = {
		get$: (): Observable<ProviderInfo> => {
			const url = `${this.config.api.root}/config/providerInfo`;
			return this.http.get<ProviderInfo>(url, { withCredentials: true });
		}
	};

	public providerPhone = {
		get$: (): Observable<string> => {
			const url = `${this.config.api.root}/config/providerPhone`;
			return this.http.get<string>(url, { responseType: "text" as "json" });
		}
	};

	public providerPublicInfo = {
		get$: (): Observable<any> => {
			const url = `${this.config.api.root}/config/providerPublicInfo`;
			return this.http.get<any>(url);
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
