<main [formGroup]="form">
	<div *ngIf="!isEwaybillGoodsFilter">
		<label i18n>GTIN</label>
		<app-textbox formControlName="gtin"></app-textbox>
	</div>

	<div *ngIf="!isEwaybillGoodsFilter">
		<label i18n>Наименование</label>
		<app-textbox formControlName="name"></app-textbox>
	</div>

	<div *ngIf="!isEwaybillGoodsFilter">
		<label i18n>Код ТН ВЭД</label>
		<app-textbox formControlName="tnvedCode" maxLength="10"></app-textbox>
	</div>

	<div *ngIf="isEwaybillGoodsFilter">
		<label i18n>Строка поиска</label>
		<app-textbox icon="search" formControlName="name" maxLength="10"></app-textbox>
	</div>

	<div class="clean">
		<app-button  skin="simple-cancel" icon="clear" (click)="clear()">Сбросить</app-button>
	</div>
</main>
