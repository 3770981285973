import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { MnsAuthService } from "./mns-auth.service";

@Injectable()
export class MnsAuthGuard implements CanActivate {

	constructor(
		private readonly router: Router,
		private readonly authService: MnsAuthService,
	) { }

	public async canActivate(): Promise<boolean> {
		const token = await this.authService.token$.pipe(first()).toPromise();
		if (!token) {
			this.router.navigate(["gov", "login"]);
			return false;
		} else
			return true;
	}
}
