<section>
	<ng-container *ngIf="urlScMobileForm; else skeleton">
		<iframe [src]="url"></iframe>
	</ng-container>
	<ng-template #skeleton>
		<div *ngIf="!urlScMobileForm" class="skeleton-block">
			<p-skeleton width="950px" height="350px" borderRadius="6px"></p-skeleton>
		</div>
	</ng-template>
	<footer>
		<button *ngIf="!isAuthorizeMobileEds" appSkin="action" class="green" (click)="confirmCertificate()">Привязать</button>
		<button appSkin="simple-cancel" (click)="close(false)">{{ isAuthorizeMobileEds ? "Закрыть" :"Отмена" }}</button>
	</footer>
</section>
