<section @slideNotic>
	<header>
		<span *ngIf="form?.get('checkControl')?.value || data?.content?.checkControl === 0 || data?.checkControl === 0" (click)="close()">
			{{ data?.content?.closeButtonName || data?.closeButtonName }}
			<app-icon>cancel</app-icon>
		</span>
	</header>
    <iframe [src]="url"></iframe>
	<div [formGroup]="form" class="main" *ngIf="data?.content?.checkControl === 1 || data?.checkControl === 1">
		<span>
			<app-checkbox formControlName="checkControl"></app-checkbox>
			<label>{{ data?.content?.checkControlName || data?.checkControlName || "" }}</label>
		</span>
	</div>
</section>
