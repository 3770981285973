<div class="grid-container" #container
	(scroll)="onScroll(container.scrollHeight, container.scrollTop, container.clientHeight)">
	<table *ngIf="items && listHeaders">
		<thead>
			<tr  [ngClass]="withSelectedItemsBlock ? 'tall-header': ''">
				<th rowspan="2"></th>
				<th rowspan="2" *ngIf="(!withoutCheckbox && hideOrderCode && !this.isEwaybillGoods) else fieldTh">
					<div class="icon">
						<app-icon *ngIf="selectedItems && selectedItems?.length > 0 && selectedItems?.length < filteredList.length"
							(click)="selectAllItems(items)">
							indeterminate_check_box</app-icon>
						<app-icon *ngIf="!selectedItems || selectedItems?.length === 0" (click)="selectAllItems(items)">
							check_box_outline_blank</app-icon>
						<app-icon *ngIf="selectedItems?.length && selectedItems?.length === filteredList.length"
							(click)="clearSelectedItems()">
							check_box</app-icon>
					</div>
				</th>
				<th *ngIf="this.isEwaybillGoods" rowspan="2" #fieldTh></th>
				<ng-container *ngFor="let header of properties">
					<th [class.text-left]="!header.children" [attr.rowspan]="header.children ? 1 : 2"
						[attr.colspan]="header.children && header.children.length">
						{{header.name}}</th>
				</ng-container>
				<ng-container *ngIf="hideOrderCode">
					<div class="selected-items-block" *ngIf="withSelectedItemsBlock && selectedItems?.length > 0">
						<div class="selected-items-wrapper">
							<p>Выбрано {{ selectedItems.length }} кода</p>
							<app-button icon="arrow_forward" (click)="orderCode()">Заказать код</app-button>
						</div>
					</div>
				</ng-container>
			</tr>
			<tr>
				<ng-container *ngFor="let header of properties">
					<ng-container *ngIf="header.children">
						<th class="center-text" *ngFor="let field of header.children">{{field.name}}</th>
					</ng-container>
				</ng-container>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let document of items; let i = index" (click)="clickItem(document)" [class.selected]="clickId === i"
				[class.checked]="checkedItem(document)">
				<td>
				</td>
				<td *ngIf="!withoutCheckbox && hideOrderCode">
					<div class="icon" [class.disabled]="isEwaybillGoods ? isEwaybillGoods === false : document.tradeItemType === 1">
						<app-icon *ngIf="checkedItem(document)" (click)="selectedItem(document); $event.stopPropagation()" >
							check_box</app-icon>
						<app-icon *ngIf="!checkedItem(document)" (click)="selectedItem(document); $event.stopPropagation()">
							check_box_outline_blank</app-icon>
					</div>
				</td>
				<ng-container *ngFor="let header of properties">
					<td class="text-left" *ngIf="!header.children">
						<ng-container [ngSwitch]="header.type">
							<div class="icon" *ngSwitchCase="'status'">
								{{getStatuses(document[header.key])}}
							</div>
							<div *ngSwitchCase="'date'">{{document[header.key] | date: "dd.MM.yyyy HH:mm"}}</div>
							<div *ngSwitchCase="'boolean'">
								<div *ngIf="document[header.key]">
									Да
								</div>
							</div>
							<div class="icon" *ngSwitchCase="'person'">
								{{document[header.key].name}}
							</div>
							<div class="icon" *ngSwitchCase="'place'">
								{{document[header.key].address}} {{document[header.key].gln}}
							</div>
							<div class="icon" *ngSwitchCase="'placeFull'">
									{{document[header.key]? addressAndGln(document[header.key]): "—"}}
							</div>
							<div class="icon" *ngSwitchCase="'isGTIN'">
									<span>{{document[header.key] === 1? "Да": "Нет"}}</span>
							</div>
							<div class="icon" *ngSwitchCase="'type'">
								<ng-container [ngSwitch]="document[header.key]">
									<ng-container *ngSwitchCase="'BLRWBL'">
										ТТН
									</ng-container>
									<ng-container *ngSwitchCase="'BLRDLN'">
										ТН
									</ng-container>
								</ng-container>
							</div>
							<div *ngSwitchDefault>{{document[header.key]}}</div>
						</ng-container>
					</td>
					<ng-container *ngIf="header.children">
						<td *ngFor="let child of header.children">
							<ng-container *ngIf="document[header.key]">
								<ng-container [ngSwitch]="child.type">
									<div *ngSwitchCase="'date'">
										<ng-container *ngIf="header.type === 'array'; else simple">
											<div *ngFor="let doc of document[header.key]">
												{{doc[child.key] | date: "dd.MM.yyyy HH:mm"}}
											</div>
										</ng-container>
										<ng-template #simple>
											{{document[header.key][child.key] | date: "dd.MM.yyyy HH:mm"}}
										</ng-template>
									</div>
									<div *ngSwitchCase="'dateWithoutTime'">
											{{document[header.key][child.key] | date: "dd.MM.yyyy"}}
									</div>
									<div [class]="document[header.key] ? 'link': ''" *ngSwitchCase="'link'">
										<ng-container *ngIf="header.type === 'array'; else simple">
											<div *ngFor="let doc of document[header.key]" (click)="openUrl(header.key,undefined, doc.id)">
												{{doc[child.key]}}
											</div>
										</ng-container>
										<ng-template #simple>
											<div (click)="openUrl(header.key,undefined, document[header.key].id)">
												{{document[header.key][child.key]}}</div>
										</ng-template>
									</div>
									<div *ngSwitchDefault>
										<ng-container *ngIf="header.type === 'array'; else simple">
											<div *ngFor="let doc of document[header.key]">
												{{doc[child.key]}}
											</div>
										</ng-container>
										<ng-template #simple>
											{{document[header.key][child.key]}}
										</ng-template>
									</div>
								</ng-container>
							</ng-container>
						</td>
					</ng-container>
				</ng-container>
			</tr>
		</tbody>
	</table>
</div>
<app-pending *ngIf="pending$ | async" [isBlackTheme]="true"></app-pending>
<footer *ngIf="items && items.length > 0 && !withoutFooter">
	Доступно {{items.length}} товаров
</footer>
<app-overlay></app-overlay>

<template #statusList>
	<dl>
		<dt>SENT</dt>
		<dd i18n>Отправляется</dd>
		<dt>TRANSFERRED</dt>
		<dd i18n>Передана</dd>
		<dt>RECEIVED</dt>
		<dd i18n>Получена</dd>
		<dt>CREATED</dt>
		<dd i18n>Создана</dd>
		<dt>CANCELED</dt>
		<dd i18n>Отменена</dd>
		<dt>CHANGE_REQUIRED</dt>
		<dd i18n>Треб. изменений</dd>
		<dt>CREATED_CONFIRMED</dt>
		<dd i18n>Создана и подтверждена</dd>
		<dt>NOT_CONFIRMED_CANCEL</dt>
		<dd i18n>Отмена не подтверждена</dd>
		<dt>CHANGE_REQUIRED_NOT_CONFIRMED</dt>
		<dd i18n>Треб. изменений не подтверждено</dd>
		<dt>CREATED_CANCEL_SENT</dt>
		<dd i18n>Создана. Отправлена отмена</dd>
		<dt>CREATED_CONFIRMED_CANCEL_SENT</dt>
		<dd i18n>Создана и подтверждена. Отправлена отмена</dd>
		<dt>AUTOMATICALLY_CANCELLED</dt>
		<dd>Автоматически отменена</dd>
	</dl>
</template>
