import { Component, ChangeDetectionStrategy } from "@angular/core";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-password-error-popup",
	styleUrls: ["password-error-popup.component.scss"],
	templateUrl: "./password-error-popup.component.html"
})

export class PasswordErrorPopupComponent extends DefaultPopupComponent {
}
