<section>
	<header>
		<h2>Выбор склада</h2>
		<app-icon (click)="this.close$.next()">close</app-icon>
	</header>
	<main>
		<form [formGroup]="form">
			<div class="button-block" *ngIf="!['EINVOICE', 'ORDERS', 'DESADV'].includes(documentTypeId)">
				<app-tab [tabs]="tabs" formControlName="tab"></app-tab>
				<app-button *ngIf="mode === 'unloading' && !isInternalStatus && consigneeId" type="button" skin="action-save" icon="add" i18n (click)="addStorage()">Добавить склад</app-button>
			</div>
			<ng-container *ngIf="!isGln">
				<app-storage-grid [updateList$]="updateStorageForReceiver$" [option]="storagePointsSelectBoxOption" (appFormValue)="addStorageToDoc($event)"></app-storage-grid>
			</ng-container>
			<ng-container *ngIf="isGln">
				<app-storage-grid [updateList$]="updateStorageForReceiver$" [option]="generalGLNSelectBoxOption" (appFormValue)="addStorageToDoc($event)"></app-storage-grid>
			</ng-container>
		</form>
	</main>
</section>

<app-overlay #createStorage></app-overlay>

<template #tabsTemplate>
	<dl>
		<dt>place</dt>
		<dd i18n>Пункт </dd>
		<dt>gln</dt>
		<dd i18n>Общественный GLN</dd>
	</dl>
</template>

<template #placesTemplate>
	<dl>
		<dt>loading</dt>
		<dd i18n>Пункт погрузки</dd>
		<dt>unloading</dt>
		<dd i18n>Пункт разгрузки</dd>
		<dt>storage</dt>
		<dd i18n>Склад</dd>
	</dl>
</template>
