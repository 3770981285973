import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { pluralize } from 'numeralize-ru';
import { Table } from 'primeng/table';
import { Big } from "big.js";
import { dependentFields, emptyFields, nonHiddenFields } from '@app/user/documents/eact/eact-grid-metadata';

export interface DataTableModel {
	headerName: string;
	fieldKey?: string;
	objectFieldName?: string;
	editableCell?: boolean;
	width?: string;
	isUsedWidth?: boolean;
	hideRaw?: boolean;
	isRequired?: boolean;
	fields?: {
		[key: string]: {
			value: string;
			width: string;
			editable?: boolean;
			objectFieldName?: string;
			maxLength?: number;
			markList?: any[];
			handleMarkCodes?: boolean;
			handleMarkCodesMode?: "VIEW" | "EDIT";
			wholeMaxLength?: number;
			decimalMaxLength?: number;
			numberType?: "int" | "float",
		}
	}
};

@Component({
	selector: 'app-edit-cell-grid',
	templateUrl: './edit-cell-grid.component.html',
	styleUrls: ['./edit-cell-grid.component.scss'],
	animations: [
		trigger('rowExpansionTrigger', [
			state('void', style({
				transform: 'translateX(-10%)',
				opacity: 0
			})),
			state('active', style({
				transform: 'translateX(0)',
				opacity: 1
			})),
			transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class EditCellGridComponent<T> {
	@Input() public data?: T[];
	@Input() public isViewMode = false;
	@Input() public totalSums?: T[];
	@Input() public headerMetadataTable?: DataTableModel[];
	@Input() public headersTotal?: DataTableModel[];
	@Input() public editableFields?: string[];
	@Input() public maximizationView?: boolean;
	@Output() public cellChanged: EventEmitter<T[]> = new EventEmitter<T[]>();
	@Output() public processItem:
		EventEmitter<{ mode: "EDIT" | "DELETE", item: T, index: number }>
		= new EventEmitter<{ mode: "EDIT" | "DELETE", item: T, index: number }>();
	@Output() public openCodes: EventEmitter<T> = new EventEmitter<T>();
	public pluralize = pluralize;
	public globalFilter?: string;
	public expandedFooter = false;
	private dependentFields = dependentFields;
	@ViewChild('dt') public table?: Table;

  	public arrowPressed(event: KeyboardEvent): void {
		event.stopPropagation();
		return;
	}

	public getDataField(item: any, field: string): string {
		return item[field];
	}

	public checkButtonStatus(item: any): boolean {
		this.updateDependentFields(item);
		for (let k in item) {
			if (k === "quantityAccepted" && String(item.quantityAccepted).trim() !== item.quantityOrdered) {
				return true
			}
		}
		return false;
	}

	public editInputComplete(event: string, contentRow: T, key: string): void {
		let newContentRow: any = contentRow;
		newContentRow[key] = event;
		const newData = this.data?.map((el: any) => {
			if (el.position === newContentRow.position) {
				return newContentRow;
			} else {
				return el
			}
		});
		this.cellChanged.next(newData);
	}

	public keys(row: any): any {
        return Object.keys(row); 
    }

	public editInternalTableComplete(event: T): void {
		// TODO add event to EventEmitter
		this.updateDependentFields(event);
		this.cellChanged.next(this.data);
	}

	public getTableRawLength(): number {
		return this.table?.filteredValue?.length ?? this.data?.length ?? 0;
	}

	public globalFilterTable(): void {
		// TODO
	}

	public editExternalTableComplete(event: any): void {
		// TODO for general table
	}

	public fieldWidth(size: number): string {
		return `width: ${size}rem`
	}

	public handleEmptyField(key: string): string {
		return key.substring(0, 2) === "no" ? "" : "-";
	}

	public expand(event: { originalEvent: PointerEvent, data?: T }, dt: Table): void {
		event.originalEvent.stopPropagation();
		dt.wrapperViewChild.nativeElement.scrollTo({ left: 0 });
	}

	public processRaw(mode: "EDIT" | "DELETE", item: T, index: number): void {
		this.processItem.next({ mode, item, index });
	}

	public openMarkCodePopup(item?: any): void {
		this.openCodes.next(item);
	}

	public checkTooltipLength(value: string): boolean {
		if (!value) {
			return true;
		}
		return value?.length < 12;
	}

	public checkTooltipText(value: string): string {
		if (!value) {
			return "";
		}
		return value;
	}

	public checkLengthDigit(key: string): number {
		return key.substring(0, 8) === "quantity" ? 5 : 2;
	}

	public updateDependentFields(position: any): boolean {
		this.dependentFields.forEach(key => {
			const amountWithoutVat: Big = new Big(position[key[2]] && +position[key[2]] || 0).times(new Big(position[key[0]] && +position[key[0]] || 0)).round(2);
			const amountVat: Big = +position[key[1]] ? amountWithoutVat.times(+position[key[1]]).div(100).round(2) : new Big(0);
			const amountWithVat: Big = amountWithoutVat.plus(amountVat);
			// Количество 		key[0]
			// Ставка НДС,% 	key[1]
			// Цена 			key[2]
			// Стоимость 		key[3]
			// Сумма НДС 		key[4]
			// Стоимость с НДС	key[5]
			if (key[3]) position[key[3]] = amountWithoutVat && amountWithoutVat.toFixed(2) !== "0.00" ? amountWithoutVat.toFixed(2) : this.checkKeys(key, position) ? "0.00" : undefined;
			if (key[4])	position[key[4]] = amountVat && amountVat.toFixed(2) !== "0.00" ? amountVat.toFixed(2) : this.checkKeys(key, position) ? "0.00" : undefined;
			if (key[5])	position[key[5]] = amountWithVat && amountWithVat.toFixed(2) !== "0.00" ? amountWithVat.toFixed(2) : this.checkKeys(key, position) ? "0.00" : undefined;
			if (key[2]) position[key[2]] = ![undefined, null, ""].includes(position[key[2]]) && (+position[key[0]] > 0 || +position[key[0]] >= 0 && nonHiddenFields.includes(key[2])) ? position.priceFact : undefined;
			if (key[0]) {
				position[key[1]] = ![undefined, null, ""].includes(position[key[0]]) && +position[key[0]] >= 0 ? position.vatRateFact ?? position.vatRate : undefined;
				position[key[2]] = ![undefined, null, ""].includes(position[key[0]]) && +position[key[0]] >= 0 ? position.priceFact : undefined;
			}
		});
		return true;
	}

	private checkKeys(key: string[], position: any): boolean {
		return emptyFields.includes(key[3 || 4 || 5]) && +position[key[0]] >= 0 && !["", null, undefined].includes(position[key[0]]);
	}
}
