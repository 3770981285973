import { Directive, Input, HostListener, OnChanges } from "@angular/core";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { ExtendedFormControl } from "@shared/directives/native-element-to-form-control-provider/native-element-to-form-control-provider.directive";

@Directive({
	selector: "[appScrollToFirstInvalidDirective]"
})
export class ScrollToFirstInvalidDirective implements OnChanges {
	@Input() public formGroup?: FormGroup;
	@Input() public appScrollToFirstInvalidDirective?: HTMLElement;
	@Input() public isScroll = true;
	@HostListener("submit", ["$event"]) public onSubmit(target: any): void {
		target.preventDefault();
		if (this.formGroup && this.formGroup.invalid && this.isScroll) {
			const firstInvalidControl = this.findTarget(this.formGroup);
			if (firstInvalidControl) {
				const ne = (firstInvalidControl as ExtendedFormControl).nativeElement;
				if (!ne)
					return;
				ne.scrollIntoView({ behavior: "smooth" });
			}
		}
	}

	public ngOnChanges(): void {
		if (!this.formGroup)
			throw Error("No formGroup");
	}

	private findTarget(fgOrFc: FormGroup | FormControl | FormArray): FormControl | null {
		if (fgOrFc instanceof FormGroup || fgOrFc instanceof FormArray) {
			for (const key in fgOrFc.controls) {
				const control = this.findTarget((fgOrFc.controls as any)[key] as FormGroup | FormControl);
				if (control) {
					return control;
				}

			}
			return null;
		} else {
			return fgOrFc.invalid ? fgOrFc as FormControl : null;
		}
	}
}
