import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslationService } from "@core/translation.service";
import { OverlayService } from "@core/overlay.service";

@Injectable()
export class ErrorHandlerService {

	constructor(
		private translationService: TranslationService,
		private overlayService: OverlayService
	) {
	}

	public errorHandler(error: HttpErrorResponse | Error | undefined | string): void {
		if ((error as HttpErrorResponse).error?.errorCode == "EX1001")
			return;
		let errorMessage = typeof error === "string" ? error : "";
		const proxyUnavailableMessage = this.translationService.getTranslation("unavailableSCCryptoProxy");
		if (error && error instanceof HttpErrorResponse)
			switch (error.status) {
				case 422:
				case 404:
					errorMessage = error.error && typeof error.error.error === "string" ? error.error.error : error.error.message;
					break;
				case 0:
					errorMessage = proxyUnavailableMessage;
					break;
				default:
					errorMessage = error.error && typeof error.error.error === "string" ? error.error.error : error.message;
					break;
			}
		this.overlayService.showNotification$(errorMessage, "error");
	}
}
