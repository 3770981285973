import { ChangeDetectionStrategy, Component, forwardRef, Input, Type } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextboxComponent } from "../textbox/textbox.component";

export type NumberType = "int" | "float";

@Component({
	selector: "app-numberbox",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<NumberboxComponent> => NumberboxComponent)
	}],
	template: `
        <input #input [class.error]="showError" type="text" [attr.maxlength]="maxLength" [value]="value" [attr.disabled]="disabled"
				(input)="inputValidator($event)" placeholder="{{placeholder}}" (blur)="blur($event)">
	`,
	styleUrls: ["./numberbox.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberboxComponent extends TextboxComponent {
	@Input() public type: NumberType = "int";
	@Input() public wholeMaxLength?: number;
	@Input() public decimalMaxLength?: number;
	@Input() public disableAutoCheck = false;
	@Input() public withEmptyNull = false;

	public isDotRemoved = true;
	public value = "";
	private previousValue = "";

	public blur(event: any): void {
		const focuseValue = event.target.value;
		if (typeof focuseValue === "string" && !Boolean(focuseValue.split(",")[1] || focuseValue.split(".")[1])) {
			const clearFocuseValue = this.checkValue((String(focuseValue).split(".") || String(focuseValue).split(","))[0]);
			this.writeValue(clearFocuseValue);
			if (this.onChange)
				this.onChange(clearFocuseValue);
			if (this.onTouched)
				this.onTouched();
		}
	}

	public inputValidator(event: any): void {
		this.previousValue = this.value;
		if (this.type === "float")
			this.inputValidatorFloat(event);
		else
			this.inputValidatorInt(event);
	}

	public inputValidatorInt(event: any): void {
		const pattern = /^[0-9]*$/;
		if (!pattern.test(event.target.value)) {
			event.target.value = this.previousValue;
		} else {
			this.previousValue = event.target.value;
		}
		const checkedValue = this.checkValue(event.target.value);
		this.writeValue(checkedValue);
		if (this.onChange)
			this.onChange(checkedValue);
		if (this.onTouched)
			this.onTouched();
	}

	public inputValidatorFloat(event: any): void {
		const withDot = event.target.value.indexOf(".") !== -1;
		const withComma = event.target.value.indexOf(",") !== -1;
		if ((withDot || withComma) && this.isDotRemoved && this.maxLength) {
			this.maxLength++;
			this.isDotRemoved = false;
		} else if (!withDot && !withComma && !this.isDotRemoved && this.maxLength) {
			this.maxLength--;
			this.isDotRemoved = true;
		}
		const wordPattern = /[^\d.,]/g;
		if (wordPattern.test(event.target.value)) {
			event.target.value = this.previousValue;
			return;
		}
		if (this.wholeMaxLength && this.decimalMaxLength && this.maxLength) {
			const decimalPattern = new RegExp(`^\\d{0,${this.wholeMaxLength}}\\.{1}\\d{0,${this.decimalMaxLength}}$`);
			const decimalCommaPattern = new RegExp(`^\\d{0,${this.wholeMaxLength}}\\,{1}\\d{0,${this.decimalMaxLength}}$`);
			const pattern = /^[0-9.,]*$/;

			if (event.target.value.length <= this.maxLength && event.target.value.indexOf(".") === -1 && event.target.value.indexOf(",") === -1) {
				if (!pattern.test(event.target.value)) {
					event.target.value = this.previousValue;
				}

				this.writeValue(event.target.value, true);
				if (this.onChange)
					this.onChange(event.target.value.replace(",", "."));
				if (this.onTouched)
					this.onTouched();

				this.previousValue = event.target.value;
				return;
			}

			if (event.target.value === "." || event.target.value === ",") {
				event.target.value = "0" + event.target.value.slice(0, event.target.value.length);
			}

			if (!decimalPattern.test(event.target.value) && !decimalCommaPattern.test(event.target.value)) {
				event.target.value = event.target.value.slice(0, event.target.value.length - 1);
			}

			if (event.target.value === "" && this.withEmptyNull) {
				event.target.value = null;
			}
		}
		this.previousValue = event.target.value;
		this.writeValue(event.target.value, true);
		if (this.onChange)
			this.onChange(event.target.value.replace(",", "."));
		if (this.onTouched)
			this.onTouched();
	}
}
