<div class="container">
	<section>
		<div class="header">
			<h3 [ngClass]="!message ? 'title' : ''">{{title || "Подтвердить действие?"}}</h3>
			<app-icon *ngIf="!message" (click)="appConfirm.emit()">clear</app-icon>
		</div>
		<p *ngIf="message" class="message-block">
			{{message}}
		</p>
	</section>
	
	<nav>
		<button [appSkin]="'action'" (click)="appConfirm.emit(true)">{{agreeButtonText || "ОК" }}</button>
		<button *ngIf="!withoutDisagree" [appSkin]="'simple-cancel'"
			(click)="appConfirm.emit(false)">{{disagreeButtonText || "Отмена"}}</button>
	</nav>
</div>
