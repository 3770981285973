import { DatePipe } from "@angular/common";
import { FormGroup, AbstractControl, FormArray } from "@angular/forms";
import { TranslationService } from "@core/translation.service";

export function isEveryFieldNull(obj: {}): boolean {
	return Object.values(obj).every(value => value == null || value == "");
}

export function addFieldIsFilled(mainObject: any, fieldsList: { variableName: string; value: any }[]): void {
	fieldsList.forEach(el => {
		if (!isEveryFieldNull({ objectName: el.value })) {
			mainObject[el.variableName] = el.value;
		}
	});
}

export function isEveryFieldNullAtArray(array: {}[]): boolean {
	return !array?.filter(obj => !isEveryFieldNull(obj)).length;
}

export function subjectFullName(value: string): string {
	return value.split("OID").slice(1,4).join().replace(/\w|=|\.|,/g,"").trim();
}

export function trimFormValues(control: AbstractControl): void {
	if (control instanceof FormGroup) {
		const group = (control as FormGroup);

		for (const field in group.controls) {
			const c = group.controls[field];

			trimFormValues(c);
		}
	}
	else if (control instanceof FormArray) {
		const group = (control as FormArray);

		for (const field in group.controls) {
			const c = group.controls[field];

			trimFormValues(c);
		}
	}
	if (control?.value && control.value === "object") {
		Object.keys(control.value).forEach(key => {
			if (control.value[key] && typeof control.value[key] === "string") {
				control.value[key] = control.value[key].trim().length ? control.value[key].trim() : null;
			}
		});
	}
	else if (control?.value && typeof control.value === "string") {
		control.setValue(control.value.trim().length ? control.value.trim() : null);
	}
}

export function  checkFullAddress(object: { [key: string]: any }, fieldArray: any[]): any[] {
	const arr = fieldArray.map(field => {
		if (Array.isArray(field)) {
			let obj = object?.[field[0]];
			field.filter((_, i) => i > 0 && i < field.length - 1 ).forEach(subField => {
				obj = obj?.[subField];
			});
			return obj?.[field[field.length - 1]];
		}
		return object?.[field];
	}).filter(el => !!el);
	return arr;
}

export function formatVersion(actlV: string, clntV: string, cutActlVersion: boolean): string {
	return cutActlVersion ? actlV.substring(0, Math.min(clntV.length, actlV.length)) : clntV.substring(0, Math.min(clntV.length, actlV.length));
}

export function checkVersions(actlV: string, clntV: string): boolean {
	const clientVersion = clntV.split(".");
	const actualVersion = actlV.split(".");
	for (let i = 0; i < clientVersion.length; i++) {
		if (([0, 1].includes(i) || (i === 2 && clientVersion[i].length === actualVersion[i].length)) &&
			actualVersion[i] !== clientVersion[i]) {
			return actualVersion[i] > clientVersion[i];
		}
		if (i === 2) {
			if (formatVersion(actualVersion[i], clientVersion[i], true) === formatVersion(actualVersion[i], clientVersion[i], false)) {
				return actualVersion[i] > clientVersion[i];
			}
			return formatVersion(actualVersion[i], clientVersion[i], true) > formatVersion(actualVersion[i], clientVersion[i], false);
		}
	}
	return false;
}

export function	getFormatedCertsList(certList: any, locale: string, translationService: TranslationService): string {
	const datePipe = new DatePipe(locale);
	return certList.map((c: any) => {
		const arr = [c.certType && c.certType.nameShort, c.certNumber];
		if (c.dateFrom) arr.push(`${translationService.getTranslation("from")} ${datePipe.transform(c.dateFrom, "dd.MM.yyy")}`);
		if (c.dateTo) arr.push(`${translationService.getTranslation("to")} ${datePipe.transform(c.dateTo, "dd.MM.yyy")}`);
		if (c.certPartyIssuingName) arr.push(`${translationService.getTranslation("issued")} ${c.certPartyIssuingName}`);
		if (c.certPartyNumber) arr.push(`${translationService.getTranslation("certPartyNumber")} ${c.certPartyNumber}`);
		if (c.certProductDate) arr.push(`${translationService.getTranslation("certProductDate")} ${datePipe.transform(c.certProductDate, "dd.MM.yyy")}`);
		return arr.join(" ");
	}).join("; ");
}

export function handleDate(date: string): string {
	return (date as unknown as Date)?.toLocaleDateString("en-ca");
}

export function cutOffDateLine(value: string, isConvert = false): string {
	const dateValue = typeof value === "object" ? new Date(isConvert ? handleDate(value) : value).toISOString() : value;
	return  dateValue.includes("T") ? dateValue.split("T")[0] : dateValue;
}

export function substringValue(value: string, interval: [number, number], targetValue: string): boolean {
	return !value ? false : value.trim().substring(interval[0], interval[1]) === targetValue;
}
