<div (appClickOutside)="onClickOutside()">
	<main (click)="switchExpandState($event)" [class.error]="showError">
		<div class="container">
			<div *ngIf="!value.length && placeholder" class="placeholderBox">
				<input type="text" readonly="readonly" [placeholder]="placeholder">
			</div>
			<div class="element" *ngFor="let el of value; let i = index; trackBy: trackByFn">
				<div>{{valueTransformFn ? valueTransformFn(el) : el}}</div>
				<app-icon *ngIf="el.id !== 'ALL'" (click)="deleteItem(i, $event)">close</app-icon>
			</div>
		</div>

		<ng-container *ngIf="!pending">
			<app-icon *ngIf="value?.length < 2" (click)="switchExpandState($event)">{{!expand? "unfold_more": "unfold_less"}}</app-icon>
		</ng-container>
		<div *ngIf="pending" class="loading">
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
					style="margin: auto; background: rgba(0, 0, 0, 0); display: block;" width="45px" height="45px" viewBox="0 0 100 100"
					preserveAspectRatio="xMidYMid">
						<circle cx="50" cy="50" fill="none" stroke="rgb(0, 102, 209)" stroke-width="7" r="32" stroke-dasharray="150.79644737231007 52.26548245743669" transform="rotate(334.662 50 50)">
							<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9900990099009901s" values="0 50 50;360 50 50" keyTimes="0;1">

							</animateTransform>
						</circle>
				</svg>
		</div>
	</main>
	<app-list *ngIf="expand" [data]="list" (appInput)="onInput($event)" (appSelect)="addItem($event)"
		(appScrolled)="onScrolled()" [showSearch]="showSearch" [autoStopScroll]="autoStopScroll"></app-list>
</div>
