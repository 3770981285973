import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs";
import { DraftDto } from "@helper/abstraction/draft";

export class Eact extends DocumentController<any> {
	public get apiUrl(): string { return `${this.config.api.eact}`; }

	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	public checkSign = {
		get$: (documentId: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${documentId}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public cancelEact = {
		get$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
			const url = `${this.apiUrl}/${documentId}/cancel`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.get<DraftDto>(url, { withCredentials: true, params });
		}
	};

	public saveResponse = {
		post$: (draftId: number, base64?: boolean): Observable<number> => {
			const url = `${this.apiUrl}/${draftId}/response`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.post<number>(url, null, { withCredentials: true, params });
		}
	};

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}
