import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Store } from "@ngrx/store";

import { DocumentType } from "@helper/abstraction/documents";
import { SignatureDtoUI } from "@helper/abstraction/eact";
import { exportDts } from "@app/user/user.actions";

@Component({
  selector: "app-dts-receipt-popup",
  templateUrl: "./dts-receipt-popup.component.html",
  styleUrls: ["./dts-receipt-popup.component.scss"]
})
export class DtsReceiptPopupComponent implements OnInit {
  public dts?: SignatureDtoUI;
  public id?: number;
  public documentType?: DocumentType;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
		private store: Store,
  ) { }

  public ngOnInit(): void {
    this.setData();
  }

  public setData(): void {
    this.config.header = this.config.header ?? "Квитанция ДТС";
    this.config.width = "800px";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
  }

  public close(): void {
    this.ref.close();
  }

  public downloadDts(dts?: SignatureDtoUI): void {
    this.store.dispatch(exportDts({ documentType: this.documentType!, documentId: this.id!, signatureId: dts?.id! }));
  }
}
