<div class="empty" *ngIf="!value || value.length === 0" (click)="openPopup(true)">{{placeholder}}</div>


<div *ngIf="value && value.length > 0">
	<div class="field" (click)="openPopup(true)">
		<div class="element" *ngFor="let el of value; let i = index">
			<div>{{valueTransformFn ? valueTransformFn!(el) : el}}</div>
			<app-icon (click)="deleteItem(el, i); $event.stopPropagation()">close</app-icon>
		</div>
	</div>
</div>
<div class="popup" *ngIf="isPopupDisplay">
	<div class="header">
		<label>{{title}}</label>
		<app-icon (click)="openPopup(false)">close</app-icon>
	</div>
	<div class="content">
		<form [formGroup]="searchCriterion" class="first-line">
			<div class="selector">
				<label>Наименование:</label>
				<app-textbox name="Наименование" formControlName="name"></app-textbox>
			</div>

			<div class="selector">
				<label>УНП:</label>
				<app-textbox formControlName="unp" name="УНП"></app-textbox>
			</div>

			<div class="selector">
				<label>Адрес:</label>
				<app-textbox formControlName="address" name="Адрес"></app-textbox>
			</div>
		</form>
		<div class="second-line">
			<div class="count" *ngIf="!single">
				Выбрано: {{countOfChecked}}
			</div>
			<div class="list" #container
					(scroll)="onScroll(container.scrollHeight, container.scrollTop, container.clientHeight)">
				<div class="list-element" *ngFor="let el of addresses; let i = index">
					<app-icon (click)="checkItem(el)">
						{{ el.checked ? "check_box" : "check_box_outline_blank" }}</app-icon>
					<p>{{el.text}}</p>
				</div>
			</div>
			<app-pending *ngIf="popupPending$ | async" [isBlackTheme]="true"></app-pending>
		</div>
	</div>
	<div class="footer">
		<button class="save" appSkin="action" (click)="save()">Сохранить</button>
		<button class="save" appSkin="simple" (click)="resetFilter(true)">Сбросить</button>
		<button class="save" appSkin="simple" (click)="openPopup(false)">Отмена</button>
	</div>
</div>
