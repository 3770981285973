import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SseService } from "@app/user/user-core/server-sent-events.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
	selector: "app-inaction-popup",
	templateUrl: "./inaction-popup.component.html",
	styleUrls: ["./inaction-popup.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InactionPopupComponent implements OnInit, OnDestroy {
	public strTimer = "";
	public isTimerEnd = false;
	public timer?: ReturnType<typeof setInterval>;
	public endDate?: Date;

	constructor(
		public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
		private cdr: ChangeDetectorRef,
		private sse: SseService
	) { }

	public ngOnInit(): void {
		this.endDate = this.config.data.endDate;
		if (this.endDate) {
			this.timer = setInterval(() => {
				// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
				const msLeft = +this.endDate! - +new Date();
				if (msLeft <= 0) {
					this.isTimerEnd = true;
					localStorage.setItem("timer_end", "1");
					this.sse.eventSource?.close();
					this.cdr.detectChanges();
					if (this.timer) {
						clearTimeout(this.timer);
					}
				} else {
					const res = new Date(msLeft);
					const sec = res.getUTCSeconds() < 10 ? `0${res.getUTCSeconds()}` : `${res.getUTCSeconds()}`;
					this.strTimer = `0${res.getUTCMinutes()}:${sec}`;
					this.cdr.detectChanges();
				}
			}, 1000);
		}
	}

	public close(reload: boolean): void {
		this.ref.close(reload);
	}

	public ngOnDestroy(): void {
		this.timer && clearInterval(this.timer);
	}
}
