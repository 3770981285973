<section [ngClass]="progressUrl? 'iframeSection': null">
	<header>
		<label i18n>Подписать ЭЦП-М</label>
		<app-icon (click)="close()">close</app-icon>
	</header>
	<iframe *ngIf="progressUrl && showIFrame" [src]="url"></iframe>
	<main *ngIf="showMessage">
		<h1>{{showMessage}}</h1>
	</main>
	<footer>
		<button appSkin="simple-cancel" id="closeButton" (click)="close()">Закрыть</button>
	</footer>
</section>
<app-overlay></app-overlay>
<app-pending *ngIf="pending"></app-pending>
