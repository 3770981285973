import {
	ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges,
	ViewChild, ViewChildren
} from "@angular/core";
import { Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { select, Store, createSelector } from "@ngrx/store";
import { Document, DocumentProperty } from "@helper/abstraction/documents";
import { TemplateUtil } from "@helper/template-util";
import { CheckboxComponent } from "@shared/checkbox/checkbox.component";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { DocumentsState } from "@app/user/documents/documents-store/documents.reducer";
import { UserState } from "@app/user/user.reducer";
import { UserType } from "@helper/abstraction/user";

export interface Header {
	attribute?: string;
	key: string;
	name: string;
}

@Component({
	selector: "app-grid",
	templateUrl: "./grid.component.html",
	styleUrls: ["./grid.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent<T extends { id: number | string } = Document> implements OnInit, OnDestroy, OnChanges {
	public clickedItem?: T;
	public checkedRecs: number[] = [];
	public templateUtil = TemplateUtil;
	public listHeaders: Header[] = [];
	public pending$?: Observable<boolean | undefined>;
	@Input() public currentDocumentTypeId$: Observable<string | undefined>;
	public get clickedItemId(): number {
		const clickedItemId = this.clickedItem && this.clickedItem.id;
		return clickedItemId ? +clickedItemId : -1;
	}
	@Input() public stopScrolling = false;
	@Input() public userType?: UserType;
	@Input() public resetSelectedItems$?: Observable<boolean>;
	@Input() public items: T[] = [];
	@Input() public properties: DocumentProperty[] | HTMLElement = [];
	@Input() public selectedItems?: T[];
	@Input() public mainStoreName = "user";
	@Input() public storeName = "documents";
	@Input() public highlightItem?: T | string;
	@Input() public highlightId?: number | string;
	@Input() public withoutFooter = false;
	@Input() public withoutCheckbox = false;
	@Input() public withoutPointerCursor = false;
	@Input() public withSelectedItemsBlock = false;
	@Input() public isInvitation = false;
	@Input() public isSingleSelect = false;
	@Input() public isDisableClick = false;
	@Output() public massAction: EventEmitter<"send" | "delete"> = new EventEmitter<"send" | "delete">();
	@Output() public itemClick: EventEmitter<T> = new EventEmitter<T>();
	@Output() public showMore: EventEmitter<void> = new EventEmitter<void>();
	@Output() public selectItems: EventEmitter<T[]> = new EventEmitter<T[]>();
	@Output() public appDownloadId: EventEmitter<{ key: string; id: number }> = new EventEmitter<{ key: string; id: number }>();
	@Output() public moreHorizSelect: EventEmitter<{ action: string; item: T }> = new EventEmitter<{ action: string; item: T }>();
	@ViewChildren("checkbox") public checkboxes!: QueryList<CheckboxComponent>;
	@ViewChild(OverlayComponent, { static: true }) private overlayComponent?: OverlayComponent;
	private unsubscribe$$ = new Subject<void>();

	constructor(
		protected readonly store: Store<DocumentsState>
	) {
		const selectUser = (appState: any): any => appState[this.mainStoreName];
		const getPending = createSelector(selectUser, (state: UserState): boolean | undefined => state.pending);
		this.pending$ = this.store.pipe(select(getPending));

		const selectDocuments = (appState: any): DocumentsState | any => appState.documents;
		const selectCurrentDocumentType = createSelector(selectDocuments, (state: DocumentsState): string | undefined => (state || {}).currentDocumentTypeId);
		this.currentDocumentTypeId$ = this.store.pipe(select(selectCurrentDocumentType));
		if (this.isSingleSelect) this.withoutCheckbox = true;
	}

	public ngOnInit(): void {
		this.resetSelectedItems$?.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			this.clearSelectedItems();
		});
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.properties && simpleChanges.properties.currentValue) {
			if (this.properties instanceof Element || this.properties instanceof HTMLDocument)
				this.listHeaders = this.templateUtil.getArrayWithAttributes(this.properties as HTMLElement).map((e): Header => ({
					attribute: e[0].attribute,
					key: e[0].value,
					name: e[1]
				}));
			else if (Array.isArray(this.properties))
				this.listHeaders = this.properties.slice(0);
			else
				throw Error("Invalid data format");
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public clickItem(item: T): void {
		if (this.isDisableClick) {
			return;
		}
		if (this.clickedItem && item.id === this.clickedItem.id)
			this.clickedItem = undefined;
		else {
			this.clickedItem = item;
			this.itemClick.emit(this.clickedItem);
		}
	}

	public selectedItem(doc: T): void {
		if (typeof this.selectedItems === "undefined") {
			this.selectedItems = [];
		}
		if (this.isSingleSelect) {
			if (!this.selectedItems.find(selectedItems => JSON.stringify(selectedItems) === JSON.stringify(doc))) {
				this.selectedItems = Object.assign([], this.selectedItems);
				if (this.selectedItems.length) {
					this.selectedItems.pop();
				}
				this.selectedItems.push(doc);
				this.selectItems.emit(this.selectedItems);
			} else {
				this.selectedItems.pop();
				this.selectItems.emit(this.selectedItems);
			}
		}
		else {
			if (!this.selectedItems.find(selectedItems => JSON.stringify(selectedItems) === JSON.stringify(doc))) {
				this.selectedItems = Object.assign([], this.selectedItems);
				this.selectedItems.push(doc);
				this.selectItems.emit(this.selectedItems);
			} else {
				if (this.selectedItems.length > 0) {
					const newSelectedDocuments = this.selectedItems.filter((selectedDocument): boolean => JSON.stringify(selectedDocument) !== JSON.stringify(doc));
					this.selectedItems = newSelectedDocuments;
					this.selectItems.emit(newSelectedDocuments);
				} else {
					this.selectedItems = [];
					this.selectItems.emit([]);
				}
			}
		}
	}

	public isWithExtra(): boolean {
		return !!(this.properties as DocumentProperty[]).find(el => el.type === "extra");
	}

	public checkedItem(document: T): boolean {
		if (this.selectedItems){
			return this.selectedItems.some((selectItem: T): boolean => JSON.stringify(selectItem) === JSON.stringify(document));
		}
		else{
			return false;
		}
	}

	public clearSelectedItems(): void {
		this.selectedItems = [];
		this.selectItems.emit([]);
	}

	public selectAllItems(documents: T[]): void {
		if (!documents.length) return;
		this.selectedItems = documents;
		this.selectItems.emit(documents);
	}

	public stopPropagation(event: MouseEvent): void {
		event.stopPropagation();
	}

	public onScroll(scrollHeight: number, scrollTop: number, height: number): void {
		if (scrollTop === 0){
			return;
		}
		if ((scrollHeight - (height + scrollTop + 1)) / scrollHeight <= 0 && !this.stopScrolling){
			this.showMore.emit();
		}
	}

	public openUrl(key: string, url?: string, id?: number): void {
		if (url) {
			const urlId = +url.split("=")[1];
			this.appDownloadId.emit({ key, id: urlId });
		}
		if (id) {
			this.appDownloadId.emit({ key, id });
		}
	}

	public handleMoreHorizAction(action: string, item: T): void {
		this.moreHorizSelect.emit({ action, item });
	}
	public isUserStatuses(item: any): boolean {
		return item?.userStatus && (item.userStatus.id === "BLOCKED" || item.userStatus.id === "DELETED");
	}

	public isEqual(item: T): boolean {
		return this.highlightItem
			? JSON.stringify(this.highlightItem) === JSON.stringify(item)
			: this.highlightId
				? this.highlightId === item.id
				: false;
	}

	public isShow(item: any): boolean {
		if (this.isInvitation) {
			return item.status !== "SENT_TO_PARTNER" && item.status !== "PARTNER_REGISTERED";
		}
		return true;
	}
}
