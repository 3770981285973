import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { GeneralGLN, GeneralGLNsDto, GeneralGLNsParams } from "@helper/abstraction/generalGLN";
import { StorageCreateDto, StoragesDto, TypedStoragesParams } from "@helper/abstraction/storages";
import { SelectBoxSelfFetchState } from "./storage-grid/storage-grid.component";
import { DocumentType } from "../../helper/abstraction/documents";
import { EwaybillCreateStoragePopupComponent } from "@app/user/documents/ewaybill/ewaybill-shared-components/ewaybill-create-storage-popup/ewaybill-create-storage-popup.component";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { createStorage, createStorageSuccess, findInfo, findInfoError, findInfoSuccess } from "@app/user/customization/my-organization/storages/storage-store/storage.actions";
import { OverlayService } from "@core/overlay.service";
import { TranslationService } from "@core/translation.service";
import { TemplateUtil } from "@helper/template-util";
import { StorageCreatePopupComponent } from "@app/user/customization/my-organization/storages/storage-create-popup/storage-create-popup.component";
import { addStorageForReceiverSuccess } from "@app/user/customization/customization-store/customization.actions";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-storage-popup",
	templateUrl: "./storage-popup.component.html",
	styleUrls: ["./storage-popup.component.scss"],
})
export class StoragePopupComponent implements OnDestroy, OnInit {
	public form?: FormGroup;
	@Input() public partnerId?: number;
	@Input() public storageNameButton?: string;
	@Input() public consigneeId?: number;
	@Input() public mode?: string;
	@Input() public documentTypeId?: DocumentType;
	@Input() public isInternalStatus?: boolean;
	@Input() public formType?: number;
	@Input() public lln?: string;
	@Output() public close$: Subject<void> = new Subject<void>();
	@ViewChild("tabsTemplate", { static: true })
	public set tabsMap(elementRef: ElementRef<HTMLElement>) {
		this.tabs = Array.from(TemplateUtil.getMap(elementRef.nativeElement)).map((e): [string, string] => e.reverse() as [string, string]);
	}
	@ViewChild("placesTemplate", { static: true })
	public set placesMap(elementRef: ElementRef<HTMLElement>) {
		this.places = Array.from(TemplateUtil.getMap(elementRef.nativeElement)).map((e): [string, string] => e.reverse() as [string, string]);
	}
	public tabs: [string, string][] = [];
	public places: [string, string][] = [];
	public process$: Subject<any> = new Subject<any>();
	public isGln = false;
	public updateStorageForReceiver$?: Subject<void> = new Subject<void>();

	public generalGLNSelectBoxOption = {
		getData$: (selectBoxState: SelectBoxSelfFetchState): Observable<GeneralGLNsDto> => {
			const params: GeneralGLNsParams = { ...selectBoxState, searchText: selectBoxState.search };
			return this.userBackendService.nsi.generalGLN.list.get$(params);
		},
		mapData: (data: any[]): [any, string][] => data.map(e => [e, [e.address, e.gln].filter(e => !!e).join(", ")])
	};

	public storagePointsSelectBoxOption = {
		getData$: (selectBoxState: SelectBoxSelfFetchState): Observable<StoragesDto> => {
			const params: TypedStoragesParams = {
				...selectBoxState,
				searchText: selectBoxState.search,
				partnerId: this.partnerId,
				documentTypeId: this.documentTypeId,
				storageTypeId: this.mode === "loading" ? "LOADING_PLACE" : "UNLOADING_PLACE",
			};
			return this.userBackendService.organization.storages.list.getByStorageType$(params);
		},
		mapData: (data: any[]): [any, string][] => data.map(e => [e, [e.addressFull, e.storageName, e.gln].filter(e => !!e).join(", ")])
	};

	@ViewChild("createStorage", { static: false }) private readonly overlayComponent?: OverlayComponent;
	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(
		private readonly store: Store,
		private changeDetectorRef: ChangeDetectorRef,
		private actions: Actions,
		private userBackendService: UserBackendService,
		private overlayService: OverlayService,
		private translationService: TranslationService,
		private formBuilder: FormBuilder,
	) { }

	public ngOnInit(): void {
		this.tabs.filter(i => i[1] === "place").forEach(item => {
			const placeName = this.places.find(elem => elem[1] === this.mode || elem[1] === this.storageNameButton);
			if (placeName) {
				item[0] = placeName[0];
			}
		});
		this.initForm();
		this.actions.pipe(
			ofType(createStorageSuccess, addStorageForReceiverSuccess),
			takeUntil(this.unsubscribe$$)
		).subscribe((action) => {
			action.type === "[Storage] Create Storage Success" ? this.addStorageToDoc(action.storage) : this.updateStorageForReceiver$?.next();
			this.overlayComponent?.destroy();
			this.overlayService.showNotification$(this.translationService.getTranslation("createStorageSuccess"), "success");
		});

		this.actions.pipe(
			ofType(findInfoSuccess),
			takeUntil(this.unsubscribe$$)
		).subscribe(action => this.addFactoringStorage(action.storage));

		this.actions.pipe(
			ofType(findInfoError),
			takeUntil(this.unsubscribe$$)
		).subscribe(error => this.overlayService.showNotification$(error.error.error, "error"));

		this.form?.valueChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe((value) => {
			this.isGln = value.tab === "gln";
			this.changeDetectorRef.detectChanges();
		});
	}

	public initForm(): void {
		this.form = this.formBuilder.group({ tab: ["place"] });
		this.form.updateValueAndValidity();
	}

	public addStorage(): void {
		if (!this.consigneeId) {
			this.overlayService.showNotification$(this.translationService.getTranslation("createStorageError"), "error");
			return;
		}
		if (this.formType === 1) {
			this.store.dispatch(findInfo());
			return;
		}
		const component = this.overlayComponent?.show(EwaybillCreateStoragePopupComponent, {
			inputs: { id: this.consigneeId },
			centerPosition: true
		});
		component?.instance.close$.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			this.overlayComponent?.destroy();
		});
		component?.instance.process$.pipe(takeUntil(this.unsubscribe$$)).subscribe(gln => {
			this.consigneeId && this.store.dispatch(createStorage(this.consigneeId, gln));
		});
	}

	private addFactoringStorage(storage: StorageCreateDto): void {
		const component = this.overlayComponent?.show(StorageCreatePopupComponent, {
			inputs: {
				isGln: this.formType !== 1,
				formType: this.formType,
				formValue: storage,
				receiverLln: this.lln
			},
			centerPosition: true
		});
		component?.instance.close$.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			this.overlayComponent?.destroy();
		});
	}

	public addStorageToDoc(event: Storage | GeneralGLN | StorageCreateDto): void {
		this.process$.next({ ...event, isPublicGln: this.isGln });
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
