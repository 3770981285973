<div class="grid-container" #container
	(scroll)="onScroll(container.scrollHeight, container.scrollTop, container.clientHeight)">
	<table *ngIf="items && listHeaders">
		<thead [class.error-transit]="isDisableClick && !items.length">
			<tr [ngClass]="withSelectedItemsBlock? 'tall-header': ''">
				<th></th>
				<th *ngIf="items.length">
					<div *ngIf="!withoutCheckbox && !isSingleSelect" class="icon">
						<app-icon *ngIf="selectedItems && selectedItems?.length > 0 && selectedItems?.length < items.length"
							(click)="selectAllItems(items)">
							indeterminate_check_box</app-icon>
						<app-icon *ngIf="!selectedItems || selectedItems?.length === 0" (click)="selectAllItems(items)">
							check_box_outline_blank</app-icon>
						<app-icon *ngIf="items.length > 0 && selectedItems && selectedItems?.length === items.length"
							(click)="clearSelectedItems()">
							check_box</app-icon>
					</div>
				</th>
				<th *ngFor="let header of properties" [attr.class]="isWithExtra()? 'upZ':null" >{{header.name}}</th>
				<div class="selected-items-block" *ngIf="withSelectedItemsBlock && selectedItems?.length > 0">
					<div class="selected-items-wrapper">
						<p>Выбрано {{ selectedItems.length }} приглашения</p>
						<app-button icon="send" (click)="massAction.next('send')">Обработать и отправить</app-button>
						<app-button icon="delete" (click)="massAction.next('delete')">Удалить</app-button>
					</div>
				</div>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of items" (click)="clickItem(item)"
				[class.visited]="isEqual(item)"
				[attr.class]="item?.processingStatus?.id==='SENT' || item.read ? 'documents-table': item.read === false? 'unread' : null"
				[class.checked]="isUserStatuses(item) ? false : checkedItem(item)"
				[class.pointer]="withoutPointerCursor"
				[class.redBackGround]="isUserStatuses(item)">
				<td></td>
				<td *ngIf="items.length">
					<div *ngIf="!withoutCheckbox || isSingleSelect" class="icon" (click)="stopPropagation($event)">
						<app-icon *ngIf="checkedItem(item)" (click)="selectedItem(item)">
							check_box</app-icon>
						<app-icon *ngIf="!checkedItem(item)" (click)="selectedItem(item)">
							check_box_outline_blank</app-icon>
					</div>
				</td>
				<td *ngFor="let header of properties">
					<ng-container [ngSwitch]="header.type">
						<div [class]="item[header.key]?.url ? 'link': ''" *ngSwitchCase="'link'"
							(click)="openUrl(header.key, item[header.key]?.url)">
							{{item[header.key]?.description}}
						</div>
						<div class="icon" *ngSwitchCase="'status'" [ngClass]="item[header.key].error? 'error': '' ">
							{{item[header.key]?.name}}
							<div class="error-wrapper">{{item[header.key]?.error}}</div>
						</div>
						<div *ngSwitchCase="'date'">
							{{header.key === "dateTime" ? (item[header.key] | date: "dd.MM.yyyy HH:mm:ss"):(item[header.key] | date: "dd.MM.yyyy")}}
						</div>
						<div *ngSwitchCase="'dateWithTime'">
							{{item[header.key] | date: "dd.MM.yyyy HH:mm:ss"}}
						</div>
						<div *ngSwitchCase="'dateWithText'">
							{{item[header.key] ? (item[header.key] | date: "dd.MM.yyyy HH:mm:ss"): "Нет ответа"}}
						</div>
						<div *ngSwitchCase="'boolean'">
							<app-icon>{{item[header.key] ? "done" : "clear"}}</app-icon>
						</div>
						<div *ngSwitchCase="'custom'">
							{{ header['resolveFn'] ? header['resolveFn'](item[header.key]) : "No resolve Fn" }}
						</div>
						<div *ngSwitchCase="'extra'">
							<app-icon *ngIf="isShow(item)" [appDropdown]="actions">more_horiz</app-icon>
								<ng-template #actions let-ctx>
									<div class="dropdown-wrapper">
										<ul class="dropdown">
											<li *ngIf="item.status !== 'VERIFICATION_ERROR'" (click)="handleMoreHorizAction('send', item); ctx.close()" i18n>
												<app-icon>send</app-icon>
												Обработать и отправить
											</li>
											<li (click)="handleMoreHorizAction('edit', item); ctx.close()" i18n>
												<app-icon>edit</app-icon>
												Редактировать
											</li>
											<li class="red" (click)="handleMoreHorizAction('delete', item); ctx.close()" i18n>
												<app-icon>delete</app-icon>
												Удалить
											</li>
										</ul>
									</div>
								</ng-template>
						</div>
						<div *ngSwitchDefault>{{item[header.key]}}</div>
					</ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<footer *ngIf="items && items.length > 0 && !withoutFooter" [ngSwitch]="currentDocumentTypeId$ | async">
	<ng-container *ngSwitchCase="'EWAYBILL'" i18n>
		Выделено электронных накладных: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'ORDERS'">
		Выделено заказов: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'DESADV'">
		Выделено уведомлений об отгрузке: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'EINVOICE'">
		Выделено электронных актов: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'EINVOICEPMT'">
		Выделено электронных счетов-фактур: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'MANAGEMENT'">
		Выделено пользователей: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'TRANSIT'">
		Выделено электронных сообщений о транзите: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'EDOCUMENT'">
		Выделено электронных документов: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'MNS'">
		Всего записей: {{items.length}}
	</ng-container>
	<ng-container *ngSwitchCase="'INVITATION'">
		Выделено приглашений: {{selectedItems ? selectedItems!.length: 0}} из {{items.length}}
	</ng-container>
</footer>
<app-pending *ngIf="pending$ | async" [isBlackTheme]="true"></app-pending>
