<section>
	<main>
		<div class="message-block">
      <i class="pi {{ icon }}"></i>
      <span class="message-text">
        {{ message }}
      </span>
		</div>
    <ng-container *ngIf="showStatus">
      <div class="status-block">
        <p-checkbox [(ngModel)]="checkedStatus" [binary]="true" inputId="binary"></p-checkbox>
        <span class="status-text">
          Больше не показывать это сообщение.
        </span>
      </div>
    </ng-container>
	</main>
  <hr>
	<footer>
		<button appSkin="no-shadow bold bordered" class="blue-action" type="button" (click)="close(true)">{{ successButtonName }}</button>
		<button appSkin="simple no-shadow bold bordered" type="button" (click)="close()">{{ cancelButtonName }}</button>
	</footer>
</section>
