<section>
	<div class="error">
		<app-icon>report_problem</app-icon>
		Ошибка, не запущен прокси
	</div>
	<div class="main">
		На вашем компьютере не установлено и не запущено криптографическое программное обеспечение SC-CryptoProxy. Запустите
		его или перейдите по кнопке «Установить SC-CRYPTOPROXY», чтобы установить необходимое ПО
	</div>
	<footer>
		<button class="save" appSkin="action-save bold" (click)="install()">Установить SC-CRYPTOPROXY</button>
		<button class="save" appSkin="simple no-shadow bold bordered" (click)="close()">Отмена</button>
	</footer>
</section>