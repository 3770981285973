import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

import { SelectBoxSelfFetchState, SelfFetchOption } from '@shared/storage-popup/storage-grid/storage-grid.component';

@Component({
	selector: 'app-party-grid',
	templateUrl: './party-grid.component.html',
	styleUrls: ['./party-grid.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartyGridComponent<T> implements OnChanges, OnDestroy, AfterViewInit {
	@Input("option") public selfFetchOption: SelfFetchOption<T>;
	@Input() public mode: "CREATE" | "VIEW" | "EDIT" | "SELECT" = "SELECT";
	@Input() public dataType: "STORAGES" | "PARTY" = "PARTY";
	@Output() public appFormValue = new EventEmitter<T>();
	public data: [any, string][] = [];
	public input$$ = new Subject<string>();
	private initialState: SelectBoxSelfFetchState = { page: 1, size: 30 };
	private state$$: BehaviorSubject<SelectBoxSelfFetchState>;
	private stateTracker$$?: Subject<void>;
	public unsubscribe$$: Subject<void> = new Subject<void>();

	private defaultOption: SelfFetchOption<T> = {
		getInitialState: (): SelectBoxSelfFetchState => ({ ...this.initialState }),
		nextChunk: (state): SelectBoxSelfFetchState => ({ ...state, page: state.page + 1 })
	};

	constructor(
		private changeDetector: ChangeDetectorRef
	) {
		this.selfFetchOption = { ...this.defaultOption };
		this.state$$ = new BehaviorSubject<SelectBoxSelfFetchState>(this.selfFetchOption.getInitialState());
		this.input$$.pipe(
			debounceTime(250),
			takeUntil(this.unsubscribe$$)
		).subscribe(text => this.onInput(text));
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
			this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
		}
	}

	public ngAfterViewInit(): void {
		this.setData(this.data);
		this.unfold();
		this.changeDetector.markForCheck();
	}

	public select(item: [any, string]): void {
		this.collapse();
		this.appFormValue.emit(item[0]);
		this.changeDetector.markForCheck();
	}

	public onInput(search: string): void {
		this.resetData();
		this.cleanStateTracker();
		const newState = { ...this.selfFetchOption.getInitialState(), search };
		this.state$$.next(newState);
		this.stateTracker$$ = this.trackState$$();
	}

	public onScrolled(): void {
		this.state$$.next(this.selfFetchOption.nextChunk(this.state$$.value));
	}

	public collapse(): void {
		this.cleanStateTracker();
		this.resetData();
	}

	public unfold(): void {
		this.state$$.next(this.selfFetchOption.getInitialState());
		this.stateTracker$$ = this.trackState$$();
	}

	public ngOnDestroy(): void {
		this.cleanStateTracker();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public trackState$$(): Subject<void> {
		const unsubscribe$$ = new Subject<void>();
		this.state$$.pipe(
			takeUntil(this.unsubscribe$$),
			takeUntil(unsubscribe$$)
		).subscribe(state => {
			if (!this.selfFetchOption.getData$)
				throw Error("No getData$ using for enrich date");

			this.selfFetchOption.getData$(state).pipe(
				take(1),
				map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
				takeUntil(this.unsubscribe$$),
				takeUntil(unsubscribe$$)
			).subscribe(
				data => {
					if (Array.isArray(data) && !data.length) {
						unsubscribe$$.next();
						unsubscribe$$.complete();
					}
					this.appendData(data);
					this.changeDetector.detectChanges();
				}
			);
		});
		return unsubscribe$$;
	}

	private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

	private resetData(): void {
		this.data = [];
	}

	private appendData(newData: [any, string][]): void {
		this.data = [...this.data, ...newData];
	}

	private setData(newData: [any, string][]): void {
		this.data = newData;
	}
}
