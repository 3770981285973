import { Component, ChangeDetectionStrategy } from "@angular/core";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-install-proxy-popup",
	styleUrls: ["install-proxy-popup.component.scss"],
	templateUrl: "./install-proxy-popup.component.html"
})

export class InstallProxyPopupComponent extends DefaultPopupComponent {
	public install(): void {
		console.log("install");
	}
}
