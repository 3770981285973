import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { openInactionPopup } from "@app/app.actions";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const timer = 3420000;

@Injectable()
export class InactionInterceptor implements HttpInterceptor {
	public timerId?: ReturnType<typeof setTimeout>;

	constructor(
		private store: Store
	) { }

	public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(httpRequest).pipe(
			map(data => {
				if ((data as any).status === 200) {
					if (this.timerId) {
						clearTimeout(this.timerId);
					}
					this.timerId = setTimeout(() => {
						this.store.dispatch(openInactionPopup());
					}, timer);
				}
				return data;
			})
		);
	}
}
