<div class="date">
	<div class="input">
		<input #date (input)="onInputDate($event.target.value)" (click)="$event.preventDefault();" type="date" required>
		<button type="button" appSkin="simple-cancel" (click)="clear()">Очистить</button>
	</div>
	<div class="date-pickers">
		<app-date-picker [value]="value" [date]="value" (valueChange)="onValueChange($event)" [dayLimit]="dayLimit" [isDateLimit]="isDateLimit">
			<!-- (keyup.enter)="onEnter($event)" -->
		</app-date-picker>
	</div>
</div>

<ng-container *ngIf="!hideTime">
	<app-time-picker *ngIf="withTime" [value]="value" (appEmitTime)="emitTime($event)"></app-time-picker>
</ng-container>

<template #messageValue>
	<dl>
		<dt>maxDateError</dt>
		<dd>Дата не может быть больше, чем </dd>
		<dt>minDateError</dt>
		<dd>Дата не может быть меньше, чем </dd>
	</dl>
</template>
