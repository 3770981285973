import {
	ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnChanges, Output,
	SimpleChanges, Type, ChangeDetectorRef, OnInit
} from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Storage } from "@helper/abstraction/storages";
import { TemplateUtil } from "@helper/template-util";
import { BoxValueAccessor } from "../box-value-accessor/BoxValueAccessor";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-multiselect-box",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<MultiSelectBoxComponent> => MultiSelectBoxComponent)
	}],
	templateUrl: "./multiselect-box.component.html",
	styleUrls: ["./multiselect-box.component.scss"],
})

export class MultiSelectBoxComponent extends BoxValueAccessor implements OnChanges, OnInit {
	@Input() public data: [any, string][] | HTMLElement = [];
	@Input() public valueTransformFn?: (value: any) => string;
	@Input() public formControlName?: string;
	@Input() public placeholder?: string = "";
	@Input() public displayValidation = true;
	@Input() public userStatusesList = false;
	@Input() public formControl?: AbstractControl;
	@Input() public stopScrolling = false;
	@Input() public withoutPending = false;
	@Input() public error = false;
	@Input() public showSearch = true;
	@Input() public set otherEmptyValue(element: HTMLElement) {
		this.presetValues = TemplateUtil.getArray(element);
	}
	@Input() public autoStopScroll?: boolean;
	public presetValues: [any, string][] = [];
	public list: [any, string][] = [];
	public expand = false;
	public pending = false;
	public showError = false;
	public control?: AbstractControl | null;

	public unsubscribe$$ = new Subject<void>();
	@Output() public appFilterChanges: EventEmitter<{ search: string }> = new EventEmitter<{ search: string }>();
	@Output() public resetList: EventEmitter<void> = new EventEmitter<void>();
	@Output() public appNextPage: EventEmitter<void> = new EventEmitter<void>();
	@Output() public appCollapse = new EventEmitter<void>();
	private templateUtil = TemplateUtil;

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		public readonly controlContainer: ControlContainer
	) {
		super();
	}

	public ngOnInit(): void {
		if (!this.displayValidation)
			return;

		if (this.formControl)
			this.control = this.formControl;

		if (this.controlContainer && this.formControlName && this.controlContainer.control)
			this.control = this.controlContainer.control.get(this.formControlName);

		// Subscribe need to change control touched state
		this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error)
				this.showError = this.control && this.control.touched && this.control.invalid || false;
			else
				this.showError = this.error;

			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data) {
			if (this.data instanceof Element || this.data instanceof HTMLDocument){
				this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.templateUtil.getArray(this.data as HTMLElement));
				if(!this.withoutPending) this.pending = false;
				this.changeDetectorRef.markForCheck();
			}
			else if (Array.isArray(this.data)) {
				this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.data.slice(0));
				if(!this.withoutPending) this.pending = false;
				this.changeDetectorRef.markForCheck();
			}
			else
				throw Error("Invalid data format");
		}
	}

	public switchExpandState(event: Event): void {
		event.stopPropagation();
		this.expand = !this.expand;
		if (this.expand) {
			if(!this.withoutPending) this.pending = true;
			this.appCollapse.emit();
			this.changeDetectorRef.markForCheck();
			return;
		}
		this.resetList.emit();
	}

	public readonly trackByFn = (index: number, item: Storage): any => item.id;

	public deleteItem(index: number, event: Event): void {
		event.stopPropagation();
		const arr = this.value ? [...this.value] : [];
		arr.splice(index, 1);
		if (this.userStatusesList && !arr.length) {
			arr.push({ id: "ALL", name: "Все статусы" });
		}
		this.value = arr;
		if (this.onChange)
			this.onChange(this.value);

		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public addItem(item: [any, string]): void {
		if (!this.value) {
			this.value = [];
		}
		if (this.userStatusesList) {
			if (this.value.find((el: any) => el.id === "ALL") && item[0].id !== "ALL" || !(this.value.find((el: any) => el.id === "ALL")) && item[0].id === "ALL") {
				this.value = [];
				const arr = [];
				arr.push(item[0]);
				this.value = arr;
			}
		}
		const itemExist: boolean = this.value.some((el: any) => el.id === item[0].id);
		if (!itemExist) {
			const arr = this.value ? [...this.value] : [];
			arr.push(item[0]);
			this.value = arr;
		}

		if (this.onChange)
			this.onChange(this.value);

		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public writeValue(value: any): void {
		if (!value) {
			this.value = [];
		} else {
			this.value = value;
		}

		if (this.onChange)
			this.onChange(this.value);
		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public onScrolled(): void {
		if (!this.stopScrolling) {
			if(!this.withoutPending) this.pending = true;
			this.appNextPage.emit();
			this.changeDetectorRef.markForCheck();
		}
	}

	public onClickOutside(): void {
		if(this.expand){
			this.expand = false;
			this.resetList.emit();
		}
	}

	public onInput(value: string): void {
		this.resetList.emit();
		setTimeout(() => {
			this.list = [];
			if(!this.withoutPending)this.pending = true;
			this.appFilterChanges.emit({ search: value });
			this.changeDetectorRef.markForCheck();
		}, 0);
	}
}
