<ul #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)">
	<div *ngFor="let element of list; let i = index">
		<div class="header">
			<app-icon *ngIf="getSelectedItemsByIndex(i) && getSelectedItemsByIndex(i)?.length > 0 && getSelectedItemsByIndex(i)?.length < element[0].items.length"
				(click)="selectAllItems(i, element[0].items)"> indeterminate_check_box </app-icon>
			<app-icon *ngIf="getSelectedItemsByIndex(i)?.length === 0" (click)="selectAllItems(i, element[0].items)">
				check_box_outline_blank</app-icon>
			<app-icon *ngIf="getSelectedItemsByIndex(i) && getSelectedItemsByIndex(i)?.length === element[0].items.length"
				(click)="clearSelectedItems(i)">
				check_box</app-icon>
			<b>{{element[1]}}</b>
		</div>
		<li *ngFor="let item of element[0].items" (click)="selectItem($event, i, item)">
			<div class="content">
				<div class="icon">
					<app-icon *ngIf="checkedItem(i, item)" (click)="selectItem($event, i, item)"> check_box </app-icon>
					<app-icon *ngIf="!checkedItem(i, item)" (click)="selectItem($event, i, item)"> check_box_outline_blank </app-icon>
				</div>
				<span>
					{{item.name}}
				</span>
			</div>
		</li>
		<div *ngIf="i < list.length-1" class="delimiter"></div>
	</div>
</ul>
