import { OverlayComponent } from "@shared/overlay/overlay.component";
import { AfterViewInit, ChangeDetectionStrategy, Component, OnChanges, Input, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter, Output, OnInit, HostListener } from "@angular/core";
import { Time } from "@angular/common";
import { TextUtil } from "@helper/text-util";
import { OverlayService } from "@core/overlay.service";
import { TemplateUtil } from "@helper/template-util";

@Component({
	selector: "app-select-date",
	templateUrl: "./select-date.component.html",
	styleUrls: ["./select-date.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDateComponent implements OnInit, AfterViewInit, OnChanges {
	public textUtil = TextUtil;
	public messages: Map<string, string> = new Map<string, string>();
	@Input() public value?: Date;
	@Input() public withTime = false;
	@Input() public hideTime = false;
	@Input() public dayLimit?: number;
	@Input() public isDateLimit?: boolean;
	@Input() public errorOverlay?: OverlayComponent;
	@Output() public appEnter = new EventEmitter<Date | void>();
	@Output() public appSetNewDate = new EventEmitter<Date | void>();
	@Output() public appSetTime = new EventEmitter<Time | void>();
	@Output() public appClear = new EventEmitter<void>();
	@ViewChild("date", { static: true }) public date?: ElementRef<HTMLInputElement>;
	@ViewChild("messageValue", { static: true }) public set messageValue(elementTemplate: ElementRef) {
		this.messages = TemplateUtil.getMap(elementTemplate.nativeElement);
	}
	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly overlayService: OverlayService,
	) { }

	public ngOnInit(): void {
		this.appSetNewDate.emit(this.value);
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.value && simpleChanges.value.currentValue) {
			this.sync();
		}
	}
	@HostListener("document:keydown.enter") public hide(): void {
		this.tryClose();
	}

	@HostListener("document:keydown.space", ["$event"]) public preventDefaultSpaceBar($event: KeyboardEvent): void {
		$event.preventDefault();
	}

	public onValueChange(value: Date): void {
		this.value = value;
		this.changeDetectorRef.detectChanges();
		this.sync();
		this.setDate(this.value);
		this.tryClose();
	}

	public emitTime(time: Time): void {
		this.appSetTime.emit(time);
	}

	public ngAfterViewInit(): void {
		this.date && this.date.nativeElement.focus();
	}

	public onInputDate(stringDate: string): void {
		if (stringDate.split("-")[0].length > 4) { return; }

		const today = new Date();
		const min = new Date(1900, 0, 1);
		const max = this.dayLimit && !this.isDateLimit
			? new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.dayLimit, 23, 0, 0)
			: new Date(today.getFullYear() + 10, today.getMonth(), today.getDate(), 23, 0, 0);
		let difference = false;

		if (stringDate && stringDate.split("-")[0].length === 4) {
			const date = new Date(stringDate);
			this.value = date;
			let messageValue = "";
			this.sync();
			if (+stringDate.split("-")[0] < 999) {
				return;
			} else {
				const minTextUtil = ` ${this.textUtil.toTwoSymbol(min.getDate())}.${this.textUtil.toTwoSymbol(min.getMonth() + 1)}.${this.textUtil.toFourSymbol(min.getFullYear())}`;
				const maxTextUtil = ` ${this.textUtil.toTwoSymbol(max.getDate())}.${this.textUtil.toTwoSymbol(max.getMonth() + 1)}.${this.textUtil.toFourSymbol(max.getFullYear())}`;
				if (date.getTime() < min.getTime()) {
					messageValue = this.messages.get("minDateError") + minTextUtil;
					min.setFullYear(today.getFullYear());
					this.value = min;
				}
				if (date.getTime() > max.getTime()) {
					messageValue = this.messages.get("maxDateError") + maxTextUtil;
					max.setFullYear(today.getFullYear());
					this.value = max;
				}

				if (messageValue) {
					difference = true;
					(this.errorOverlay ? this.errorOverlay : this.overlayService).showNotification$(messageValue, "error");
				}
			}
			this.setDate(this.value, difference);
		}
	}

	public tryClose(): void {
		// this.appSetNewDate.emit(this.value);
		this.appEnter.emit(this.value);
	}

	public setDate(date: Date, dif = false): void {
		this.value = date;
		this.changeDetectorRef.detectChanges();
		this.appSetNewDate.emit(this.value);
		if (dif) { this.sync(); }
	}

	public sync(): void {
		if (this.date && this.value && this.value.toString() !== "Invalid Date")
			this.date.nativeElement.value = this.getDateString(this.value);
	}

	public clear(): void {
		this.appClear.emit();
	}

	private getDateString(date: Date): string {
		return this.textUtil.toFourSymbol(date.getFullYear()) + "-" + this.textUtil.toTwoSymbol(date.getMonth() + 1) + "-" + this.textUtil.toTwoSymbol(date.getDate());
	}
}
