import { ImportInterface } from "@shared/shared-interfaces";
import { Role } from "@helper/abstraction/roles";
import { createAction } from "@ngrx/store";
import { UserProfile, UserType } from "@helper/abstraction/user";
import { Organization } from "@helper/abstraction/organization";
import { DocumentType } from "@helper/abstraction/documents";
import { HttpErrorResponse } from "@angular/common/http";
import { DraftType } from "@helper/abstraction/draft";
import {
	StatisticOrderParams,
	StatisticEwaybillParams,
	StatisticTabs,
	StatisticTransitParams
} from "@helper/abstraction/statistic";
// import { CreateRegistryRq } from "./user-core/api/export";
// import { ImportTradeItemListDto } from "./marks/marks-store/marks-types";
import { PushNotification } from "@helper/abstraction/notifications";
import { SignAParams, SignedSCCryptoDto } from "@helper/abstraction/sccrypto";
export const setToken = createAction(
	"[Mns] Set Token",
	(payload?: string): { token?: string } => ({ token: payload })
);

export const setRoles = createAction(
	"[Mns] Set roles",
	(payload: Role[]): { roles: Role[] } => ({ roles: payload })
);

export const getRoles = createAction("[Mns] Get roles");

export const getRolesSuccess = createAction(
	"[Mns] Get roles success",
	(payload: Role[]): { roles: Role[] } => ({ roles: payload })
);

export const getRolesError = createAction(
	"[Mns] Get roles error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const getPermissions = createAction("[Mns] Get Permissions");

export const getPermissionsSuccess = createAction(
	"[Mns] Get Permissions Success",
	(payload: string[]): { permissions: string[] } => ({ permissions: payload })
);

export const getPermissionsError = createAction(
	"[Mns] Get Permissions Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getUserType = createAction(
	"[Mns] Get User Type",
);

export const getUserTypeSuccess = createAction(
	"[Mns] Get User Type Success",
	(payload: UserType): { userType: UserType } => ({ userType: payload })
);

export const getUserTypeError = createAction(
	"[Mns] Get User Type Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getStatisticTabs = createAction(
	"[Mns] Get Statistics Tabs",
);

export const getStatisticTabsSuccess = createAction(
	"[Mns] Get Statistics Tabs",
	(payload: StatisticTabs): { statisticTabs: StatisticTabs } => ({ statisticTabs: payload })
);

export const getStatisticTabsError = createAction(
	"[Mns] Get Statistics Tabs",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getProfile = createAction("[Mns] Get Profile");
export const getProfileError = createAction(
	"[Mns] Get Profile Error",
	(payload: Error): { error: Error } => ({ error: payload })
);
export const getProfileSuccess = createAction(
	"[Mns] Get Profile Success",
	(payload: UserProfile): { userProfile: UserProfile } => ({ userProfile: payload })
);

export const resetNotificationCount = createAction(
	"[Mns] Reset Notification Count",
	(): { notificationCount: undefined } => ({ notificationCount: undefined })
);

export const getNotificationCount = createAction(
	"[Mns] Get Notification Count",
	(payload: { page: number; size: number }): { page: number; size: number } => ({ page: payload.page, size: payload.size })
);

export const getNotificationCountSuccess = createAction(
	"[Mns] Get Notification Count Success",
	(payload: PushNotification): { notificationCount: PushNotification } => ({ notificationCount: payload })
);

export const getNotificationCountError = createAction(
	"[Mns] Get Notification Count Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const getOrganization = createAction("[Mns] Get Organization");
export const getOrganizationError = createAction(
	"[Mns] Get Organization Error",
	(payload: Error): { error: Error } => ({ error: payload })
);
export const getOrganizationSuccess = createAction(
	"[Mns] Get Organization Success",
	(payload: Organization): { organizationInfo: Organization } => ({ organizationInfo: payload })
);


export const restoreUserPassword = createAction(
	"[Mns] Restore User Password",
	(email: string): { email: string } => ({ email })
);

export const restoreUserPasswordSuccess = createAction(
	"[Mns] Restore User Password Success"
);

export const restoreUserPasswordError = createAction(
	"[Mns] Restore User Password Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const checkApprovedCode = createAction(
	"[Mns] Check Approved Code",
	(payload: { code: string; userId: number }): { code: string; userId: number } => ({ code: payload.code, userId: payload.userId })
);

export const checkApprovedCodeSuccess = createAction("[Mns] Check Approved Code Success");

export const checkApprovedCodeError = createAction(
	"[Mns] Check Approved Code Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

// -------------------ACTIONS WITH EXPORT XML DOCUMENTS-------------------
export const exportXMLDocuments = createAction(
	"[Mns] Export XML Documents",
	(payload: { documentType: DraftType; documentIds: number[]; isStatisticValue?: boolean; statisticDocType?: string }): { params: { documentType: DraftType; documentIds: number[]; isStatisticValue?: boolean; statisticDocType?: string } } => ({ params: payload })
);

export const exportXMLDocumentsSuccess = createAction(
	"[Mns] Export XML Documents Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportXMLDocumentsError = createAction(
	"[Mns] Export XML Documents Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const exportErrorXMLDocuments = createAction(
	"[Mns] Export Error XML Documents",
	(payload: { errorIdList: number[] }): { params: { errorIdList: number[] } } => ({ params: payload })
);

export const exportErrorXMLDocumentsSuccess = createAction(
	"[Mns] Export Error XML Documents Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportErrorXMLDocumentsError = createAction(
	"[Mns] Export Error XML Documents Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

// -------------------ACTIONS WITH EXPORT XLSX Orders-------------------

export const exportXLSXOrders = createAction(
	"[Mns] Export XLSX Orders",
	(payload: number[]): { documentIds: number[] } => ({ documentIds: payload })
);

export const exportXLSXOrdersSuccess = createAction(
	"[Mns] Export XLSX Orders Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportXLSXOrdersError = createAction(
	"[Mns] Export XLSX Orders Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

// -------------------ACTIONS WITH EXPORT XLSX DOCUMENTS-------------------

export const exportRegistryBulkSuccess = createAction(
	"[Ewaybill] Export Registry Bulk Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportRegistryBulkError = createAction(
	"[Ewaybill] Export Registry Bulk Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const validationRegistryBulkSuccess = createAction(
	"[Ewaybill] Validation Registry Bulk Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const validationRegistryBulkError = createAction(
	"[Ewaybill] Validation Registry Bulk Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);



export const exportXLSXDocuments = createAction(
	"[Mns] Export XLSX Documents",
	(payload: { documentType: DocumentType; documentIds: number[]; shortForm?: boolean }): { params: { documentType: DocumentType; documentIds: number[]; shortForm?: boolean } } => ({ params: payload })
);

export const exportXLSXDocumentsSuccess = createAction(
	"[Mns] Export XLSX Documents Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportXLSXDocumentsError = createAction(
	"[Mns] Export XLSX Documents Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

// -------------------ACTIONS WITH CREATE REGISTRY-------------------
export const createRegistry = createAction(
	"[Documents] Create Registry",
	(payload: string): { id: string } => ({ id: payload })
);

export const createRegistrySuccess = createAction(
	"[Documents] Create Registry Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const createRegistryError = createAction(
	"[Documents] Create Registry Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

// -------------------------- ACTIONS WITH STATISTICS XLSX --------------------------
export const getStatisticsOrdersXLSX = createAction(
	"[Statistic] Get Statistics Orders XLSX",
	(payload: StatisticOrderParams): { statisticFilterParams: StatisticOrderParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsTransitXLSX = createAction(
	"[Statistic] Get Statistics Transit XLSX",
	(payload: StatisticTransitParams): { statisticFilterParams: StatisticTransitParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsEwaybillXLSX = createAction(
	"[Statistic] Get Statistics Ewaybill XLSX",
	(payload: StatisticEwaybillParams): { statisticFilterParams: StatisticEwaybillParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsXLSXError = createAction(
	"[Statistic] Get Statistics XLSX Error",
	(payload: Error): { statisticError: Error } => ({ statisticError: payload })
);

export const getStatisticsXLSXSuccess = createAction(
	"[Statistic] Get Statistics XLSX Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

//#region import and mass actions & report actions
export const setImportObject = createAction(
	"[Mns] Set Import Object",
	(importObject: ImportInterface): { importObject: ImportInterface } => ({ importObject })
);

export const resetImportObject = createAction("[Mns] Reset Import Object");


export const changeHintReportStatus = createAction(
	"[Mns] Change Hint Report Status",
	(hintReport: boolean): { hintReport: boolean } => ({ hintReport })
);

export const importGoodsDocument = createAction(
	"[Mns] Import Goods Document",
	(file: File): { file: File } => ({ file })
);

export const importGoodsDocumentError = createAction(
	"[Mns] Import Goods Document Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

//#endregion import and mass actions & report actions
export const signA = createAction(
	"[SCCrypto] Sign A",
	(value: SignAParams, token: number[]): { value: SignAParams; token: number[] } => ({ value, token })
);
export const signASuccess = createAction(
	"[SCCrypto] Sign A Success",
	(value: SignedSCCryptoDto, token: number[]): { value: SignedSCCryptoDto; token: number[] } => ({ value, token })
);
export const signAError = createAction(
	"[SCCrypto] Sign A Error",
	(payload: SignedSCCryptoDto): { error: SignedSCCryptoDto } => ({ error: payload })
);

export const downloadSCCrypto = createAction("[Mns Documents] Download SCCrypto");
export const downloadSCCryptoSuccess = createAction(
	"[Mns Documents] Download SCCrypto Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);
export const downloadSCCryptoError = createAction(
	"[Mns Documents] Download SCCrypto Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const downloadBase64CryptoProxy = createAction("[Mns Documents] Download Base64 SCCrypto");
export const downloadBase64CryptoProxySuccess = createAction(
	"[Mns Documents] Download Base64 SCCrypto Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);
export const downloadBase64CryptoProxyError = createAction(
	"[Mns Documents] Download Base64 SCCrypto Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const getCertificates = createAction(
	"[Mns] Get Certificates"
);

export const getCertificatesSuccess = createAction(
	"[Mns] Get Certificates Success",
	(response: any): { response: any } => ({ response })
);

export const getCertificatesError = createAction(
	"[Mns] Get Certificates Error",
	(errorResponse: HttpErrorResponse): { errorResponse: HttpErrorResponse } => ({ errorResponse })
);
