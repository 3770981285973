import { ChangeDetectionStrategy, Component, forwardRef, Type } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextboxComponent } from "@shared/textbox/textbox.component";

@Component({
	selector: "app-passwordbox",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<PasswordboxComponent> => PasswordboxComponent)
	}],
	template: `<div class="password">
      <input #input type="password" [class.error]="showError"
        [value]="value" (input)="onInput(input.value)" [type]="showPassword ? 'text' : 'password'" placeholder="{{placeholder}}">
				<app-icon *ngIf="!showPassword" (click)="showPassword = !showPassword">visibility</app-icon>
				<app-icon *ngIf="showPassword" (click)="showPassword = !showPassword">visibility_off</app-icon>
		</div>`,
	styleUrls: ["./passwordbox.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordboxComponent extends TextboxComponent {
	public showPassword = false;
}
