import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, of } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-party-list',
	templateUrl: './party-list.component.html',
	styleUrls: ['./party-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartyListComponent implements OnInit, AfterViewInit {
	@Input() public data: [any, string][] = [];
	@Input() public mode: "CREATE" | "VIEW" | "EDIT" | "SELECT" = "SELECT";
	@Input() public dataType: "STORAGES" | "PARTY" = "PARTY";
	@Output() public appInput = new EventEmitter<string>();
	@Output() public appSelect = new EventEmitter<[any, string]>();
	@Output() public appScrolled = new EventEmitter<void>();
	@ViewChild("input", { static: true }) public input?: ElementRef<HTMLInputElement>;
	public list: [any, string][] = [];
	public scroll$: Subject<HTMLElement> = new EventEmitter<HTMLElement>();
	public input$: Subject<string> = new EventEmitter<string>();
	public unsubscribe$$ = new Subject<void>();

	public ngOnInit(): void {
		this.scroll$.pipe(
			switchMap(el => of(el).pipe(delay(100))),
			takeUntil(this.unsubscribe$$)
		).subscribe(() => {
			this.appScrolled.emit();
		});
		this.input$.pipe(
			switchMap(el => of(el).pipe(delay(300))),
			takeUntil(this.unsubscribe$$)
		).subscribe((value) => {
			value = value.toLocaleLowerCase();
			if (value !== "") {
				this.list = this.data.filter(item => item[1].toLocaleLowerCase().includes(value));
			} else {
				this.list = this.data.slice(0);
			}
			this.appInput.emit(value);
		});
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data) {
			this.list = this.data;
		}
	}

	public ngAfterViewInit(): void {
		this.input?.nativeElement.focus();
	}

	public onInput(value: string): void {
		this.input$.next(value);
	}

	public select(item: [any, string]): void {
		if (this.mode === "VIEW") {
			return;
		}
		this.appSelect.emit(item);
	}

	public onScroll(scrollHeight: number, scrollTop: number, height: number): void {
		if (scrollTop !== 0 && (scrollHeight - (height + scrollTop)) <= 60) {
			this.scroll$.next();
		}
	}
}
