<ng-container *ngIf="massImport">
	<div class="empty" [class.error]="showError" *ngIf="!value || !value.length" (click)="openPopup(true)">Выберите файлы</div>
	<div *ngIf="value && value.length" class="field" (click)="openPopup(true)">Выбрано файлов: {{value.length}}</div>
	<div class="popup" *ngIf="isPopupDisplay">
		<div class="header">
			<label>{{title}}</label>
			<app-icon (click)="openPopup(false)">close</app-icon>
		</div>
		<div class="content">
			<input class="input-file" type="file" #fileChoicer [multiple]="massImport" (change)="addFile($event)" />
			<div class="custom-mass-input" (click)="fileChoicer.click()" appDragAndDrop (fileDropped)="fileDropped($event)">
				<app-icon class="icon">cloud_queue</app-icon>
				<span>Перетащите выбранные файлы, которые нужно импортировать</span>
				<div class="button">
					<app-icon>file_upload</app-icon>
					Загрузить
				</div>
				<div class="additional">
					<span>Общий размер файлов: {{ selectedFilesSize | number: "1.0-2" }}/{{ maxFilesSize }} kb</span>
				</div>
			</div>
			<div class="grid-view" *ngIf="files.length">
				<div *ngFor="let file of files" class="checked-item">
					<div class="text" [title]="file.fileName">{{file.fileName}}</div>
					<app-icon class="red" (click)="deleteFile(file); fileSingle.value = null; $event.stopPropagation()">close</app-icon>
				</div>
			</div>
		</div>
		<div class="footer">
			<button class="save" appSkin="action" (click)="save()">Сохранить</button>
			<button class="save" appSkin="simple" (click)="openPopup(false)">Отмена</button>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!massImport">
	<section>
		<input class="input-file" type="file" #fileSingle (change)="addFile($event)" />
		<div class="custom-input" [ngClass]="{'error-single': showError, 'no-error': !showError}" (click)="fileSingle.click()" appDragAndDrop (fileDropped)="fileDropped($event)">
			<div *ngIf="!files.length" (click)="fileSingle.value = null" class="button">Выбрать файл</div>
			<div *ngIf="files.length" class="carryover">
				{{files[0]?.fileName}}
			</div>
			<app-icon *ngIf="files.length" class="red" (click)="deleteFile(); fileSingle.value = null; $event.stopPropagation()">close</app-icon>
		</div>
	</section>
</ng-container>
<app-overlay></app-overlay>

