<tr [class.checked]="checkedItem(branch) && !isIndeterminateCheckBox">
	<td [ngClass]="'td-' + step">
		<div class="name">
			<ng-container *ngIf="branch?.markCodeSet?.length; else noIcon">
				<app-icon *ngIf="!showBranch" (click)="showBranch = !showBranch" class="keyboard">keyboard_arrow_right</app-icon>
				<app-icon *ngIf="showBranch" (click)="showBranch = !showBranch" class="keyboard">keyboard_arrow_down</app-icon>
			</ng-container>
			<ng-template #noIcon>
				<div class="block"></div>
			</ng-template>

			<div *ngIf="step < 2; else dot" (click)="$event.stopPropagation()">
				<app-icon *ngIf="branch?.checkState === true" (click)="selectedItem(false,branch)" class="checkbox">
					check_box</app-icon>
				<app-icon class="checkbox" *ngIf="branch?.checkState === undefined" (click)="selectedItem(true,branch)">
					indeterminate_check_box</app-icon>
				<app-icon *ngIf="branch?.checkState === false" (click)="selectedItem(true,branch)" class="checkbox">
					check_box_outline_blank</app-icon>
			</div>
			<ng-template #dot>
				<app-icon>fiber_manual_record</app-icon>
			</ng-template>

		</div>
	</td>

	<td *ngFor="let header of properties">
		<ng-container [ngSwitch]="header.type">
			<div *ngSwitchCase="'person'">
				{{branch[header.key]?.name}}
			</div>
			<div *ngSwitchCase="'date'">
				{{branch[header.key] | date: "dd.MM.yyyy HH:mm:ss"}}
			</div>
			<div *ngSwitchCase="'dateWithTime'">
				{{branch[header.key] | date: "dd.MM.yyyy HH:mm:ss"}}
			</div>
			<div *ngSwitchCase="'dateWithText'">
				{{branch[header.key] ? (branch[header.key] | date: "dd.MM.yyyy HH:mm:ss"): "Нет ответа"}}
			</div>
			<div *ngSwitchCase="'boolean'">
				<app-icon>{{branch[header.key] ? "done" : "clear"}}</app-icon>
			</div>
			<div *ngSwitchCase="'custom'">
				{{ header['resolveFn'] ? header['resolveFn'](branch[header.key]) : "No resolve Fn" }}
			</div>
			<div *ngSwitchCase="'gtinDetails'">
				{{ branch[header.key][gtin] }}
			</div>
			<div *ngSwitchCase="'shortField'" class="shortField" [title]="branch[header.key]">
				{{ branch[header.key]?.slice(0, 25) }}
			</div>
			<div *ngSwitchCase="'moreHoriz'">
				<app-icon [appDropdown]="actions" [container]="container" class="keyboard">more_horiz</app-icon>
				<ng-template #actions let-ctx>
					<div class="dropdown-wrapper">
						<ul class="dropdown">
							<li (click)="handleEllipsisActions('singleExportCodes',branch); ctx.close()" i18n><app-icon>get_app</app-icon>Экспорт кодов</li>
							<li *ngIf="branch.lineNumber && branch.gtin && branch.orderNumber" (click)="handleEllipsisActions('view',branch); ctx.close()" i18n><app-icon>visibility</app-icon>Просмотреть</li>
						</ul>
					</div>
				</ng-template>
			</div>
			<div *ngSwitchDefault [class.ellipsis]="gtin" [title]="gtin ? branch[header.key] : ''">{{branch[header.key]}}</div>
		</ng-container>
	</td>
</tr>
<tr></tr>
<ng-container *ngFor="let children of branch?.markCodeSet">
	<app-grid-tree *ngIf="showBranch" [branch]="children" [step]="step + 1"
		[selectedItems]="selectedItems" [properties]="properties" [container]="container"
		[gtin]="gtin"
		(isSelect)="setSelected($event)"
		(ellipsisActions)="ellipsisActions.next($event)"></app-grid-tree>
</ng-container>
