import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import * as DocumentsActions  from "../../../user/documents/documents-store/documents.actions";

@Component({
	selector: "app-sccrypto-profile-popup",
	templateUrl: "./sccrypto-profile-popup.component.html",
	styleUrls: ["./sccrypto-profile-popup.component.scss"]
})
export class SccryptoProfilePopupComponent {
	@Input() public actualVersion = "";
	@Input() public isDocument = false;
	public close$: Subject<void> = new Subject<void>();
	@Output() public closePopup: EventEmitter<void> = new EventEmitter<void>();
	constructor(
		private readonly store: Store<any>){

	}
	public close(): void {
		this.isDocument? this.closePopup.emit() : this.close$.next();
	}

	public goLink(link = "version"): void{
		switch(link){
			case "version": this.store.dispatch(DocumentsActions.downloadSCCrypto()); break;
			case "help": break;
		}
	}

}
