import { Injectable, Inject } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { OverlayService } from "./overlay.service";
import { startWith } from "rxjs/operators";
import { TranslationService } from "./translation.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class VersionCheckService {
	private subscription: Subscription;

	constructor(
		@Inject("Window") private readonly window: Window,
		private readonly cookieService: CookieService,
		private readonly overlayService: OverlayService,
		private readonly translationService: TranslationService
	) {
		// use setInterval inside
		this.subscription = interval(60000).pipe(
			startWith(0),
		).subscribe(() => this.checkForUpdate());
	}

	public getVersion(): any {
		return this.cookieService.get("appVersion") || "";
	}

	public setVersion(version: string): void {
		this.cookieService.set("appVersion", version, undefined, "/");
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public checkForUpdate(): void {
		if (this.isCompareWithCurrentVersion()) {
			// this.setVersion(environment.version);
			this.overlayService.showConfirmation$(this.translationService.getTranslation("versionDeprecated"), "", "", true).then(() => {
				if (this.window && this.window.location instanceof Location) {
					this.window.location.reload();
				}
			});
		}
	}

	public isCompareWithCurrentVersion(): boolean {
		// if (!environment.production)
		return false;
		// return this.getVersion() !== environment.version;
	}
}
