import { Observable, throwError, Subscription } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { Injectable, OnDestroy } from "@angular/core";
import { LoginByCertificate } from "@helper/abstraction/authenticate";
import { createSelector, select, Store } from "@ngrx/store";

import * as MnsActions from "../mns-store/mns.actions";
import { MnsState } from "../mns-store/mns.reducer";
import { MnsBackendService } from "./mns-backend.service";
import { setPending } from "@app/app.actions";

const TOKEN_KEY = "token.user.v1";

@Injectable()
export class MnsAuthService implements OnDestroy {
	public isForgetMe = true;
	public token$: Observable<string | undefined>;

	private subscription?: Subscription;

	constructor(
		private readonly backendService: MnsBackendService,
		private readonly store: Store<MnsState>
	) {
		const selectUser = (appState: any): MnsState => appState.mns;
		const selectToken = createSelector(selectUser, (state: MnsState): string | undefined => state?.token);
		this.token$ = this.store.pipe(select(selectToken));
		const token = localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY) || undefined;
		this.store.dispatch(MnsActions.setToken(token));
	}

	public login$(user: string, password: string): Observable<any> {
		return this.backendService.authenticate.govLogin.post$(user, password).pipe(
			tap(res => this.saveTokenToSession(res.uuid)),
			tap(res => this.store.dispatch(MnsActions.setToken(res.uuid))),
			tap(res => this.store.dispatch(MnsActions.setRoles(res.userRoles))),
			tap(res => this.store.dispatch(MnsActions.getUserTypeSuccess(res.userType))),
			catchError(err => {
				this.store.dispatch(MnsActions.setToken(undefined));
				this.saveTokenToSession(undefined);
				return throwError(err);
			})
		);
	}

	public loginAndRemember$(user: string, password: string): Observable<any> {
		this.isForgetMe = false;
		return this.login$(user, password).pipe(
			tap(res => this.pushToken(res.uuid))
		);
	}

	public authToken(publicKeyId: string): Observable<any> {
		return this.backendService.authenticate.authGovToken.get$(publicKeyId);
	}

	public authByCertificate(data: LoginByCertificate): Observable<any> {
		this.store.dispatch(setPending(true));
		return this.backendService.authenticate.authByCertificate.post$(data).pipe(
			tap(() => this.store.dispatch(setPending(false))),
			tap(res => this.saveTokenToSession(res.uuid)),
			tap(res => this.store.dispatch(MnsActions.setToken(res.uuid))),
			tap(res => this.store.dispatch(MnsActions.setRoles(res.userRoles))),
			catchError(err => {
				this.store.dispatch(MnsActions.setToken(undefined));
				return throwError(err);
			})
		);
	}

	public logout(): void {
		this.backendService.authenticate.logout.post$().subscribe(
			() => {
				this.pushToken(undefined);
				this.saveTokenToSession(undefined);
			},
			err => throwError(err));
	}

	public ngOnDestroy(): void {
		if (this.isForgetMe){
			this.logout();
		}
		this.subscription?.unsubscribe();
	}

	private pushToken(token?: string): void {
		if (token)
			localStorage.setItem(TOKEN_KEY, token);
		else
			localStorage.removeItem(TOKEN_KEY);
	}

	private saveTokenToSession(token?: string): void {
		if (token)
			sessionStorage.setItem(TOKEN_KEY, token);
		else
			sessionStorage.removeItem(TOKEN_KEY);
	}
}
