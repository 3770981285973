import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, Type } from "@angular/core";
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, map, take, takeUntil } from "rxjs/operators";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { Shipper } from "@helper/abstraction/statistic";
import { createSelector, select, Store } from "@ngrx/store";

export interface SelectBoxSelfFetchState {
	[key: string]: any;
	page: number;
	size: number;
	search?: MultiSelectFormValue;
}

export interface MultiSelectFormValue {
	name?: string;
	unp?: string;
	address?: string;
}

export interface ListItem {
	checked: boolean;
	dto: {
		id: number;
		name: string;
	};
	text: string;
}

export interface SelfFetchOption<T> {
	getInitialState: () => SelectBoxSelfFetchState;
	nextChunk: (state: SelectBoxSelfFetchState) => SelectBoxSelfFetchState; // if search exist, it must be considered
	getData$?: (state: SelectBoxSelfFetchState) => Observable<T>;
	mapData?: (data: T) => [any, string][];
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-shipper-receiver-multiselect",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef(<T>(): Type<ShipperReceiverMultiselectComponent<T>> => ShipperReceiverMultiselectComponent)
	}],
	templateUrl: "./shipper-receiver-multiselect.component.html",
	styleUrls: ["./shipper-receiver-multiselect.component.scss"]
})
export class ShipperReceiverMultiselectComponent<T> extends BoxValueAccessor implements OnDestroy, OnChanges {
	@Input() public title = "";
	@Input() public placeholder = "";
	@Input() public valueTransformFn?: (value: any) => string;
	@Input("option") public selfFetchOption: SelfFetchOption<T>;
	@Input() public single = false;
	@Input() public mainStoreName = "user";
	public data: [any, string][] = [];
	public popupPending$: Observable<boolean>;
	public isPopupDisplay = false;
	public addresses: ListItem[] = [];
	public tempCheckedList: ListItem[] = [];
	public searchCriterion: FormGroup;
	public showedValue: any[] = [];
	public input$$ = new Subject<MultiSelectFormValue>();
	private initialState: SelectBoxSelfFetchState = { page: 1, size: 30 };
	private state$$: BehaviorSubject<SelectBoxSelfFetchState>;
	private formChanges?: Subscription;
	private stateTracker$$?: Subject<void>;
	private unsubscribe$$: Subject<void> = new Subject<void>();
	private defaultOption: SelfFetchOption<T> = {
		getInitialState: (): SelectBoxSelfFetchState => ({ ...this.initialState }),
		nextChunk: (state): SelectBoxSelfFetchState => ({ ...state, page: state.page + 1 })
	};

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
		if (!this.value) {
			this.value = [];
		}
		this.searchCriterion = this.formBuilder.group({
			name: null,
			unp: null,
			address: null,
		});

		const selectUser = (appState: any): any => appState[this.mainStoreName];
		const getPending = createSelector(selectUser, (state: any) => state?.popupPending);
		this.popupPending$ = this.store.pipe(select(getPending));

		this.selfFetchOption = { ...this.defaultOption };
		this.state$$ = new BehaviorSubject<SelectBoxSelfFetchState>(this.selfFetchOption.getInitialState());
		this.input$$.pipe(
			debounceTime(250),
			takeUntil(this.unsubscribe$$)
		).subscribe((text: MultiSelectFormValue) => this.onInput(text));
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
			// if not pass required function use default
			this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
		}
	}

	public onInput(search: MultiSelectFormValue): void {
		this.resetData();
		this.cleanStateTracker();
		const newState = { ...this.selfFetchOption.getInitialState(), search };
		this.state$$.next(newState);
		this.stateTracker$$ = this.trackState$$();
	}

	public checkItem(el: ListItem): void {
		if (this.single) {
			this.addresses.forEach(element => {
				if (element !== el) {
					element.checked = false;
				}
			});
		}
		el.checked = !el.checked;
		if (!this.single) {
			this.setTempCheckedList(el);
		}
	}

	public ngOnDestroy(): void {
		if (this.formChanges)
			this.formChanges.unsubscribe();
	}

	public get countOfChecked(): number {
		return this.tempCheckedList.length;
	}

	public deleteItem(item: { id: number; name: string }, index: number): void {
		this.value.splice(index, 1);
		this.addresses[index].checked = false;
		if (!this.single) {
			const element = this.addresses.find(el => el.dto.id === item.id);
			if (element) {
				element.checked = false;
				this.setTempCheckedList(element);
			}
		}
		this.showedValue.splice(index, 1);
		if (this.onChange)
			this.onChange(this.value);
		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public openPopup(isOpen: boolean): void {
		this.isPopupDisplay = isOpen;
		if (!this.addresses.length)
			this.input$$.next();
		if (this.isPopupDisplay) {
			this.formChanges = this.searchCriterion.valueChanges.pipe(debounceTime(500)).subscribe((form: MultiSelectFormValue): void => {
				const data = { name: form.name?.trim(), unp: form.unp?.trim(), address: form.address?.trim() };
				this.input$$.next(data);
			});
		} else {
			this.resetFilter();
			this.formChanges && this.formChanges.unsubscribe();
		}
	}

	public resetFilter(clearValue = false): void {
		this.addresses = [];
		if (clearValue) this.tempCheckedList = [];
		this.searchCriterion.reset();
	}


	public writeValue(value: any): void {
		if (!value && this.searchCriterion) {
			this.searchCriterion.reset();
		}
		this.value = value;

		if (this.onChange)
			this.onChange(this.value);
		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public save(): void {
		this.value = [];
		if (this.single) {
			this.addresses.forEach((el: ListItem): void => {
				if (el.checked) {
					this.value.push(el.dto);
					this.showedValue.push(el.text);
				}
			});
		}
		else {
			this.tempCheckedList.forEach((el: ListItem): void => {
				this.value.push(el.dto);
				this.showedValue.push(el.text);
			});
		}
		if (this.onChange)
			this.onChange(this.value);
		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
		this.resetFilter();
		this.openPopup(false);
	}

	public onScroll(scrollHeight: number, scrollTop: number, height: number): void {
		if (scrollTop === 0)
			return;
		if ((scrollHeight - (height + scrollTop)) / scrollHeight <= 0)
			this.state$$.next(this.selfFetchOption.nextChunk(this.state$$.value));
	}

	public transformFn(organization: Shipper): string {
		return organization.name;
	}

	private resetData(): void {
		this.addresses = [];
	}

	private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

	private trackState$$(): Subject<void> {
		const unsubscribe$$ = new Subject<void>();
		this.state$$.pipe(
			takeUntil(this.unsubscribe$$),
			takeUntil(unsubscribe$$)
		).subscribe(state => {
			if (!this.selfFetchOption.getData$)
				throw Error("No getData$ using for enrich date");

			this.selfFetchOption.getData$(state).pipe(
				take(1),
				map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
				takeUntil(this.unsubscribe$$),
				takeUntil(unsubscribe$$)
			).subscribe(data => {
				if (Array.isArray(data) && !data.length) {
					unsubscribe$$.next();
					unsubscribe$$.complete();
				}
				this.appendData(data);
				if (!this.single) {
					this.setChecked();
				}
				this.changeDetectorRef.detectChanges();
			});
		});
		return unsubscribe$$;
	}

	private appendData(newData: [any, string][]): void {
		const parsedData = newData.map(e => ({ dto: e[0], text: e[1], checked: false }));
		this.addresses = [...this.addresses, ...parsedData];
	}

	private setChecked(): void {
		this.addresses.forEach(addr => {
			this.tempCheckedList.forEach(t => {
				if (addr.dto.id === t.dto.id) {
					addr.checked = true;
				}
			});
		});
	}

	private setTempCheckedList(el: ListItem): void {
		if (el.checked) {
			this.tempCheckedList.push(el);
		} else {
			const element = this.tempCheckedList.find(element => element.dto.id === el.dto.id);
			if (element) {
				this.tempCheckedList.splice(this.tempCheckedList.indexOf(element), 1);
			}
		}
	}
}
