import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { DocumentKind, DocumentsDto, DocumentsParams, DocumentType, MassReport, MessageType, DocumentState } from "@helper/abstraction/documents";
import { DraftDto, DraftType } from "@helper/abstraction/draft";
import { MnsBackendService } from "./mns-backend.service";
import { UserPermissionService } from "@app/user/user-core/services/user-permission.service";

export class Documents {
	public list = {
		get$: (dp: DocumentsParams): Observable<DocumentsDto> => {
			return this.userBackendService.getController(dp.documentTypeId).list.get$(dp);
		}
	};

	public statisticTypes = {
		list: {
			get$: (): Observable<any> => {
				const url = `${this.config.api.root}/documents/statistic/types/list`;
				return this.http.get<any[]>(url, { withCredentials: true });
			}
		}
	};

	public processingStatuses = {
		list: {
			get$: (documentTypeId: DocumentType, documentState?: DocumentState): Observable<any> => {
				let url: string;
				if (documentState)
					url = `${this.config.api.root}/documents/${documentTypeId}/${documentState}/processing-statuses/list`;
				else
					url = `${this.config.api.root}/documents/${documentTypeId}/processing-statuses/list`;

				return this.http.get<any[]>(url, { withCredentials: true });
			}
		},

		statisticList: {
			get$: (): Observable<any> => {
				const url = `${this.config.api.root}/documents/statistic/processing-statuses/list`;
				return this.http.get<any[]>(url, { withCredentials: true });
			}
		}
	};


	public createWithValidation = {
		post$: (draftType: DraftType, msgEwaybillDraftDto: DocumentKind): Observable<DraftDto> => {
			const type = this.userPermissionService.getDocumentType(draftType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).createWithValidation.post$(msgEwaybillDraftDto);
		}
	};

	public saveSignedCancel = {
		post$: (messageType: MessageType, id: string, signature: number[], xmlBody: string): Observable<number> => {
			const type = this.userPermissionService.getDocumentType(messageType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).saveSignedCancel.post$(id, signature, xmlBody);
		}
	};

	public massSendDocuments = {
		post$: (documentType: DocumentType, ids: string[]): Observable<MassReport> => {
			if (!documentType)
				throw Error("Can't define type by pass documentType");
			return this.userBackendService.getController(documentType).sendMass.post$(documentType, ids);
		}
	};

	public markAsRead = {
		post$: (idsList: number[], docType: DocumentType): Observable<any> => {
			const url = `${this.config.api.root}/${docType}/markAsRead`;
			return this.http.post<any>(url, idsList, { withCredentials: true });
		}
	};

	public massDeleteDocuments = {
		delete$: (documentType: DocumentType, ids: string[]): Observable<MassReport> => {
			if (!documentType)
				throw Error("Can't define type by pass documentType");
			return this.userBackendService.getController(documentType).deleteMass.delete$(documentType, ids);
		}
	};

	public saveSigned = {
		post$: (draftType: DraftType, documentDto: { id: string; xmlBody: string }): Observable<any> => {
			const type = this.userPermissionService.getDocumentType(draftType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).saveSigned.post$(draftType, documentDto);
		}
	};

	public downloadArchive = {
		post$: (documentIds: number[], documentType: DraftType, reportFormatType: string, isStatisticValue?: boolean, statisticDocType?: string): Observable<HttpResponse<Blob>> => {
			const type = this.userPermissionService.getDocumentType(documentType);
			if (!type)
				throw Error("Can't define type by pass draftType");
			return this.userBackendService.getController(type).downloadArchive.post$(documentIds, reportFormatType, isStatisticValue, statisticDocType);
		}
	};

	constructor(
		private config: ConfigurationService,
		private http: HttpClient,
		private userBackendService: MnsBackendService,
		private userPermissionService: UserPermissionService
	) { }
}
