<p-table
  #dt
  [value]="data"
  dataKey="position"
  styleClass="edit-table"
  [class.maximization-view]="maximizationView"
  (onEditComplete)="editExternalTableComplete($event)"
  [scrollable]="true"
  scrollHeight="flex"
  rowExpandMode="single"
  scrollDirection="both"
  [globalFilterFields]="['fullName','gtin']"
  (onRowExpand)="expand($event, dt)"
>
  <ng-template pTemplate="caption">
    <div class="flex edit-table-global-filter">
      <div class="input-block">
        <label for="global-filter">Поиск по товарному разделу</label>
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input class="p-inputtext-lg" id="global-filter" pInputText type="text" (input)="globalFilterTable()" [(ngModel)]="globalFilter" (input)="dt.filterGlobal($event.target.value, 'contains')"/>
        </span>
      </div>
      <div class="button-block">
        <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text" (click)="dt.reset(); globalFilter = undefined"></button>
      </div>
      <div class="info-block">
        <span>Найдено:</span>
        <span>{{ getTableRawLength() }} {{ pluralize(getTableRawLength(), 'позиция', 'позиции', 'позиций') }}</span>
      </div>
    </div>
  </ng-template>
  <!-- External table -->
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 2rem;"></th>
      <ng-container *ngFor="let header of headerMetadataTable">
        <th [style]="fieldWidth(header.width)">{{ header.headerName }}</th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contentRow let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
    <tr class="edit-table-raw" [class.expanded-raw]="expanded">
      <td class="expand-button" style="width: 2rem">
        <button type="button" pButton pRipple [pRowToggler]="contentRow" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-right'"></button>
      </td>
      <ng-container *ngFor="let field of headerMetadataTable">
        <td *ngIf="field.editableCell"
          [pEditableColumnRowIndex]="index"
          [pEditableColumn]="contentRow"
          [pEditableColumnField]="field.fieldKey"
          [style]="fieldWidth(field.width)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input (keydown)="arrowPressed($event)" pInputText type="text" [(ngModel)]="contentRow[field.fieldKey]">
            </ng-template>
            <ng-template pTemplate="output">
              <span pTooltip="{{ contentRow[field.fieldKey] }}" tooltipPosition="top">{{ contentRow[field.fieldKey] }}</span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td *ngIf="!field.editableCell" [style]="fieldWidth(field.width)" class="discrepancies-btn">
          <ng-container *ngIf="field.fieldKey === 'discrepancies'">
            <button *ngIf="!checkButtonStatus(contentRow) && !isViewMode" pButton pRipple icon="pi pi-plus" type="button" label="Добавить" class="add-discrepancies-btn" [pRowToggler]="contentRow"></button>
            <button *ngIf="checkButtonStatus(contentRow)" pButton pRipple type="button" label="Есть расхождения" class="are-discrepancies-btn" [pRowToggler]="contentRow"></button>
            <button *ngIf="!checkButtonStatus(contentRow) && isViewMode" pButton pRipple type="button" label="Отсутствуют" class="none-discrepancies-btn" [pRowToggler]="contentRow"></button>
          </ng-container>
          <ng-container *ngIf="field.fieldKey !== 'discrepancies'">
            <span class="overflow-ellips"
              [tooltipDisabled]="checkTooltipLength(field.objectFieldName ? contentRow[field.fieldKey][field.objectFieldName] : contentRow[field.fieldKey])"
              pTooltip="{{ checkTooltipText(field.objectFieldName ? contentRow[field.fieldKey][field.objectFieldName] : contentRow[field.fieldKey]) }}" tooltipPosition="bottom">
              {{ field.objectFieldName ? contentRow[field.fieldKey][field.objectFieldName] : contentRow[field.fieldKey] }}
            </span>
          </ng-container>
        </td>
      </ng-container>
      <td *ngIf="!isViewMode" style="width: 5rem;" class="action-buttons">
        <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" [icon]="'pi pi-pencil'" (click)="processRaw('EDIT', contentRow, rowIndex)"></button>
        <button *ngIf="!contentRow.quantityOrdered || contentRow.quantityOrdered === '0'" type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" [icon]="'pi pi-trash'" (click)="processRaw('DELETE', contentRow, rowIndex)"></button>
      </td>
    </tr>
  </ng-template>
  <!-- Total table -->
  <ng-template pTemplate="footer">
    <p-table [value]="totalSums"
      [scrollable]="true"
      scrollHeight="flex"
      styleClass="summary-table"
      scrollDirection="both"
      [class.expand-footer]="!expandedFooter">
      <ng-template pTemplate="header">
        <tr>
          <th class="expand-total-button" style="width: 2rem">
            <button type="button" pButton pRipple
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expandedFooter ? 'pi pi-chevron-up' : 'pi pi-chevron-right'"
              (click)="expandedFooter = !expandedFooter">
            </button>
          </th>
          <th *ngFor="let header of headersTotal" [style]="fieldWidth(header.width)">
            {{ !expandedFooter && header.fieldKey !== "total" ? "" : header.headerName }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-footerData>
        <tr *ngIf="expandedFooter">
          <td [style]="fieldWidth(data[0].width)"></td>
          <td [style]="fieldWidth(footerData.width)">{{ footerData.headerName }}</td>
          <ng-container *ngFor="let key of keys(footerData?.fields)">
            <td [style]="fieldWidth(footerData.fields[key]?.width)">
              {{ footerData.fields[key].value }}
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
  <!-- Internal table -->
  <ng-template pTemplate="rowexpansion" let-contentRow>
    <tr styleClass="edit-raw-cell" class="expanded-cell">
      <td class="edit-raw">
        <div class="p-p-3" [@rowExpansionTrigger]="'active'">
          <p-table *ngIf="updateDependentFields(contentRow)" [value]="contentRow.editCells" dataKey="position"
            [scrollable]="true"
            scrollHeight="flex"
            (onEditComplete)="editInternalTableComplete(contentRow)"
            scrollDirection="both">
            <ng-template pTemplate="body" let-data>
              <tr class="raw-cell" *ngIf="!data.hideRaw">
                <th [style]="fieldWidth(contentRow?.width)"></th>
                <td [style]="fieldWidth(data.width)" [class.required]="data.isRequired && !isViewMode">{{ data.headerName }}</td>
                <ng-container *ngFor="let key of keys(data?.fields)">
                  <td
                    *ngIf="data.fields[key].editable && !isViewMode"
                    [pEditableColumnRowIndex]="index"
                    [pEditableColumn]="data.fields" [pEditableColumnField]="key"
                    [style]="fieldWidth(data.fields[key]?.width)">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <app-number
                          [value]="contentRow[key]"
                          (valueChange)="editInputComplete($event, contentRow, key)"
                          [type]="'float'"
                          [wholeMaxLength]="data.fields[key].wholeMaxLength"
                          [decimalMaxLength]="data.fields[key].decimalMaxLength"
                          [maxLength]="data.fields[key].maxLength">
                        </app-number>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="fixed-cell" [tooltipDisabled]="checkTooltipLength(contentRow[key])"
                          pTooltip="{{ checkTooltipText(contentRow[key]) }}" tooltipPosition="bottom">{{ contentRow[key] || "-" }}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td *ngIf="(!data.fields[key].editable || isViewMode) && !data.fields[key].handleMarkCodes" [style]="fieldWidth(data.fields[key]?.width)"
                    [tooltipDisabled]="checkTooltipLength(data.fields[key].objectFieldName ? contentRow[key][data.fields[key].objectFieldName] : contentRow[key])"
                    pTooltip="{{ checkTooltipText(data.fields[key].objectFieldName ? contentRow[key][data.fields[key].objectFieldName] : contentRow[key]) }}" tooltipPosition="bottom">
                    <span class="overflow-ellips">
                      {{ (data.fields[key].objectFieldName ? contentRow[key][data.fields[key].objectFieldName] : contentRow[key]) || handleEmptyField(key) }}
                    </span>
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <div *ngIf="(!isViewMode && contentRow?.infoData) || (isViewMode && (contentRow?.infoData || contentRow?.markCodeDtoList?.length))" class="expand-footer">
                <div class="footer-block">
                  <h3>Дополнительная информация</h3>
                  <div class="mark-codes" *ngIf="isViewMode && contentRow?.markCodeDtoList?.length">
                    <button pButton pRipple type="button" label="Код маркировки" class="p-button-outlined" (click)="openMarkCodePopup(contentRow)"></button>
                  </div>
                  <div class="note">
                    <span>{{ contentRow.infoData }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
