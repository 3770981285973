<form *ngIf="form" [formGroup]="form">
	<header>
		<h2>Для доступа к контейнеру личного ключа введите пароль</h2>
	</header>
	<div class="main">
		<div>
			<b>Логин:</b>
			<app-textbox formControlName="login" isDisabled=true></app-textbox>
		</div>
		<div>
			<b>Пароль:</b>
			<app-passwordbox formControlName="password" name="Пароль" [noTrimValue]="true"></app-passwordbox>
		</div>
	</div>
	<footer>
		<button class="save" appSkin="action-save bold" (click)="confirm(form)">ОК</button>
		<button class="save" appSkin="simple no-shadow bold bordered" (click)="close()">Закрыть</button>
	</footer>
</form>
<app-overlay #notification></app-overlay>