import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { createSelector, select, Store } from "@ngrx/store";
import { DocumentType } from "@helper/abstraction/documents";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { ValidatorsUtil } from "@helper/validators-util";
import { exportXLSXDocuments } from "@app/user/user.actions";
import { environment } from "../../../../environments/environment";
import { UserState } from "@app/user/user.reducer";
import { Role } from "@helper/abstraction/roles";
import { notNull } from "@helper/operators";

const OutputTypeValues = [
	['PDF', 'PDF'],
	['XLSX', 'XLSX'],
];

@Component({
	selector: "app-printing-popup",
	templateUrl: "./printing-popup.component.html",
	styleUrls: ["./printing-popup.component.scss"]
})
export class PrintingPopupComponent extends DefaultPopupComponent {
	@Input() public documentType?: DocumentType;
	@Input() public ids?: number[];
	@Input() public formType?: number;
	@Input() public isMassAction = false;

	public form: FormGroup;
	public accessToPrintEds?: boolean;
	public env = environment;
	public outputTypeValues = OutputTypeValues;
	private printSignRole?: boolean;
	private allowedDocumentTypes: DocumentType[] = ["EWAYBILL", "EINVOICE"];
	private unsubscribe$$ = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private store: Store
	) {
		super();
		this.form = this.formBuilder.group({
			outputType: [null, Validators.required],
			shortForm: false,
			printEds: false
		});

		const selectUser = (appState: any): UserState => appState.user;
		const selectUserRoles = createSelector(selectUser, (state: UserState): Role[] | undefined => state.roles);
		this.store.pipe(
			select(selectUserRoles),
			notNull(),
			takeUntil(this.unsubscribe$$),
		).subscribe(roles => this.printSignRole = roles.some(role => role.id === "PRINT_SIGN"));
	}

	public ngOnInit(): void {
		const format = localStorage.getItem("format");
		this.accessToPrintEds = (this.env.project === "CTT" ||
			(this.env.project === "BM" && this.printSignRole)) &&
			(this.documentType && this.allowedDocumentTypes.includes(this.documentType));
		const printEds = JSON.parse(localStorage.getItem("printEds")!);
		this.form.patchValue({
			outputType: format || "XLSX",
			printEds: printEds === null ? true : printEds
		});
	}

	public printData(): void {
		if (this.form.invalid) {
			ValidatorsUtil.triggerValidation(this.form);
			return;
		}
		if (!this.ids) {
			throw "No documents' IDs to print the information";
		}
		if (!this.documentType) {
			throw "No documents' type to print the information";
		}
		localStorage.setItem("format", this.form.value.outputType);
		localStorage.setItem("printEds", this.form.value.printEds);
		const action = exportXLSXDocuments({
			documentType: this.documentType,
			documentIds: this.ids,
			shortForm: !this.form.value.shortForm,
			outputType: this.form.value.outputType,
			printEds: this.accessToPrintEds ? this.form.value.printEds : false,
		}, this.isMassAction);
		this.store.dispatch(action);
		this.close();
	}

	public toggleControlValue(controlName: string): void {
		this.form.get(controlName)?.setValue(!this.form.value[controlName]);
	}
}
