import { formatDate } from "@angular/common";
import { Big } from "big.js";

export class TextUtil {
	public static toTwoSymbol(digit: number): string {
		return digit < 10 ? "0" + digit : digit.toString();
	}

	public static toFourSymbol(digit: number): string {
		return digit < 10 ? "000" + digit : digit < 100 ? "00" + digit : digit < 1000 ? "0" + digit : digit.toString();
	}

	public static numberToTwoFixed(number: number): string {
		return new Big(number).toFixed(2);
	}

	// TODO update handler date
	public static handleFormatDate(date: Date, isValidate = false): string | Date {
		const newDate = formatDate(date, isValidate ? 'yyyy-MM-dd' : 'dd.MM.yyyy', 'ru-BY');
		return isValidate ? new Date(newDate) : newDate;
	}
}
