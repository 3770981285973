<section [ngClass]="urlScMobileForm? 'iframeSection': null">
	<header>
		<label i18n>Привязать ЭЦП-М</label>
		<app-icon (click)="close()">close</app-icon>
	</header>
	<form *ngIf="!urlScMobileForm " [formGroup]="form">
		<div>
			<label>Мобильный телефон</label>
			<app-phone-textbox formControlName="phoneNumber" placeholder="YY YYY-YY-YY" name="Мобильный телефон" class="phoneNumber"></app-phone-textbox>
		</div>
	</form>
	<iframe *ngIf="urlScMobileForm" [src]="url"></iframe>
	<footer>
		<button *ngIf="!urlScMobileForm" appSkin="action" class="green" (click)="confirmCertificate()">Привязать</button>
		<button appSkin="simple-cancel" (click)="close(urlScMobileForm ? 'close' : undefined)">{{urlScMobileForm ? "Закрыть" :"Отмена"}}</button>
	</footer>
</section>
<app-overlay></app-overlay>
