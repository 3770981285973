<app-overlay></app-overlay>
<div class="grid-container" #container (scroll)="onScroll(container)">
	<table>
		<thead>
			<tr [ngClass]="withSelectedItemsBlock ? 'tall-header': ''">
				<th *ngIf="!withoutCheckBox">
					<div class="block" (click)="$event.stopPropagation()">
						<app-icon class="checkbox" *ngIf="allData?.length && selectedItems?.length === allData?.length && !isIndeterminateCheckBox" (click)="selectedItems = []; selectAll(false)">
							check_box</app-icon>
						<app-icon class="checkbox" *ngIf="selectedItems.length && selectedItems.length !== allData?.length && !isIndeterminateCheckBox" (click)="selectedItems = []; selectAll(true)">
							indeterminate_check_box</app-icon>
						<app-icon class="checkbox" *ngIf="!selectedItems.length || isIndeterminateCheckBox" (click)="selectAll(true)">
							check_box_outline_blank</app-icon>
					</div>
				</th>
				<th *ngFor="let header of properties">{{header.name}}</th>
				<th></th>
				<div class="selected-items-block" *ngIf="withSelectedItemsBlock && selectedItems?.length > 0">
					<div class="selected-items-wrapper">
						<p>Выбрано: {{ selectedItems.length }} {{ pluralize(selectedItems.length, 'код', 'кода', 'кодов') }}</p>
						<app-button icon="get_app" (click)="selectionListActions('exportCodes')">Экспорт кодов</app-button>
						<app-button icon="bookmark" *ngIf="isAllElementsInStatusIssued" (click)="selectionListActions('mark')">Маркировать</app-button>
						<app-button icon="archive" *ngIf="aggregationButtonView" (click)="selectionListActions('aggregate')">Агрегировать</app-button>
						<app-button icon="unarchive" *ngIf="disaggregationButtonView" (click)="selectionListActions('disAggregate')">Дезагрегировать</app-button>
						<app-button icon="lock" *ngIf="writeOfButtonView" (click)="selectionListActions('writeOff')">Списать</app-button>
					</div>
				</div>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let branchCodes of treeData">
				<app-grid-tree [branch]="branchCodes" [selectedItems]="selectedItems" [container]="container"
					[childrenFieldName]="childrenFieldName" [withoutCheckBox]="withoutCheckBox" [properties]="properties"
					[gtin]="gtin"
					(isSelect)="selectItem($event)" (ellipsisActions)="viewCodes($event)"></app-grid-tree>
			</ng-container>
		</tbody>
	</table>
</div>
<app-pending *ngIf="fromEwaybill? (popupPending$ | async) : (pending$ | async)" [isBlackTheme]="true"></app-pending>
<footer *ngIf="!withoutFooter && treeData && treeData.length">
	<span>Выделено кодов: {{ selectedItems ? selectedItems?.length: 0 }} из {{ treeData?.length }} (Всего записей: {{ totalElements }})</span>
	
	<ng-container *ngTemplateOutlet="footer, context: { $implicit: { list: list, selectedItems: selectedItems, totalElements: totalElements } }"></ng-container>
	<app-pagination
		*ngIf="(filter && totalElements) && totalElements > filter.size" [totalElements]="totalElements" [page]="filter.page" [filter]="filter"
		(selectedPage$)="setSelectedPage($event)">
	</app-pagination>
</footer>

<template #texts>
	<dl>
		<dt>aggregationConfirmText</dt>
		<dd i18n>Не все выбранные элементы находятся на верхнем уровне иерархии(элементы без родителя). </dd>
		<dt>disaggregationConfirmText</dt>
		<dd i18n>Не все выбраные элементы находятся на втором уровне иерархии. </dd>
		<dt>messageSecondPart</dt>
		<dd i18n>Применить действие только для соответствующего уровня?</dd>
	</dl>
</template>
