<section [ngSwitch]="type" [ngClass]="[style === 'show' ? 'show-ntf': 'hide-ntf', type]" >
	<ng-container *ngIf="style === 'show'">
		<div class="wrapper">
			<app-icon *ngSwitchCase="'success'">check_circle</app-icon>
			<app-icon *ngSwitchCase="'error'">report_problem</app-icon>
					<label>{{message}}</label>
		</div>

		<app-icon (click)="close$.next()">close</app-icon>
	</ng-container>
</section>
