<section>
	<ng-container *ngIf="progressUrl && showIFrame; else skeleton">
		<iframe [src]="url"></iframe>
	</ng-container>
	<ng-template #skeleton>
		<div class="skeleton-block">
			<p-skeleton width="950px" height="350px" borderRadius="6px"></p-skeleton>
		</div>
	</ng-template>
	<main *ngIf="showMessage">
		<h1>{{ showMessage }}</h1>
	</main>
	<footer>
		<button appSkin="simple-cancel" id="closeButton" (click)="close()">Закрыть</button>
	</footer>
</section>
