import { NgModule } from "@angular/core";

import { ConfigurationService } from "./configuration.service";
import { OverlayService } from "./overlay.service";
import { BackendService } from "./backend.service";
import { VersionCheckService } from "./version-check.service";
import { HttpClientModule } from "@angular/common/http";
import { TranslationService } from "./translation.service";
import { ConstantsComponent } from "./constants.component";
import { ErrorHandlerService } from "@core/error-handler.service";
import { DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";

export function windowFactory(): Window {
	return window;
}
@NgModule({
    declarations: [
        ConstantsComponent
    ],
    imports: [
        HttpClientModule
    ],
    providers: [
        ConfigurationService,
        OverlayService,
        BackendService,
        VersionCheckService,
        TranslationService,
        ErrorHandlerService,
        DialogService,
        DynamicDialogConfig,
        { provide: "Window", useValue: windowFactory() },
        { provide: "TranslateComponent", useValue: ConstantsComponent },
    ]
})
export class CoreModule { }
