import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, throttleTime } from "rxjs/operators";
import { createSelector, select, Store } from "@ngrx/store";
import { DocumentProperty, DocumentType } from "@helper/abstraction/documents";
import { UserState } from "@app/user/user.reducer";
import { TemplateUtil } from "@helper/template-util";
import { Organization } from "@helper/abstraction/organization";
import { StoreService } from "./store.service";
import { environment } from "src/environments/environment";
import { formatCurrency } from "@angular/common";

@Component({
	selector: "app-automatic-grid",
	templateUrl: "./automatic-grid.component.html",
	styleUrls: ["./automatic-grid.component.scss"]
})
export class AutomaticGridComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() public storeName = ""; // the name of the storage
	@Input() public fieldName = ""; // storage object field for the list
	@Input() public getAction: any; // actions to get a list of data
	@Input() public successAction: any; // action to display a list of data
	@Input() public errorAction: any; // action to display errors
	@Input() public resetAction: any; // actions to reset the data list
	@Input() public headers: DocumentProperty[] = []; // the table headers
	@Input() public filter: any; // filter from the parent component
	@Input() public withoutCheckBoxes = false;
	@Input() public withoutCheckBoxesAndIcons = false;
	@Input() public highlightItem?: any | string;
	@Input() public highlightId?: number | string;
	@Input() public selectedItems?: any[] = [];
	@Input() public withHeadersChildren = false;
	@Input() public dataMapping?: (list: any[]) => any[];
	@Input() public withSelectedItemsBlock = false;
	@Input() public reload = false;
	@Input() public isDateWithTmeFirst = false;
	@Input() public options?: TemplateRef<any>;
	@Input() public selectedItemsBlock?: TemplateRef<any>;
	@Input() public footer?: TemplateRef<any>;
	@Input() public documentType?: DocumentType;

	@Output() public tableScrolling = new EventEmitter<any>();
	@Output() public rowClick = new EventEmitter<any>();
	@Output() public selectItems: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() public appDownloadId: EventEmitter<{ key: string; id: number }> = new EventEmitter<{ key: string; id: number }>();

	public list: any[] = [];
	public env = environment;
	public pending$?: Observable<boolean | undefined>;
	public totalElements?: number;
	public currentCompany?: Organization;

	@ViewChild("statusList", { static: true }) private statusList?: ElementRef<HTMLTemplateElement>;
	@ViewChild("processMap", { static: true }) private processMap?: ElementRef<HTMLTemplateElement>;
	@ViewChild("transferMap", { static: true }) private transferMap?: ElementRef<HTMLTemplateElement>;
	@ViewChild("typeList", { static: true }) private typeList?: ElementRef<HTMLTemplateElement>;
	private messages: Map<string, string> = new Map<string, string>();
	private transferMessages: Map<string, string> = new Map<string, string>();
	private processMessages?: Map<string, string>;
	private scroll$: Subject<HTMLElement> = new EventEmitter<HTMLElement>();
	private stopScrolling = false;
	private unsubscribe$$ = new Subject<void>();
	private throttleTime = 300;
	private totalPages?: number;
	private listPage: any[][] = [];

	@ViewChild("container") public containerRef?: ElementRef;

	constructor(
		private store: Store,
		private changeDetector: ChangeDetectorRef,
		private storeService: StoreService
	) {
		const user = (appState: any): UserState => appState.user;
		const userTypeState = createSelector(user, (state: UserState): boolean | undefined => state.tablePending);
		this.pending$ = this.store.pipe(select(userTypeState), takeUntil(this.unsubscribe$$));
	}

	public ngOnInit(): void {
		if (this.env.project === "ME" && this.storeName === "statistics" && this.documentType === "EWAYBILL") {
			if (this.headers.some(el => el.key === "traceableItems")) {
				this.headers.splice(this.headers.length - 1);
			}
		}
		this.storeService.storageName = this.storeName;
		this.storeService.select$(store => store[this.fieldName])
			.pipe(
				map((data: any) => {
					if (data?.hasOwnProperty("totalElements") && data?.hasOwnProperty("totalPages")) {
						this.totalElements = data?.totalElements;
						this.totalPages = data?.totalPages;
					}
					return data && this.dataMapping ? this.dataMapping(data) : data;
				}),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe((value: any) => {
				if (value?.length) {
					this.list = this.filter?.page !== 1 ? this.list.concat(value) : value;
					this.listPage.push(value);
					this.stopScrolling = !(value.length === this.filter.size);
					this.changeDetector.detectChanges();
					return;
				}
				this.stopScrolling = true;
			});

		this.setScrollSubscription();
		const userStore = (appState: any): UserState => appState.user;
		const organizationInfo = createSelector(userStore, (state: UserState): Organization | undefined => state.organizationInfo);
		this.store.pipe(select(organizationInfo), takeUntil(this.unsubscribe$$)).subscribe(value => this.currentCompany = value);		
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ("filter" in changes) {
			this.list = [];
			this.listPage = [];
			this.getListData();
		}
		if (changes?.documentType?.currentValue !== changes?.documentType?.previousValue) {
			this.containerRef?.nativeElement?.scrollTo(0, 0);
		}
	}

	public ngAfterViewInit(): void {
		if (!this.statusList) { throw Error("No texts"); }
		this.messages = TemplateUtil.getMap(this.statusList.nativeElement);

		if (!this.processMap) { throw Error("No messagesErrors"); }
		this.processMessages = TemplateUtil.getMap(this.processMap.nativeElement);

		if (!this.transferMap) { throw Error("No texts"); }
		this.transferMessages = TemplateUtil.getMap(this.transferMap.nativeElement);
	}

	public onScroll(container: any): void {
		if (container.scrollTop === 0 || this.stopScrolling) {
			return;
		}
		if (this.totalPages === this.filter.page) {
			return;
		}
		if ((container.scrollHeight - (container.clientHeight + container.scrollTop)) <= 50) {
			this.scroll$.next(container);
		}
	}

	public clickRowAction(item: any, isFieldClecked = false): void {
		if (["THIRDPARTYACCESS"].includes(this.documentType!) && !isFieldClecked) {
			return;
		}
		const page = this.listPage.findIndex(itemArr => itemArr.some(elem => elem.id === item.id)) + 1;
		this.storeService.page = this.listPage.length > 1 ? page : this.filter.page;
		this.rowClick.emit(item);
	}

	public clickFieldAction(item: any): void {
		this.clickRowAction(item, true);
	}

	public isEqual(item: any): boolean {
		return this.highlightItem
			? JSON.stringify(this.highlightItem) === JSON.stringify(item)
			: this.highlightId
				? this.highlightId === item.id
				: false;
	}

	public checkedItem(item: any): boolean {
		if (this.selectedItems) {
			return this.selectedItems.some(selectItem => JSON.stringify(selectItem) === JSON.stringify(item));
		}
		return false;
	}

	public selectAllItems(documents: any[]): void {
		if (!documents.length) return;
		this.selectedItems = documents;
		this.selectItems.emit(documents);
	}

	public clearSelectedItems(): void {
		this.selectedItems = [];
		this.selectItems.emit([]);
	}

	public selectedItem(doc: any): void {
		if (typeof this.selectedItems === "undefined") {
			this.selectedItems = [];
		}

		if (!this.selectedItems.find(selectedItems => JSON.stringify(selectedItems) === JSON.stringify(doc))) {
			this.selectedItems = Object.assign([], this.selectedItems);
			this.selectedItems.push(doc);
			this.selectItems.emit(this.selectedItems);
			return;
		}

		if (this.selectedItems.length) {
			const newSelectedDocuments = this.selectedItems.filter((selectedDocument): boolean => JSON.stringify(selectedDocument) !== JSON.stringify(doc));
			this.selectedItems = newSelectedDocuments;
			this.selectItems.emit(newSelectedDocuments);
			return;
		}

		this.selectedItems = [];
		this.selectItems.emit([]);
	}

	public openUrl(key: string, url?: string, id?: number): void {
		if (url) {
			const urlId = +url.split("=")[1];
			this.appDownloadId.emit({ key, id: urlId });
		}
		if (id) {
			this.appDownloadId.emit({ key, id });
		}
	}

	public addressAndGln(document: any): string {
		return document.addressFull + " " + document.gln;
	}

	public getStatuses(status: string): string {
		return this.messages.get(status) || "";
	}

	public getTransferStatuses(status: string): string {
		return this.transferMessages.get(status) || "";
	}

	public ngOnDestroy(): void {
		this.changeDetector.detach();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public setSelectedPage(selectedPage: number): void {
		this.filter.page = selectedPage;
		this.list = [];
		this.listPage = [];
		this.selectedItems = [];
		this.selectItems.emit([]);
		this.getListData();
	}

	public isSender(item: any): boolean {
		const isOrderResponse = item.messageType === "ORDRSP" && item.senderId !== item.receiverId;
		/** TODO change (this.documentType === 'ROSEU' ? item.sender.id : item.senderId) in next version */
		const isSender = (this.documentType === 'ROSEU' ? item.sender.id : item.senderId) === this.currentCompany?.id;
		return isOrderResponse ? !isSender : isSender;
	}			

	public isReceiver(item: any): boolean {
		return item.senderId !== this.currentCompany?.id;
	}

	public getTitleText(item: any): string {
		if (item.deliveryStatus === 7) {
			return "Связанное сообщение отправляется";
		}
		return this.isReceiver(item) 
			? item.read 
				? "Входящий" 
				: "Входящий (обновлен)" 
			: this.isSender(item) && !item.read && item.readByPartner
				? "Исходящий (обновлен)"
				: "Исходящий";
	}

	public getValueFormatedDecimals(value: string | number): string {
		const currency = formatCurrency(Number(value), 'ru-BY', 'BY', '', '1.2-2').replace("BY", "").replace(",", ".");
		return currency;
	  }

	private getListData(): void {
		this.store.dispatch(this.resetAction());
		this.store.dispatch(this.getAction(this.filter));
		this.tableScrolling.emit(this.filter);
	}

	private setScrollSubscription(): void {
		this.scroll$
			.pipe(
				throttleTime(this.throttleTime),
				takeUntil(this.unsubscribe$$)
			).subscribe(() => {
				this.stopScrolling = true;
				this.filter.page = this.filter.page + 1;
				this.store.dispatch(this.getAction(this.filter));
				this.tableScrolling.emit(this.filter);
			});
	}
}
