import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserBackendService } from '@app/user/user-core/services/user-backend.service';
import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';

@Component({
	animations: [
		trigger("slideHost", [
			transition("* <=> *", [
				query("@slideNotic", animateChild()),
			]),
		]),
		trigger("slideNotic", [
			transition(":enter", [
				style({ transform: "translate3d(100%, 0, 0)" }),
				animate("500ms cubic-bezier(0.17, 0.67, 0.32, 1.11)", style({ transform: "translate3d(0, 0, 0)" }))
			]),
			transition(":leave", [
				style({ transform: "translate3d(0, 0, 0)" }),
				animate("250ms ease-in", style({ transform: "translate3d(100%, 0, 0)" }))
			])
		])
	],
	host: {
		"[@slideHost]": ""
	},
  selector: 'app-user-notify-popup',
  templateUrl: './user-notify-popup.component.html',
  styleUrls: ['./user-notify-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNotifyPopupComponent implements OnInit, OnDestroy {
  @Input() public data?: any;
  public url?: SafeResourceUrl;
  public close$: Subject<any> = new Subject<any>();
	public form?: FormGroup;
  private env = environment;

	private unsubscribe$$: Subject<void> = new Subject<void>();

  constructor(
		private sanitizer: DomSanitizer,
		private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private userBackendService: UserBackendService,
  ) {
		this.form = this.fb.group({
			checkControl: [false],
		});
  }

  public ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.content?.message! || this.data?.message);
    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
  }

  public close(): void {
    if (this.data?.content?.closeAction || this.data?.closeAction) {
      this.userBackendService.notificationController.action.get$(this.data.id, this.data?.content?.closeAction || this.data?.closeAction)
        .pipe(takeUntil(this.unsubscribe$$))
        .subscribe(
          () => this.close$.next(),
          err => this.close$.next(err.error.error ?? "")
        );
    } else {
      this.close$.next();
    }
  }

  public getSkinButton(): string {
    let skin = "simple-cancel";
    switch (this.env.project) {
      case "CTT":
        skin = "action";
        break;
      default:
        break;
    }
    return skin + " no-shadow bold bordered";
  }
}
