<section>
	<header>
		<label i18n>{{ mode === "create" ? "Создание пароля" : "Изменение пароля" }}</label>
		<app-icon (click)="close()">close</app-icon>
	</header>
	<form [formGroup]="form">
		<div>
			<label>
				<span>Логин</span>
			</label>
			<div class="control-box">
				<app-textbox formControlName="login" isDisabled="true"></app-textbox>
			</div>
		</div>
		<div>
			<label class="required">
				<span>Пароль</span>
			</label>
			<div class="control-box">
				<app-passwordbox [class.error]="form?.get('psw')?.errors" formControlName="psw">
				</app-passwordbox>
				<ng-container *ngIf="form?.get('psw') as psw">
					<span class="error" *ngIf="psw.hasError('required')">
						{{ messages?.get('required') || "Unknown message" }}
					</span>
					<span *ngIf="psw.hasError('minlength'); else maxlength" class="error">
								{{ messages.get('minlength') || "Unknown message" }}
					</span>
					<ng-template #maxlength>
						<span class="error" *ngIf="psw.hasError('maxlength'); else specialCharacters">
							{{ messages?.get('maxlength') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #specialCharacters>
						<span class="error" *ngIf="psw.hasError('onlyLatinOrSpecialCharactersAllowed'); else noOneUppercaseLatinCharacters">
							{{ messages?.get('specialCharacters') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #noOneUppercaseLatinCharacters>
						<span class="error" *ngIf="psw.hasError('noOneUppercaseLatinCharacters'); else noOneLowercaseLatinCharacters">
							{{ messages?.get('noOneUppercaseLatinCharacters') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #noOneLowercaseLatinCharacters>
						<span class="error" *ngIf="psw.hasError('noOneLowercaseLatinCharacters'); else noOneNumberCharacters">
							{{ messages?.get('noOneLowercaseLatinCharacters') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #noOneNumberCharacters>
						<span class="error" *ngIf="psw.hasError('noOneNumberCharacters')">
							{{ messages?.get('noOneNumberCharacters') || "Unknown message" }}
						</span>
					</ng-template>
				</ng-container>
			</div>
		</div>
		<div>
			<label class="required">
				<span>Подтвердите пароль</span>
			</label>
			<div class="control-box">
				<app-passwordbox
					formControlName="pswConfirm"
					[class.error]="form?.get('pswConfirm')?.errors || form?.hasError('doNotMatch')">
				</app-passwordbox>
				<ng-container *ngIf="form?.get('pswConfirm') as pswConfirm">
					<span class="error" *ngIf="pswConfirm.hasError('required')">
						{{ messages?.get('required') || "Unknown message" }}
					</span>
					<span class="error" *ngIf="form?.hasError('doNotMatch')">
						{{ messages?.get('doNotMatch') || "Unknown message" }}
					</span>
					<span *ngIf="pswConfirm.hasError('minlength'); else maxlength" class="error">
						{{ messages.get('minlength') || "Unknown message" }}
					</span>
					<ng-template #maxlength>
						<span class="error" *ngIf="pswConfirm.hasError('maxlength'); else specialCharacters">
							{{ messages?.get('maxlength') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #specialCharacters>
						<span class="error" *ngIf="pswConfirm.hasError('onlyLatinOrSpecialCharactersAllowed'); else noOneUppercaseLatinCharacters">
							{{ messages?.get('specialCharacters') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #noOneUppercaseLatinCharacters>
						<span class="error" *ngIf="pswConfirm.hasError('noOneUppercaseLatinCharacters'); else noOneLowercaseLatinCharacters">
							{{ messages?.get('noOneUppercaseLatinCharacters') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #noOneLowercaseLatinCharacters>
						<span class="error" *ngIf="pswConfirm.hasError('noOneLowercaseLatinCharacters'); else noOneNumberCharacters">
							{{ messages?.get('noOneLowercaseLatinCharacters') || "Unknown message" }}
						</span>
					</ng-template>
					<ng-template #noOneNumberCharacters>
						<span class="error" *ngIf="pswConfirm.hasError('noOneNumberCharacters')">
							{{ messages?.get('noOneNumberCharacters') || "Unknown message" }}
						</span>
					</ng-template>
				</ng-container>
			</div>
		</div>
	</form>
	<footer>
		<button appSkin="action-save" (click)="save()">{{ mode === "create" ? "Добавить" : "Изменить" }}</button>
		<button appSkin="simple-cancel" (click)="close()">Закрыть</button>
		<div class="help">
		  <app-icon
			[appTooltip]="helpMessage"
					[appTooltipStyle]="true"
					[position]="'top'"
			[isDisable]="false">
			help_outline
		  </app-icon>
		</div>
	</footer>
</section>

<ng-template #helpMessage>
	<div class="tooltip-message" i18n>
		  Пароль может содержать только латинские буквы в верхнем
		  и нижнем регистрах, цифры и спец.символы [!@#$%^&*()].
		  <ul class="mainLi">Пароль должен содержать:
			  <li>
				  не менее 8 символов и не более 32 символов
			  </li>
			  <li>
				  не менее одной латинской буквы в верхнем регистре
			  </li>
			  <li>
				  не менее одной латинской буквы в нижнем регистре
			  </li>
			  <li>
				  не менее одной цифры
			  </li>
		  </ul>
		  <ul class="mainLi">Примеры:
			  <li>Password1</li>
			  <li>Password!1</li>
			  <li>pASSWORD1</li>
			  <li>Pa123456</li>
		  </ul>
	</div>
</ng-template>

<template #messagesErrors>
	<dl>
		<dt>specialCharacters</dt>
		<dd i18n>Пароль может содержать латинские буквы в верхнем и нижнем регистрах, цифры и спец.символы
			[!@#$%^&*()].</dd>
		<dt>minlength</dt>
		<dd i18n>Пароль должен содержать не менее 8 символов.</dd>
		<dt>maxlength</dt>
		<dd i18n>Пароль должен содержать не более 32 символов.</dd>
		<dt>noOneNumberCharacters</dt>
		<dd i18n>Пароль должен содержать не менее одной цифры.</dd>
		<dt>noOneLowercaseLatinCharacters</dt>
		<dd i18n>Пароль должен содержать не менее одной латинской буквы в нижнем регистре.</dd>
		<dt>noOneUppercaseLatinCharacters</dt>
		<dd i18n>Пароль должен содержать не менее одной латинской буквы в верхнем регистре.</dd>
		<dt>required</dt>
		<dd i18n>Это обязательное поле.</dd>
		<dt>doNotMatch</dt>
		<dd i18n>Новый пароль и его подтверждение отличаются.</dd>
	</dl>
</template>
