import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { createSelector, select, Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { authorizeCryptoMobile, attachCryptoMobile, authorizeCryptoMobileSuccess, attachCryptoMobileSuccess } from "@app/user/customization/customization-store/sccrypto.actions";
import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { UserState } from "@app/user/user.reducer";
import { ValidatorsUtil } from "@helper/validators-util";
import { notNull } from "@helper/operators";

@Component({
	selector: "app-bind-mobile-popup",
	templateUrl: "./bind-mobile-popup.component.html",
	styleUrls: ["./bind-mobile-popup.component.scss"]
})
export class BindMobilePopupComponent implements OnInit {
	public isAuthorizeMobileEds = false;
	public form: FormGroup;
	public urlScMobileForm: any;
	public phoneNumber$: Observable<string | undefined>;
	public isFirst = true;

	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private sanitizer: DomSanitizer,
		private actions: Actions,
		private formBuilder: FormBuilder,
		private store: Store<UserState>,
		private userErrorsService: UserErrorsService,
		private userBackendService: UserBackendService,
		private changeDetector: ChangeDetectorRef
	) {
		const selectUser = (appState: any): UserState | any => appState.user;
		const getPhoneNumber = createSelector(selectUser, (state: UserState): string | undefined => state.userProfile?.mobileEds?.phoneNumber);
		this.phoneNumber$ = this.store.pipe(select(getPhoneNumber));

		this.form = this.formBuilder.group({ phoneNumber: [null, [Validators.required, ValidatorsUtil.checkLength(9)]] });
		window.addEventListener("message", this.listener);
	}

	public listener = (event: any): any => {
		if (event.data?.hasOwnProperty("method") && this.isFirst) {
			this.openObservable(event.data);
		}
	};

	public ngOnInit(): void {
		this.setData();
		this.phoneNumber$.pipe(notNull(), takeUntil(this.unsubscribe$$)).subscribe(phone => {
			this.form.get("phoneNumber")?.patchValue(phone.slice(4));
			this.changeDetector.detectChanges();
			this.confirmCertificate();
		});
	}

	public confirmCertificate(): void {
		ValidatorsUtil.triggerValidation(this.form);
		if (this.form.invalid) {
			this.userErrorsService.displayErrors(this.form);
			return;
		}
		if (this.isAuthorizeMobileEds) {
			this.store.dispatch(authorizeCryptoMobile());
			this.actions.pipe(
				ofType(authorizeCryptoMobileSuccess),
				take(1)
			).subscribe((value) => {
				this.urlScMobileForm = value.mobileEds;
				this.changeDetector.detectChanges();
			});
			return;
		}

		const mobileEds = {
			phoneNumber: "+375" + this.form.value?.phoneNumber,
			authUrl: this.form.value?.authUrl ? this.form.value?.authUrl : null,
		};
		this.store.dispatch(attachCryptoMobile(mobileEds));
		this.actions.pipe(
			ofType(attachCryptoMobileSuccess),
			take(1)
		).subscribe((value) => {
			this.urlScMobileForm = value.mobileEds;
			this.changeDetector.detectChanges();
		});
	}

	public get url(): any {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlScMobileForm.authUrl);
	}

	public close(isClosed: boolean): void {
	  this.ref.close(isClosed);
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
		window.removeEventListener("message", this.listener);
	}

	private openObservable(data: any): void {
		this.isFirst = false;
		window.removeEventListener("message", this.listener);
		let obs = null;
		if (data?.method === "callback") {
			obs = this.userBackendService.crypto.callbackCryptoMobile.post$(data?.search);
		}
		if (!obs) {
			throw "no method from service";
		}
		obs.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => this.close(!!this.urlScMobileForm));
	}
	
	private setData(): void {
		this.config.width = "1100px";
		this.config.header = "Привязать ЭЦП-М";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
	}
}
