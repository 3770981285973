import { registerLocaleData } from "@angular/common";
import localeRuBy from "@angular/common/locales/ru-BY";
import { LOCALE_ID, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "@core/core.module";
import { RouterModule as AppRouterModule } from "./router/router.module";
import { SharedModule } from "@shared/shared.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { CryptoMobileComponent } from "./crypto-mobile/crypto-mobile.component";
import { UserCoreModule } from "./user/user-core/user-core.module";
import { TranslationService } from "@core/translation.service";
import { DownloadFileService } from "./user/documents/documents-services/downloadFile.service";
import { TranslationComponent } from "./user/translation/translation.component";
import { InactionPopupComponent } from "./inaction-popup/inaction-popup.component";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";

registerLocaleData(localeRuBy, "ru-By");

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        CryptoMobileComponent,
        TranslationComponent,
        InactionPopupComponent
    ],
    imports: [
        AppRouterModule,
        BrowserAnimationsModule,
        UserCoreModule,
        BrowserModule,
        CoreModule,
        SharedModule,
        DynamicDialogModule,
        EffectsModule.forRoot([]),
        StoreModule.forRoot({}, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: false,
                strictActionSerializability: false,
            }
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25 }),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "ru-By" },
        TranslationService,
        DownloadFileService,
        DialogService,
        { provide: "TranslateComponent", useValue: TranslationComponent }
    ]
})
export class AppModule { }
