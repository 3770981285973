import { Injectable } from "@angular/core";
import { Store, createSelector, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SelectStoreService {
	private storeName = "";
	constructor(protected readonly store: Store) {}

	public selectFn = (appState: any): any => appState[this.storeName];

	public set storageName(name: string) {
		this.storeName = name;
	}

	public select$<T>(fn: (state: any) => any, mappingView?: (obj: [any, string][]) => [any, string]): Observable<[T, string][]> {
		const selector = createSelector(this.selectFn, fn);
		return this.store.pipe(
			select(selector),
			map((obj): [any, string][] => mappingView ? mappingView(obj) : [])
		);
	}
}
