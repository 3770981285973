import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges, Type, ViewChild } from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, map, take, takeUntil, tap } from "rxjs/operators";
import { SelectBoxSelfFetchState, SelfFetchOption } from "@shared/select-box-self-fetch/select-box-self-fetch.component";
import { DefaultFormControl } from "@shared/default-form-control/default-form-control";
import { Storage } from "@helper/abstraction/storages";
import { TemplateUtil } from "@helper/template-util";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef(<T>(): Type<MultiselectBoxSelfFetchComponent<T>> => MultiselectBoxSelfFetchComponent)
	}],
	selector: "app-multiselect-box-self-fetch",
	templateUrl: "./multiselect-box-self-fetch.component.html",
	styleUrls: ["./multiselect-box-self-fetch.component.scss"]
})
export class MultiselectBoxSelfFetchComponent<T> extends DefaultFormControl implements OnChanges, OnDestroy, AfterViewInit {
	@HostBinding("attr.disabled") public get disabled(): "" | null {
		return this.isDisabled ? "" : null;
	}
	@Input() public isDisabled = false;
	@Input() public documentType?: string;
	@Input() public documentState?: string;
	@Input() public withoutPresetData = false;
	@ViewChild("preset", { static: true, read: ElementRef }) public set preset(elementRef: ElementRef<HTMLElement>) {
		this.presetData = TemplateUtil.getArray(elementRef.nativeElement);
	}
	@Input() public valueTransformFn?: (value: any) => string;
	@Input() public showSearch = true;
	@Input() public isRemoveSelected = false;
	@Input() public fieldName?: string;
	@Input() public listClass = "small-hight";
	@Input() public placeholder?: string;
	@Input("option") public selfFetchOption: SelfFetchOption<T>;
	@Input() public set otherEmptyValue(element: HTMLElement) {
		this.presetData = TemplateUtil.getArray(element);
	}
	@Output() public isItemExist: EventEmitter<boolean> = new EventEmitter<boolean>();
	public textValue?: string;
	public expand = false;
	public pending = false;
	public data: [any, string][] = [];
	public presetData: [any, string][] = [];
	public value: any | null = null;
	public selected?: [any, string]; //todo: refactor selected
	public input$$ = new Subject<string>();
	private initialState: SelectBoxSelfFetchState = { page: 1, size: 30 };
	private state$$: BehaviorSubject<SelectBoxSelfFetchState>;
	private stateTracker$$?: Subject<void>;
	private defaultOption: SelfFetchOption<T> = {
		getInitialState: (): SelectBoxSelfFetchState => ({ ...this.initialState }),
		nextChunk: (state): SelectBoxSelfFetchState => ({ ...state, page: state.page + 1 })
	};

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		controlContainer: ControlContainer,
	) {
		super(controlContainer, changeDetectorRef);
		this.selfFetchOption = { ...this.defaultOption };
		this.state$$ = new BehaviorSubject<SelectBoxSelfFetchState>(this.selfFetchOption.getInitialState());
		this.input$$.pipe(
			debounceTime(250),
			takeUntil(this.unsubscribe$$)
		).subscribe(text => this.onInput(text));
	}

	public expandList(flag: boolean): void {
		if (this.expand !== flag ) {
			this.expand = flag;
		}
	}

	public switchExpandState(event: Event): void {
		event.stopPropagation();
		this.expand = !this.expand;

		if (this.expand){
			this.pending = true;
			this.input$$.next("");
		}
	}

	public deleteItem(index: number, event: Event): void {
		event.stopPropagation();
		this.value.splice(index, 1);
		if (this.onChange)
			this.onChange(this.value);

		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public addItem(item: [any, string]): void {
		if (!this.value) {
			this.value = [];
		}

		const itemExist: boolean = this.value.some((el: any) => el.id === item[0].id);
		if (itemExist && this.isRemoveSelected) {
			this.isItemExist.emit(itemExist);
		}
		if (!itemExist) {
			this.isRemoveSelected ? this.value.unshift(item[0]) : this.value.push(item[0]);
		}

		if (this.onChange)
			this.onChange(this.value);

		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public readonly trackByFn = (index: number, item: Storage): any => item.id;

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
			// if not pass required function use default
			this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
		}
		if (
			(this.documentType && simpleChanges?.documentType?.currentValue !== simpleChanges?.documentType?.previousValue)
			||
			(this.documentState && simpleChanges?.documentState?.currentValue !== simpleChanges?.documentState?.previousValue)
		) {
			this.setData([]);
			this.changeDetectorRef.detectChanges();
		}
	}

	public ngAfterViewInit(): void {
		this.setData(this.data);
		this.writeValue(this.value);
		this.changeDetectorRef.markForCheck();
	}

	public writeValue(value: any): void {
		if (value === undefined) {
			value = null;
		}
		this.value = value;
		this.selected = this.data.find(e => e[0] === value) || value && this.transform(value);
	}

	public select(item: [any, string]): void {
		this.writeValue(item[0]);
		this.selected = item.slice(0) as [any, string]; // new instance !
		this.collapse();
		if (this.onChange)
			this.onChange(item[0]);
		this.changeDetectorRef.markForCheck();
	}

	public onInput(search: string): void {
		this.resetData();
		this.cleanStateTracker();
		const newState = { ...this.selfFetchOption.getInitialState(), search };
		this.state$$.next(newState);
		this.stateTracker$$ = this.trackState$$();
	}

	public onScrolled(): void {
		this.state$$.next(this.selfFetchOption.nextChunk(this.state$$.value));
	}

	public onClickOutside(): void {
		if (this.expand)
			this.collapse();
	}

	public collapse(): void {
		this.expand = false;
		this.cleanStateTracker();
		this.resetData();
	}

	public unfold(): void {
		this.expand = true;
		this.state$$.next(this.selfFetchOption.getInitialState());
		this.stateTracker$$ = this.trackState$$();
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.changeDetectorRef.markForCheck();
	}

	public ngOnDestroy(): void {
		this.cleanStateTracker();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	private trackState$$(): Subject<void> {
		const unsubscribe$$ = new Subject<void>();
		this.state$$.pipe(
			takeUntil(this.unsubscribe$$),
			takeUntil(unsubscribe$$)
		).subscribe(state => {
			if (!this.selfFetchOption.getData$)
				throw Error("No getData$ using for enrich date");

			this.selfFetchOption.getData$(state).pipe(
				take(1),
				map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
				takeUntil(this.unsubscribe$$),
				takeUntil(unsubscribe$$)
			).subscribe(data => {
				if (Array.isArray(data) && !data.length) {
					unsubscribe$$.next();
					unsubscribe$$.complete();
				}
				this.pending = false;
				this.appendData(data);
				this.changeDetectorRef.detectChanges();
			});
		});
		return unsubscribe$$;
	}

	private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

	private transform(value: any): [any, string] {
		if (this.selfFetchOption.mapData) {
			const data = this.selfFetchOption.mapData([value] as any as T);
			if (data.length)
				return data[0];
		}
		return [value, value]; // todo: write call transform fn here
	}

	private resetData(): void {
		this.data = !this.withoutPresetData ? [...this.presetData] : [];
	}

	private appendData(newData: [any, string][]): void {
		this.data = [...this.data, ...newData];
	}

	private setData(newData: [any, string][]): void {
		this.data = !this.withoutPresetData ? [...this.presetData, ...newData] : [...newData];
	}
}
