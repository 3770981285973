<div *ngFor="let element of list" [class.push]="isPush">
	<app-icon
		*ngIf="element[0] !== value"
		(click)="isChange? checkItem(element[0]): null"
		[class.cursor-none]="!isChange"
		[class.isActive]="isActive"
		>
		{{iconName.uncheckedIconName}}
	</app-icon>
	<app-icon
		*ngIf="element[0] === value"
		(click)="isChange? checkItem(element[0]): null"
		[class.cursor-none]="!isChange"
		[class.isActive]="isActive"
		>
		{{iconName.checkedIconName}}
	</app-icon>
	<label [class.isActive]="isActive">{{element[1]}}</label>
</div>
