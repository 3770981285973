import { createAction } from "@ngrx/store";

export const setPending = createAction(
	"[App] Set Pending",
	(isPending: boolean): { isPending: boolean } => ({ isPending })
);

export const setFormPending = createAction(
	"[App] Set Form Pending",
	(isPending: boolean): { isPending: boolean } => ({ isPending })
);

export const setPopupPending = createAction(
	"[App] Set Popup Pending",
	(isPending: boolean): { isPending: boolean } => ({ isPending })
);

export const setRouterPending = createAction(
	"[App] Set Router Pending",
	(isPending: boolean): { isPending: boolean } => ({ isPending })
);

export const setTablePending = createAction(
	"[App] Set Table Pending",
	(isPending: boolean): { isPending: boolean } => ({ isPending })
);

export const openInactionPopup = createAction(
	"[App] Open Inaction Popup"
);
