<div class="grid-container" #container (scroll)="onScroll(container)">

	<table [class.row-noclicked]="documentType === 'THIRDPARTYACCESS'">
		<thead [class.upZIndex]="['counterparty', 'marks'].includes(storeName) && fieldName !== 'roamingList'">
			<tr>
				<th *ngIf="!withoutCheckBoxesAndIcons" [attr.rowspan]="withHeadersChildren ? 2 : 1" [class.row-span]="withHeadersChildren"></th>
				<th *ngIf="!withoutCheckBoxes && !withoutCheckBoxesAndIcons" [attr.rowspan]="withHeadersChildren ? 2 : 1" [class.row-span]="withHeadersChildren" [class.long]="isDateWithTmeFirst && !withoutCheckBoxesAndIcons">
					<div class="icon">
						<app-icon
							*ngIf="selectedItems && selectedItems?.length > 0 && selectedItems?.length < list.length" (click)="selectAllItems(list)">
							indeterminate_check_box</app-icon>
						<app-icon
							*ngIf="!selectedItems || selectedItems?.length === 0" (click)="selectAllItems(list)">
							check_box_outline_blank
						</app-icon>
						<app-icon
							*ngIf="selectedItems && selectedItems?.length && selectedItems?.length === list.length" (click)="clearSelectedItems()">
							check_box
						</app-icon>
					</div>
				</th>
				<th *ngIf="withoutCheckBoxesAndIcons" [attr.rowspan]="1" class="empty-field"></th>

				<ng-container *ngFor="let header of headers">
					<th [class.row-span]="header.children?.length"
						[attr.rowspan]="header.children ? 1 : 2"
						[attr.colspan]="header.children && header.children.length"
						[class.header-border]="header.children && header.children.length">
						{{ header.name }}
					</th>
				</ng-container>

				<ng-container *ngIf="selectedItemsBlock">
					<ng-container *ngTemplateOutlet="selectedItemsBlock, context: { $implicit: { selectedItems: selectedItems } }"></ng-container>
				</ng-container>

			</tr>
			<tr *ngIf="withHeadersChildren">
				<ng-container *ngFor="let header of headers">
					<ng-container *ngIf="header.children">
						<th class="center-text" *ngFor="let field of header.children" [class.child-header-border]="header.children && header.children.length">{{ field.name }}</th>
					</ng-container>
				</ng-container>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of list" (click)="clickRowAction(item, false)"
				[class.visited]="isEqual(item)"
				[class.checked]="checkedItem(item)"
				[attr.class]="item?.processingStatus?.id ==='SENT' || item.read ? 'documents-table': item.read === false ? 'unread' : null">

				<td *ngIf="(isSender(item) || isReceiver(item)) && !withoutCheckBoxesAndIcons">	
					<div class="icon" *ngIf="storeName === 'documents'">
						<app-shared-icons
							[isLoading]="documentType === 'ROSEU' ? item?.deliveryStatus === 1 : item?.deliveryStatus === 7"
							[isSender]="(documentType === 'ROSEU' ? item?.deliveryStatus !== 1 : item?.deliveryStatus !== 7) && isSender(item)"
							[isRead]="(documentType === 'ROSEU' ? item?.deliveryStatus !== 1 : item?.deliveryStatus !== 7) && item?.read"
							[isReadByPartner]="(documentType === 'ROSEU' ? item?.deliveryStatus !== 1 : item?.deliveryStatus !== 7) && item?.readByPartner"
							[titleText]="getTitleText(item)"
							[errorMessage]="item?.deliveryError"
							[class.delivery-error]="documentType !== 'ROSEU' && !!item?.deliveryError"
						></app-shared-icons>
					</div>
				</td>

				<td *ngIf="withoutCheckBoxesAndIcons" class="empty-field"></td>

				<td *ngIf="list?.length && (!withoutCheckBoxes || isSingleSelect)">
					<div class="icon" (click)="$event.stopPropagation()">
						<app-icon *ngIf="checkedItem(item); else notChecked" (click)="selectedItem(item)">check_box</app-icon>
						<ng-template #notChecked>
							<app-icon (click)="selectedItem(item)">check_box_outline_blank</app-icon>
						</ng-template>
					</div>
				</td>

				<ng-container *ngFor="let header of headers">
					<td class="text-left"
						*ngIf="!header.children"
						[class.width-fixed]="storeName === 'documents' && ['status', 'date', 'dateWithText'].includes(header.type)"
						[class.long]="isDateWithTmeFirst">
						<ng-container [ngSwitch]="header.type">
							<div class="icon" *ngSwitchCase="'status'">
								{{ item[header.key]?.name }}
							</div>
							<div class="icon" *ngSwitchCase="'statusWithError'"
								[class.error]="item[header.key]?.error"
								[class.install]="item[header.key]?.value ==='PARTNERSHIP_ESTABLISHED'"
								[class.need-setup]="item[header.key]?.value ==='ROAMING_SETUP_REQUIRED'">
								{{ item[header.key]?.name }}
								<div class="error-wrapper">{{ item[header.key]?.error }}</div>
							</div>

							<div class="icon" *ngSwitchCase="'person'">
								{{ item[header.key]?.name || "—" }}
							</div>

							<div class="icon" *ngSwitchCase="'personWithGLN'">
								{{ item[header.key]?.gln }} |	{{ item[header.key]?.name }}
							</div>

							<div class="icon" *ngSwitchCase="'unp'">
								{{ item[header.key]?.unp || "—" }}
							</div>

							<div class="icon" *ngSwitchCase="'place'">
								{{ item[header.key].address }} {{ item[header.key].gln }}
							</div>

							<div class="icon" *ngSwitchCase="'placeFull'">
								{{ item[header.key]? addressAndGln(item[header.key]): "—" }}
							</div>

							<div class="icon" *ngSwitchCase="'type'">
								<ng-container [ngSwitch]="item[header.key]">
									<ng-container *ngSwitchCase="'BLRWBL'">ТТН</ng-container>
									<ng-container *ngSwitchCase="'BLRDLN'">ТН</ng-container>
								</ng-container>
							</div>

							<div class="icon" *ngSwitchCase="'tpaMsgType'">
								<ng-container [ngSwitch]="item[header.key]">
									<ng-container *ngSwitchCase="'BLRWBL'">ТТН</ng-container>
									<ng-container *ngSwitchCase="'BLRDLN'">ТН</ng-container>
								</ng-container>
							</div>

							<div class="icon" *ngSwitchCase="'processingStatus'">
								{{ getStatuses(item[header.key]) }}
							</div>

							<div class="icon" *ngSwitchCase="'transferStatus'">
								{{ getTransferStatuses(item[header.key]) }}
							</div>

							<div class="icon" *ngSwitchCase="'isGTIN'">
								<span>{{ item[header.key] === 1? "Да": "Нет" }}</span>
							</div>

							<div *ngSwitchCase="'date'">
								{{ header.key === "dateTime" || header.key === "dateCreate" ? (item[header.key] | date: "dd.MM.yyyy HH:mm:ss"):(item[header.key] | date: "dd.MM.yyyy") }}
							</div>

							<div *ngSwitchCase="'dateWithTime'">
								{{ item[header.key] | date: "dd.MM.yyyy HH:mm:ss" }}
							</div>

							<div *ngSwitchCase="'dateWithText'">
								{{ item[header.key] ? (item[header.key] | date: "dd.MM.yyyy HH:mm:ss"): "Нет ответа" }}
							</div>

							<div *ngSwitchCase="'type'">
								{{ item[header.key]?.name }}
							</div>

							<div *ngSwitchCase="'boolean'">
								<app-icon>{{ item[header.key] ? "done" : "clear" }}</app-icon>
							</div>

							<div *ngSwitchCase="'custom'">
								{{ header['resolveFn'] ? header['resolveFn'](item[header.key]) : "No resolve Fn" }}
							</div>

							<div *ngSwitchCase="'boolean-text'">
								<div *ngIf="item[header.key]"> Да </div>
							</div>

							<div *ngSwitchCase="'boolean-fool-text'">
								<div>{{ item[header.key] ? "Да" : "Нет" }}</div>
							</div>

							<div *ngSwitchCase="'extra'">
								<ng-container *ngIf="options">
									<ng-container *ngTemplateOutlet="options, context: { $implicit: { item: item, container: container } }"></ng-container>
								</ng-container>
							</div>

							<div class="icon storage" *ngSwitchCase="'storage'">
								<span *ngIf="!(['ORDERS'].includes(documentType) && storeName === 'statistics')">{{ item[header.key]?.address || item[header.key]?.addressFull || "—" }}</span>
								<span class="storage-gln" *ngIf="['TRANSIT'].includes(filter.documentTypeId) && !['deliveryStorage'].includes(header.key) && item[header.key]?.gln">{{ item[header.key]?.gln }}</span>
								<span class="storage-gln" *ngIf="!['TRANSIT'].includes(filter.documentTypeId) && item[header.key]?.gln">{{ item[header.key]?.gln }}</span>

								<!-- ORDERS && statistics -->
								<span *ngIf="['ORDERS'].includes(documentType) && storeName === 'statistics'">{{ item?.deliveryPointAddress || "—" }}</span>
								<span class="storage-gln" *ngIf="['ORDERS'].includes(documentType) && storeName === 'statistics'">{{ item?.deliveryPointGln || "" }}</span>
							</div>

							<div *ngSwitchCase="'isFieldClicked'">
								<div class="field-clicked" *ngIf="item[header.key]" (click)="clickFieldAction(item)">{{ item[header.key] || "—" }}</div>
							</div>

							<div *ngSwitchCase="'decimal'">
								<div>{{ getValueFormatedDecimals(item[header.key]) || "—" }}</div>
							</div>

							<div *ngSwitchDefault>{{ item[header.key] || "—" }}</div>
						</ng-container>
					</td>
					<ng-container *ngIf="header.children">
						<td *ngFor="let child of header.children">
							<ng-container *ngIf="item[header.key]">
								<ng-container [ngSwitch]="child.type">
									<div *ngSwitchCase="'date'" class="max-width-time">
										<ng-container *ngIf="header.type === 'array'; else simple">
											<div *ngFor="let doc of item[header.key]">
												{{ doc[child.key] | date: "dd.MM.yyyy HH:mm" }}
											</div>
										</ng-container>
										<ng-template #simple>
											{{ item[header.key][child.key] | date: "dd.MM.yyyy HH:mm" }}
										</ng-template>
									</div>

									<div *ngSwitchCase="'dateWithoutTime'">
											{{ item[header.key][child.key] | date: "dd.MM.yyyy" }}
									</div>

									<div *ngSwitchCase="'link'" [class]="item[header.key] ? 'link': ''" >
										<ng-container *ngIf="header.type === 'array'; else simple">
											<div *ngFor="let doc of item[header.key]" (click)="openUrl(header.key,undefined, doc.id)">
												{{ doc[child.key] }}
											</div>
										</ng-container>
										<ng-template #simple>
											<div (click)="openUrl(header.key,undefined, item[header.key].id)">
												{{ item[header.key][child.key] }}</div>
										</ng-template>
									</div>

									<div *ngSwitchDefault>
										<ng-container *ngIf="header.type === 'array'; else simple">
											<div *ngFor="let doc of item[header.key]">
												{{ doc[child.key] }}
											</div>
										</ng-container>
										<ng-template #simple>
											{{ item[header.key][child.key] }}
										</ng-template>
									</div>
								</ng-container>
							</ng-container>
						</td>
					</ng-container>
				</ng-container>
			</tr>
		</tbody>
	</table>

</div>
<footer *ngIf="list?.length && footer" >
	<ng-container *ngTemplateOutlet="footer, context: { $implicit: { list: list, selectedItems: selectedItems, totalElements: totalElements } }"></ng-container>
	<app-pagination
		*ngIf="totalElements > filter.size" [totalElements]="totalElements" [page]="filter.page" [filter]="filter"
		(selectedPage$)="setSelectedPage($event)">
	</app-pagination>
</footer>
<!-- <app-pending *ngIf="pending$ | async" [isBlackTheme]="true"></app-pending> -->
<app-pending *ngIf="documentType !== 'ECMR' && (pending$ | async)" [isBlackTheme]="true"></app-pending>

<template #statusList>
	<dl>
		<dt>SENT</dt>
		<dd i18n>Отправляется</dd>
		<dt>TRANSFERRED</dt>
		<dd i18n>Передан</dd>
		<dt>RECEIVED</dt>
		<dd i18n>Получен</dd>
		<dt>CREATED</dt>
		<dd i18n>Создан</dd>
		<dt>CANCELED</dt>
		<dd i18n>Отменен</dd>
		<dt>CHANGE_REQUIRED</dt>
		<dd i18n>Треб. изменений</dd>
		<dt>CREATED_CONFIRMED</dt>
		<dd i18n>Создан и подтвержден</dd>
		<dt>NOT_CONFIRMED_CANCEL</dt>
		<dd i18n>Отмена не подтверждена</dd>
		<dt>CHANGE_REQUIRED_NOT_CONFIRMED</dt>
		<dd i18n>Треб. изменений не подтверждено</dd>
		<dt>CREATED_CANCEL_SENT</dt>
		<dd i18n>Создан (отправлена отмена)</dd>
		<dt>CREATED_CONFIRMED_CANCEL_SENT</dt>
		<dd i18n>Создан и подвержден (отправлена отмена)</dd>
		<dt>AUTOMATICALLY_CANCELLED</dt>
		<dd>Автоматически отменен</dd>
	</dl>
</template>

<template #processMap>
	<dl>
		<dt>send</dt>
		<dd i18n>Обработать и отправить</dd>
		<dt>edit</dt>
		<dd i18n>Редактировать</dd>
		<dt>delete</dt>
		<dd i18n>Удалить</dd>
		<dt>deleteInvitation</dt>
		<dd i18n>Вы уверены, что хотите удалить приглашение?</dd>
		<dt>massDeleteSuccess</dt>
		<dd i18n>Выбранное(-ые) приглашение(-я) успешно удалено(-ы).</dd>

		<dt>WAITING_FOR_CONFIRM</dt>
		<dd i18n>Ожидает обработки </dd>
		<dt>VERIFICATION_ERROR</dt>
		<dd i18n>Ошибка</dd>
		<dt>SENT_TO_PARTNER</dt>
		<dd i18n>Отправлено</dd>
		<dt>PARTNER_REGISTERED</dt>
		<dd i18n>Принято</dd>
		<dt>PARTNER_SWITCH_TO_PAID_TARIFF</dt>
		<dd i18n>Организация перешла на платный тариф</dd>
		<dt>ROAMING_SETUP_REQUIRED</dt>
		<dd i18n>Требуется настройка роуминга</dd>
		<dt>PARTNERSHIP_ESTABLISHED</dt>
		<dd i18n>Установлена партнерская связь</dd>
	</dl>
</template>

<template #transferMap>
	<dl>
		<dt>AWAITING_REQUEST_FROM_THIRD_PARTY</dt>
		<dd i18n>Ожидает запроса от третьего лица</dd>
		<dt>DELIVERED_TO_THIRD_PARTY</dt>
		<dd i18n>Передано третьему лицу</dd>
		<dt>RECEIVED_BY_THIRD_PARTY</dt>
		<dd i18n>Получено третьим лицом</dd>
		<dt>NOT_AVAILABLE_TO_THIRD_PARTY</dt>
		<dd i18n>Не доступно для третьего лица</dd>
		<dt>ERROR</dt>
		<dd i18n>Ошибка</dd>
	</dl>
</template>

<template #actionSelectTemplate>
	<dl>
		<dt>0</dt>
		<dd>Клик 0</dd>
		<dt>1</dt>
		<dd i18n>Клик 1</dd>
	</dl>
</template>
