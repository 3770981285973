import { Component, ChangeDetectionStrategy, Input, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, combineLatest } from "rxjs";
import { take } from "rxjs/operators";
import { createSelector, Store, select } from "@ngrx/store";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { notNull } from "@helper/operators";
import { ValidatorsUtil } from "@helper/validators-util";

export type ProxyPopupForm = {
	login: string;
	unp: string;
	checkUnp: boolean;
	password: string;
};

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-proxy-confirm-popup",
	styleUrls: ["proxy-confirm-popup.component.scss"],
	templateUrl: "./proxy-confirm-popup.component.html"
})

export class ProxyConfirmPopupComponent extends DefaultPopupComponent {
	public form?: FormGroup;
	@Input() public name?: string;
	@Input() public mainStoreName = "user";
	@ViewChild("notification", { static: false }) public overlayComponent?: OverlayComponent;
	public process: Subject<ProxyPopupForm> = new Subject<ProxyPopupForm>();

	constructor(
		private userErrorsService: UserErrorsService,
		private store: Store,
		private fb: FormBuilder
	) {
		super();
		this.initForm();

	}

	public ngOnInit(): void {
		const selectUser = (appState: any): any => appState[this.mainStoreName];
		const checkUnp = createSelector(selectUser, (state: any): boolean | undefined => state.userProfile?.checkUnp);
		const selectCertificateName = createSelector(selectUser, (state: any): string | undefined => state.userProfile?.certificateName);
		const selectUnp = createSelector(selectUser, (state: any): string | undefined => state.organizationInfo?.unp);

		combineLatest(
			this.store.pipe(select(selectCertificateName)),
			this.store.pipe(select(checkUnp)),
			this.store.pipe(select(selectUnp))
		).pipe(
			notNull(),
			take(1)
		).subscribe(([certificateName, checkUnp, unp]) => {
			this.form?.patchValue({ login: certificateName });
			if (checkUnp) {
				this.form?.patchValue({ checkUnp, unp });
			}
		});
		if (this.name) {
			this.form?.patchValue({ login: this.name });
		}
	}

	public confirm(form: FormGroup): void {
		ValidatorsUtil.triggerValidation(form);
		if (form.invalid) {
			this.userErrorsService.displayErrors(form, this.overlayComponent);
			return;
		}
		this.process.next(form?.value);
	}

	private initForm(): void {
		this.form = this.fb.group({
			unp: [null],
			checkUnp: [null],
			login: [null],
			password: [null, Validators.required],
		});
	}
}
