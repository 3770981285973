<div class="container">
	<input #input type="text" (input)="onInput(input.value)" placeholder="Поиск">
</div>
<ul #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)">
	<li *ngFor="let item of list" (click)="select(item)" [class.not-selected-mode]="mode === 'VIEW'">
		<span class="item-name">{{ dataType === "STORAGES" ? item[0].storageName : item[0].name }}</span>
		<span>{{ dataType === "STORAGES" ? item[0].addressFull : item[0].unp }}</span>
		<span class="item-gln">{{ item[0].gln }}</span>
	</li>
</ul>
