import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class PostMessageService {
	public userNotification$$: Subject<any> = new Subject<any>();
	public updateNotification$$: Subject<boolean> = new Subject<boolean>();
	private env = environment;
	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(private router: Router) {
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd && this.env.project === "BM-PRCMT"),
			takeUntil(this.unsubscribe$$)
		).subscribe(event => {
			const data = (event as NavigationEnd).url;
			window.parent.postMessage(data, "*");
		});
	}
}
