import { AfterViewChecked, Component, ElementRef, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
	selector: "app-tooltip",
	templateUrl: "./tooltip.component.html",
	styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent implements AfterViewChecked {
	@Input() public position = "";
	@Input() public templateRef?: TemplateRef<HTMLElement>;
	@Input() public parentHeight?: number;
	@Input() public offset?: number;
	@Input() public appTooltipStyle?: boolean;
	@ViewChild("content", { read: ElementRef, static: false }) public content: ElementRef<any> | undefined;
	constructor(private readonly elementRef: ElementRef) { }

	public ngAfterViewChecked(): void {
		const parentMiddle: number = this.parentHeight && this.parentHeight / 2 || 0;
		const offset: number = this.offset || 0;
		const bottom = `${parentMiddle + offset}px`;
		Object.assign(this.elementRef.nativeElement.style, { bottom });
	}

}
