import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
	selector: "app-logo",
	// templateUrl: "./logoCTT.component.html",
	templateUrl: "./logoBBV.component.html",
	styleUrls: ["./logo.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
	@Input() public isDark = false;
	public env = environment;
}
