<ul *ngIf="totalPages">
  <li>
    <button [disabled]="currentPage === 1 && portionNumber === 1 ? true : false" (click)="setPage(1)">
      <app-icon>
        first_page
      </app-icon>
    </button>
  </li>
  <li>
    <button [disabled]="portionNumber <= 1 ? true : false" (click)="setPortion(portionNumber - 1)">
      <app-icon>
        keyboard_arrow_left
      </app-icon>
    </button>
  </li>
  <div class="pages">
    <li *ngFor="let page of currentPagesPortion" [class.active]="currentPage === page"
      (click)="currentPage !== page ? setPage(page) : ''" @pagesCarouselAnimation>{{page}}
    </li>
  </div>
  <li>
    <button [disabled]="portionNumber >= totalPortions ? true : false" (click)="setPortion(portionNumber + 1)">
      <app-icon>
        keyboard_arrow_right
      </app-icon>
    </button>
  </li>
  <li>
    <button [disabled]="currentPage === totalPages && portionNumber === totalPortions ? true : false"
      (click)="setPage(totalPages)">
      <app-icon>
        last_page
      </app-icon>
    </button>
  </li>
</ul>