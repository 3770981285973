import { ComponentFactoryResolver, ComponentRef, Injectable, Type } from "@angular/core";
import { NavigationCancel, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { NotificationSequenceComponent } from "@shared/notification-sequence/notification-sequence.component";
import { ConfirmDialogComponent } from "@shared/shared-popups/confirm-dialog/confirm-dialog.component";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { OverlayRootService } from "./overlay-root.service";
import { PendingComponent } from "@shared/pending/pending.component";
import { TranslationService } from "./translation.service";

type OriginShowByFactory = OverlayComponent["showByFactory"];
type OriginShowNotification$ = OverlayComponent["showNotification$"];
type OriginShowConfirmation$ = OverlayComponent["showConfirmation$"];
// Возвращает тип параметров функции опуская первый параметр
type SkipFirstParametrs<T> = T extends (arg1: any, ...args: infer U) => any ? U : any;

@Injectable()
export class OverlayService {
	public ref?: DynamicDialogRef;
	private component: OverlayComponent;
	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(
		public dialog: DialogService,
		private router: Router,
		private translationService: TranslationService,
		private overlayRootService: OverlayRootService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {
		this.component = this.overlayRootService.getRootOverlayComponent();
		this.router.events.pipe(
			filter(ev => ev instanceof NavigationCancel),
			takeUntil(this.unsubscribe$$)).subscribe(event => {
				if ((event as NavigationCancel).url === "/user/logout") {
					this.ref?.destroy();
				}
			});
	}

	public show<T>(component: Type<T>, ...args: SkipFirstParametrs<OriginShowByFactory>): ComponentRef<T> {
		const factory = this.componentFactoryResolver.resolveComponentFactory(component);
		return this.component.showByFactory(factory, ...args);
	}

	public showNotification$(...args: Parameters<OriginShowNotification$>): Promise<NotificationSequenceComponent> {
		return this.component.showNotification$(...args);
	}

	public showConfirmation$(...args: Parameters<OriginShowConfirmation$>): Promise<boolean> {
		return this.component.showConfirmation$(...args);
	}

	public showTemporaryNotification$(...args: Parameters<OriginShowNotification$>): Promise<NotificationSequenceComponent> {
		return this.component.showNotification$(...args);
	}

	public clear(): void {
		return this.component.destroy();
	}

	public openConfirm(config: DynamicDialogConfig): DynamicDialogRef {
		const dialogConfig: DynamicDialogConfig = {
			width: "500px"
		}
		return this.dialog.open(ConfirmDialogComponent, { ...dialogConfig, ...config });
	}

	public openLoadingPopup(checkVersion: boolean, description = ""): void {
		if (checkVersion) {
			this.ref = this.dialog.open(PendingComponent, {
				data: { description },
				showHeader: false,
				width: '100%',
				height: '100%'
			});
		} else {
			this.ref?.close();
		}
	}

	public showWarningMessage(): void {
		this.showNotification$(this.translationService.getTranslation("devFunctionaly"), "warning", undefined, true, true);
	}
}
