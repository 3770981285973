import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Activemq } from "@app/user/user-core/api/activemq";
import { Authentication } from "@app/user/user-core/api/authenticate";
import { Datamark } from "@app/user/user-core/api/datamark";
import { Desadv } from "@app/user/user-core/api/desadv";
import { Einvoice } from "@app/user/user-core/api/einvoice";
import { Einvoicepmt } from "@app/user/user-core/api/einvoicepmt";
import { Epass } from "@app/user/user-core/api/epass";
import { Ewaybill } from "@app/user/user-core/api/ewaybill";
import { Export } from "@app/user/user-core/api/export";
import { FormatDocument } from "@app/user/user-core/api/format-document";
import { Import } from "@app/user/user-core/api/import";
import { Mns } from "@app/user/user-core/api/mns";
import { NotificationController } from "@app/user/user-core/api/notification-controller";
import { Nsi } from "@app/user/user-core/api/nsi";
import { Order } from "@app/user/user-core/api/orders";
import { Organization } from "@app/user/user-core/api/organization";
import { Provider } from "@app/user/user-core/api/provider";
import { Registration } from "@app/user/user-core/api/registration";
import { Sections } from "@app/user/user-core/api/sections";
import { Signing } from "@app/user/user-core/api/signing";
import { Statistic } from "@app/user/user-core/api/statistic";
import { Storages } from "@app/user/user-core/api/storages";
import { Transit } from "@app/user/user-core/api/transit";
import { User } from "@app/user/user-core/api/user";
import { UserPermissionService } from "@app/user/user-core/services/user-permission.service";
import { ConfigurationService } from "@core/configuration.service";
import { Store } from "@ngrx/store";
import { Config } from "./api/config";
import { Documents } from "./documents";
import { Crypto } from "@app/user/user-core/api/crypto";
import { CounterParty } from "@app/user/user-core/api/counter-party";

@Injectable()
export class MnsBackendService {
	public readonly activemq: Activemq;
	public readonly authenticate: Authentication;
	public readonly desadv: Desadv;
	public readonly documents: Documents;
	public readonly provider: Provider;
	public readonly einvoice: Einvoice;
	public readonly ewaybill: Ewaybill;
	public readonly export: Export;
	public readonly formatDocument: FormatDocument;
	public readonly nsi: Nsi;
	public readonly order: Order;
	public readonly organization: Organization;
	public readonly sections: Sections;
	public readonly signing: Signing;
	public readonly statistic: Statistic;
	public readonly storages: Storages;
	public readonly user: User;
	public readonly einvoicepmt: Einvoicepmt;
	public readonly transit: Transit;
	public readonly crypto: Crypto;
	public readonly config: Config;
	public readonly import: Import;
	public readonly mns: Mns;
	public readonly registration: Registration;
	public readonly counterParty: CounterParty;
	public readonly datamark: Datamark;
	public readonly epass: Epass;
	public readonly notificationController: NotificationController;


	constructor(
		store: Store,
		configurationService: ConfigurationService,
		httpClient: HttpClient,
		userPermissionService: UserPermissionService
	) {
		this.authenticate = new Authentication(configurationService, httpClient);
		this.organization = new Organization(configurationService, httpClient);
		this.storages = new Storages(configurationService, httpClient);
		this.sections = new Sections(configurationService, httpClient);
		this.nsi = new Nsi(configurationService, httpClient);
		this.provider = new Provider(configurationService, httpClient);
		this.signing = new Signing(configurationService, httpClient);
		this.user = new User(configurationService, httpClient);
		this.formatDocument = new FormatDocument(configurationService, httpClient);
		this.statistic = new Statistic(configurationService, httpClient);
		this.activemq = new Activemq(configurationService, httpClient);
		this.export = new Export(configurationService, httpClient);
		this.ewaybill = new Ewaybill(configurationService, httpClient);
		this.einvoice = new Einvoice(configurationService, httpClient);
		this.desadv = new Desadv(configurationService, httpClient);
		this.order = new Order(configurationService, httpClient);
		this.documents = new Documents(configurationService, httpClient, this, userPermissionService);
		this.einvoicepmt = new Einvoicepmt(configurationService, httpClient);
		this.transit = new Transit(configurationService, httpClient);
		this.config = new Config(configurationService, httpClient);
		this.import = new Import(configurationService, httpClient);
		this.mns = new Mns(store, configurationService, httpClient);
		this.crypto = new Crypto(configurationService, httpClient);
		this.registration = new Registration(configurationService, httpClient);
		this.counterParty = new CounterParty(configurationService, httpClient);
		this.datamark = new Datamark(configurationService, httpClient);
		this.epass = new Epass(configurationService, httpClient);
		this.notificationController = new NotificationController(configurationService, httpClient);
	}

	public getController(type: any): any {
		switch (type) {
			case "DESADV": {
				return this.desadv;
			}
			case "EWAYBILL": {
				return this.ewaybill;
			}
			case "EINVOICE": {
				return this.einvoice;
			}
			case "ORDERS":
				return this.order;
			case "EINVOICEPMT": {
				return this.einvoicepmt;
			}
			case "TRANSIT": {
				return this.transit;
			}
			default:
				throw Error(`Controller for ${type} doesn't exist`);
		}
	}
}
