import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { ConsigneesDto } from "@helper/abstraction/consignee";
import { SelfFetchOption } from "@shared/storage-popup/storage-grid/storage-grid.component";

@Component({
	selector: "app-party-popup",
	templateUrl: "./party-popup.component.html",
	styleUrls: ["./party-popup.component.scss"],
})
export class PartyPopupComponent implements OnInit {
	public formType?: number;
	public options?: SelfFetchOption<any>;
	public mode: "CREATE" | "VIEW" | "EDIT" | "SELECT" = "SELECT";
	public dataType: "STORAGES" | "PARTY" = "PARTY";

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef
	) { }

	public ngOnInit(): void {
		this.setData();
	}

	public setData(): void {
		this.config.width = "490px";
		this.config.height = "590px";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
	}

	public close(): void {
		this.ref.close();
	}

	public addParty(party: ConsigneesDto): void {
		this.ref.close(party);
	}
}
