import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TemplateUtil } from "@helper/template-util";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-select-box",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<SelectBoxComponent> => SelectBoxComponent)
	}],
	templateUrl: "./select-box.component.html",
	styleUrls: ["./select-box.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectBoxComponent extends BoxValueAccessor implements OnChanges, OnInit{
	//#region Input variables
	@Input() public data: [any, string][] | HTMLElement = [];
	@Input() public valueTransformFn?: (value: any) => string;
	@Input() public placeholder?: string = "";
	@Input() public formControlName?: string;
	@Input() public displayValidation = true;
	@Input() public userStatusesList = false;
	@Input() public formControl?: AbstractControl;
	@Input() public stopScrolling = false;
	@Input() public error = false;
	@Input() public showSearch = true;
	@Input() public hideClear = false;
	@Input() public newStyle = false;
	@Input() public set otherEmptyValue(element: HTMLElement | [any, string][]) {
		this.presetValues = element instanceof HTMLElement?  TemplateUtil.getArray(element): element;
	}
	@Input() public set isDisabled(value: boolean) {
		this.disabled = typeof value === "boolean"? value : null; //todo: remove it
	}
	//#endregion

	//#region public variables
	public disabled: boolean | null = null;
	public presetValues: [any, string][] = [];
	public list: [any, string][] = [];
	public expand = false;
	public showError = false;
	public control?: AbstractControl | null;
	public unsubscribe$$ = new Subject<void>();
	//#endregion

	//#region Output variables
	@Output() public appFilterChanges: EventEmitter<{ search: string }> = new EventEmitter<{ search: string }>();
	@Output() public appNextPage: EventEmitter<void> = new EventEmitter<void>();
	@Output() public appCollapse = new EventEmitter<void>();
	//#endregion

	//#region private variables
	private templateUtil = TemplateUtil;
	//#endregion

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		public readonly controlContainer: ControlContainer
	) {
		super();
		this.value = null;
	}

	public ngOnInit(): void {
		if (!this.displayValidation){
			return;
		}

		if (this.formControl){
			this.control = this.formControl;
		}

		if (this.controlContainer && this.formControlName && this.controlContainer.control){
			this.control = this.controlContainer.control.get(this.formControlName);
		}

		// Subscribe need to change control touched state
		this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error){
				this.showError = this.control && this.control.touched && this.control.invalid || false;
			} else{
				this.showError = this.error;
			}

			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data) {
			if (this.data instanceof Element || this.data instanceof HTMLDocument) {
				this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.templateUtil.getArray(this.data as HTMLElement));
			}
			else if (Array.isArray(this.data)) {
				this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.data.slice(0));
			}
			else {
				throw Error("Invalid data format");
			}
		}
	}

	public deleteItem(event: Event): void {
		event.stopPropagation();
		this.value = null;
		this.changeAndTouch();
		this.changeDetectorRef.markForCheck();
	}

	public addItem(item: [any, string]): void {
		this.value = item[0];
		this.changeAndTouch();
		this.switchExpandState();
		this.changeDetectorRef.markForCheck();
	}

	public onScrolled(): void {
		if (!this.stopScrolling) {
			this.appNextPage.emit();
		}
	}

	public switchExpandState(event?: Event): void {
		event?.stopPropagation();
		if (this.disabled) {
			return;
		}
		this.expand = !this.expand;
		if (this.expand) {
			this.appCollapse.emit();
		}
	}

	public onClickOutside(): void {
		if (this.expand){
			this.switchExpandState();
		}
	}

	public onInput(value: string): void {
		this.appFilterChanges.emit({ search: value });
	}

	private changeAndTouch(): void {
		if (this.onChange) {
			this.onChange(this.value);
		}
		if (this.onTouched){
			this.onTouched();
		}
	}
}
