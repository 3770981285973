import { Store } from "@ngrx/store";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { Subject } from "rxjs";
import { takeUntil, throttleTime } from "rxjs/operators";
import { SelectStoreService } from "./select-store.service";
import { TemplateUtil } from "@helper/template-util";
import { ExtraField } from "@helper/abstraction/extra-fields";
import { DocumentType } from "@helper/abstraction/documents";

@Component({
	selector: "app-auto-select-box",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<AutoSelectBoxComponent> => AutoSelectBoxComponent)
	}],
	templateUrl: "./auto-select-box.component.html",
	styleUrls: ["./auto-select-box.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoSelectBoxComponent extends BoxValueAccessor implements OnInit, OnChanges {
	@Input() public storeName = "";
	@Input() public fieldName = "";
	@Input() public getAction: any; // actions to get a list of data
	@Input() public successAction: any; // action to display a list of data
	@Input() public errorAction: any; // action to display errors
	@Input() public resetAction: any; // actions to reset the data list
	@Input() public filter = { page: 1, size: 20, search: "" };
	@Input() public valueTransformFn?: (value: any) => string;
	@Input() public mappingView?: (obj: [any, string][]) => [any, string];
	@Input() public placeholder?: string = "";
	@Input() public formControlName?: string;
	@Input() public displayValidation = true;
	@Input() public userStatusesList = false;
	@Input() public formControl?: AbstractControl;
	@Input() public error = false;
	@Input() public showSearch = true;
	@Input() public hideRow?: number;
	@Input() public hideRows?: string[];
	@Input() public documentTypeId?: DocumentType;

	@Input() public set otherEmptyValue(element: HTMLElement | [any, string][]) {
		const list = element instanceof HTMLElement?  TemplateUtil.getArray(element): element;
		this.presetValues = list.map(el => [{ id: el[0], name:el[1] }, el[1]]);
	}
	@Input() public set isDisabled(value: boolean) {
		this.disabled = typeof value === "boolean"? value : null; //todo: remove it
	}
	@Input() public data: [any, string][] | HTMLElement = [];
	//#endregion

	//#region public variables
	public templateUtil = TemplateUtil;
	public disabled: boolean | null = null;
	public defaultFilter = { page: 1, size: 20, search: "" };
	public pending = false;
	public expand = false;
	public showError = false;
	public presetValues: [any, string][] = [];
	public list: [any, string][] = [];
	public control?: AbstractControl | null;
	//#endregion

	//#region Output variables
	@Output() public appFilterChanges: EventEmitter<{ search: string }> = new EventEmitter<{ search: string }>();
	@Output() public appNextPage: EventEmitter<void> = new EventEmitter<void>();
	@Output() public appCollapse = new EventEmitter<void>();
	//#endregion

	//#region private variables
	private scroll$: Subject<HTMLElement> = new EventEmitter<HTMLElement>();
	private input$: Subject<string> = new EventEmitter<string>();
	private unsubscribe$$ = new Subject<void>();
	private stopScrolling = false;
	private throttleTime = 300;
	//#endregion

	constructor(
		private readonly changeDetector: ChangeDetectorRef,
		private readonly controlContainer: ControlContainer,
		private selectStoreService: SelectStoreService,
		private store: Store
	) {
		super();
		this.value = null;
	}

	public ngOnInit(): void {
		if (!this.displayValidation){
			return;
		}

		if (this.formControl){
			this.control = this.formControl;
		}

		if (this.controlContainer && this.formControlName && this.controlContainer.control){
			this.control = this.controlContainer.control.get(this.formControlName);
		}

		// Subscribe need to change control touched state
		this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error){
				this.showError = this.control && this.control.touched && this.control.invalid || false;
			} else{
				this.showError = this.error;
			}

			this.changeDetector.detectChanges();
		});

		this.selectStoreService.storageName = this.storeName;
		this.selectStoreService.select$<any>( store => store[this.fieldName], this.mappingView)
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe((listValue: any) => {
				if (listValue?.length) {
					this.list = this.list.concat(
						this.hideRow && !this.hideRows?.length ? listValue.filter((f: any) => f[0].id !== this.hideRow)
							: this.hideRows?.length ? listValue.filter((m: any) => !this.hideRows?.includes((m[0] as ExtraField).fieldCode))
								: listValue);
					if (this.list[0][0].id !== "-1") {
						this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.list.slice(0));
					}
					this.pending = false;
					this.stopScrolling = !(listValue.length === this.filter.size);
					this.changeDetector.detectChanges();
					return;
				}
				this.pending = false;
				this.stopScrolling = true;
				this.changeDetector.detectChanges();
			});

		this.setScrollSubscription();
		this.setInputSubscription();
		this.defaultFilter = { ...this.filter };
		this.changeDetector.markForCheck();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ("isDisabled" in changes) {
			this.expand = false;
			this.changeDetector.detectChanges();
		}
		if ("filter" in changes) {
			this.defaultFilter = { ...this.filter };
			this.changeDetector.detectChanges();
		}
	}

	public deleteItem(event: Event): void {
		event.stopPropagation();
		this.value = null;
		this.changeAndTouch();
		this.changeDetector.markForCheck();
	}

	public addItem(item: [any, string]): void {
		this.value = item[0];
		this.changeAndTouch();
		this.switchExpandState();
		this.changeDetector.markForCheck();
	}

	public onScroll(container: any): void {
		if (this.stopScrolling) {
			return;
		}
		this.scroll$.next(container);
	}

	public switchExpandState(event?: Event): void {
		event?.stopPropagation();
		if (this.disabled) {
			return;
		}
		this.expand = !this.expand;
		this.clearFilter();
		if (this.expand) {
			this.pending = true;
			this.getListData();
		}
	}

	public onClickOutside(): void {
		if (this.expand){
			this.switchExpandState();
		}
	}

	public onInput(value: string): void {
		this.input$.next(value);
	}

	private changeAndTouch(): void {
		if (this.onChange) {
			this.onChange(this.value);
		}
		if (this.onTouched){
			this.onTouched();
		}
	}

	private setScrollSubscription(): void {
		this.scroll$
			.pipe(
				throttleTime(this.throttleTime),
				takeUntil(this.unsubscribe$$)
			).subscribe(() => {
				this.pending = true;
				this.stopScrolling = true;
				this.filter.page = this.filter.page + 1;
				this.getListData();
			});
	}

	private setInputSubscription(): void {
		this.input$
			.pipe(
				throttleTime(this.throttleTime),
				takeUntil(this.unsubscribe$$)
			).subscribe((value) => {
				this.store.dispatch(this.resetAction());
				this.list = [];
				this.filter.page = 1;
				this.filter.search = value;
				this.pending = true;
				this.stopScrolling = true;
				this.getListData();
				this.changeDetector.detectChanges();
			});
	}

	private getListData(): void {
		this.store.dispatch(this.getAction(this.documentTypeId !== "ROSEU" ? this.filter : this.documentTypeId));
	}

	private clearFilter(): void {
		this.list = [];
		this.filter = { ...this.defaultFilter };
		this.store.dispatch(this.resetAction());
	}

}
