import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Table } from "primeng/table";
import { Big } from "big.js";
import { pluralize } from 'numeralize-ru';

import { dependentFields, emptyFields, nonHiddenFields } from '@app/user/documents/eact/eact-grid-metadata';
import { EactdifItemMarkCodeCell, EactdifItemMarkCodeMapCell } from "@helper/abstraction/eact";
import { DataTableModel } from '../edit-cell-grid/edit-cell-grid.component';

@Component({
	selector: 'app-edit-grid',
	templateUrl: './edit-grid.component.html',
	styleUrls: ['./edit-grid.component.scss'],
	animations: [
		trigger('rowExpansionTrigger', [
			state('void', style({
				transform: 'translateX(-10%)',
				opacity: 0
			})),
			state('active', style({
				transform: 'translateX(0)',
				opacity: 1
			})),
			transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class EditGridComponent<T> {
	@Input() public data?: T;
	@Input() public headerMetadataTable?: DataTableModel[];
	@Input() public isViewMode = false;
	@Input() public isAddMode = false;
  	@Output() public cellChanged: EventEmitter<T> = new EventEmitter<T>();
	@Output() public codesImported: EventEmitter<string> = new EventEmitter<string>();
	public markCode = "";
	public pluralize = pluralize;
	private dependentFields = dependentFields;

	public editInternalTableComplete(event: T): void {
		// TODO add event to EventEmitter
		this.updateDependentFields(event);
		this.cellChanged.next(this.data);
	}

	public fieldWidth(size: number): string {
		return `width: ${size}rem`;
	}

	public keys(row: any): any {
		return Object.keys(row);
	}

	public arrowPressed(event: KeyboardEvent): void {
		event.stopPropagation();
		return;
	}

	public checkTooltipLength(value: string): boolean {
		if (!value) {
			return true;
		}
		return value?.length < 12;
	}

	public checkTooltipText(value: string): string {
		if (!value) {
			return "";
		}
		return value;
	}

	public handleEmptyField(key: string): string {
		return key.substring(0, 2) === "no" ? "" : "-";
	}

	public editInputComplete(event: string, key: string): void {
		(this.data as any)[key] = event;
	}

	public updateDependentFields(position: any): boolean {
		this.dependentFields.forEach(key => {
			const amountWithoutVat: Big = new Big(position[key[2]] && +position[key[2]] || 0).times(new Big(position[key[0]] && +position[key[0]] || 0)).round(2);
			const amountVat: Big = +position[key[1]] ? amountWithoutVat.times(+position[key[1]]).div(100).round(2) : new Big(0);
			const amountWithVat: Big = amountWithoutVat.plus(amountVat);
			// Количество 		key[0]
			// Ставка НДС,% 	key[1]
			// Цена 			key[2]
			// Стоимость 		key[3]
			// Сумма НДС 		key[4]
			// Стоимость с НДС	key[5]
			if (key[3]) position[key[3]] = amountWithoutVat && amountWithoutVat.toFixed(2) !== "0.00" ? amountWithoutVat.toFixed(2) : this.checkKeys(key, position) ? "0.00" : undefined;
			if (key[4])	position[key[4]] = amountVat && amountVat.toFixed(2) !== "0.00" ? amountVat.toFixed(2) : this.checkKeys(key, position) ? "0.00" : undefined;
			if (key[5])	position[key[5]] = amountWithVat && amountWithVat.toFixed(2) !== "0.00" ? amountWithVat.toFixed(2) : this.checkKeys(key, position) ? "0.00" : undefined;
			if (key[2]) position[key[2]] = ![undefined, null, ""].includes(position[key[2]]) && (+position[key[0]] > 0 || +position[key[0]] >= 0 && nonHiddenFields.includes(key[2])) ? position.priceFact : undefined;
			if (key[0]) {
				position[key[1]] = ![undefined, null, ""].includes(position[key[0]]) && +position[key[0]] >= 0 ? position.vatRateFact ?? position.vatRate : undefined;
				position[key[2]] = ![undefined, null, ""].includes(position[key[0]]) && +position[key[0]] >= 0 ? position.priceFact : undefined;
			}
		});
		return true;
	}

	public setMarkCode(code: string, content: DataTableModel): void {
		if (!code.trim()) {
			return;
		}
		this.markCode = "";
		const key = this.getFiledListKey(content?.fields);
		(this.data as any).markCodeDtoList.forEach((el: EactdifItemMarkCodeCell) => {
			if (Object.keys(el).includes(key)) {
				el[key][0]?.list?.unshift(code);
			}
		});
	}

	public clearMarkCode(code?: string, content?: DataTableModel): void {
		const key = this.getFiledListKey(content?.fields);
		(this.data as any).markCodeDtoList.forEach((el: EactdifItemMarkCodeCell) => {
			if (Object.keys(el).includes(key)) {
				el[key].forEach((item: EactdifItemMarkCodeMapCell) => {
			  		item.list.forEach((markCode: string, i: number) => {
						if (code === markCode) {
							item.list.splice(i, 1);
						}
			  		});
				});
		 	}
		});
	}

	public resetFilter(): void {
		this.markCode = "";
	}

	public importCodes(fields: any): void {
		this.codesImported.next(this.getFiledListKey(fields));
	}

	public getFiledListKey(fields: any): string {
		return Object.keys(fields).filter(el => el.includes("List"))[0];
	}

	public expand(event: { originalEvent: PointerEvent, data?: T }, dt: Table): void {
		event.originalEvent.stopPropagation();
		dt?.wrapperViewChild?.nativeElement?.scrollTo({ left: 0 });
	}

	public checkExpandedRaw(raw: any, lengthCode?: number): boolean {
		if (
			Object.keys(raw).includes("orderedList") && this.isAddMode
				||
			Object.keys(raw).includes("orderedList") && !this.isAddMode && lengthCode === 0
		) {
			return false;
		}
		return !!Object.keys(raw).filter(el => el.includes("List")).length;
	}

	public getCodesLength(data: EactdifItemMarkCodeMapCell[]): number {
		let length: number = 0;
		data?.forEach(el => {
			length += el.list.length;
		});
		return length;
	}

	public removeAll(data: EactdifItemMarkCodeMapCell[]): void {
		data.forEach(el => {
			el.list = [];
		});
	}

	public isOrderedList(content: DataTableModel): boolean {
    	return this.getFiledListKey(content.fields) === "orderedList";
    }

	private checkKeys(key: string[], position: any): boolean {
		return emptyFields.includes(key[3 || 4 || 5]) && +position[key[0]] >= 0 && !["", null, undefined].includes(position[key[0]]);
	}
}
