import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { ValidatorsUtil } from "@helper/validators-util";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { ConfigurationService } from "@core/configuration.service";

@Component({
	selector: "app-sc-crypto-redefine-port-popup",
	templateUrl: "./sc-crypto-redefine-port-popup.component.html",
	styleUrls: ["./sc-crypto-redefine-port-popup.component.scss"]
})
export class ScCryptoRedefinePortPopupComponent extends DefaultPopupComponent {
	public port: number;
	public form: FormGroup;
	public redefinePort$ = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private userErrorsService: UserErrorsService,
		private configurationService: ConfigurationService
	) {
		super();
		this.configurationService.resetEdsPort();
		this.port = this.configurationService.port;
		this.form = this.formBuilder.group({
			portControl: [this.port, [Validators.required, ValidatorsUtil.checkEdsProxyPort()]]
		});
	}

	public enter(): void {
		if (this.form.invalid) {
			ValidatorsUtil.triggerValidation(this.form);
			this.userErrorsService.displayErrors(this.form);
			return;
		}
		this.configurationService.newPort = this.form.value.portControl;
		this.redefinePort$.next();
	}
}
