import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NumberType } from '@shared/numberbox/numberbox.component';

@Component({
	styleUrls: ["./number.component.scss"],
  	selector: 'app-number',
	template: `
        <input
			#input
			pInputText
			type="text"
			[attr.maxlength]="maxLength"
          	[(ngModel)]="value"
			(keydown)="restrictCharacters($event)"
			(ngModelChange)="onChange($event)"
			(blur)="blur($event)"
		>
	`,
})
export class NumberComponent implements OnInit {
	@Input() public type: NumberType = "int";
	@Input() public wholeMaxLength?: number;
	@Input() public decimalMaxLength?: number;
	@Input() public maxLength?: number;
	@Input() public withEmptyNull = false;
	@Input() value?: string;
	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("input") public inputElement?: unknown;

	public isDotRemoved = true;
	private previousValue = "";
	private ctrl = false;

	public ngOnInit(): void {
		this.previousValue = this.value!;
	}

	public blur(event: any): void {
		const focuseValue = event.target.value;
		if (typeof focuseValue === "string" && !Boolean(focuseValue.split(",")[1] || focuseValue.split(".")[1])) {
			const clearFocuseValue = (String(focuseValue).split(".") || String(focuseValue).split(","))[0];
			this.valueChange.emit(clearFocuseValue);
		}
	}

	public restrictCharacters(event: KeyboardEvent) {
		const inputKey = event.key;
		const targetValue = event?.target as any;
		if (inputKey === "Control") {
			this.ctrl = true;
		}
		const pattern = /^[0-9]+(\.[0-9]+)?$/;
		const floatPattern = /^[0-9.,]*$/;
		if (inputKey === "Enter") {
			if (!pattern.test(targetValue.value) || !floatPattern.test(targetValue.value)) {
				this.value = undefined;
			}
			this.valueChange.next(this.value?.trim());
			event.preventDefault();
		}
		if (this.ctrl && inputKey === "v") {
			return;
		}
		if (inputKey.length === 1 && this.type === "int" && !pattern.test(inputKey)) {
			event.preventDefault();
		}

		if (inputKey.length === 1 && this.type === "float" && [".", ","].includes(inputKey) && this.value?.includes(".")) {
			event.preventDefault();
		}
		
		if (inputKey.length === 1 && this.type === "float" && !floatPattern.test(inputKey)) {
			event.preventDefault();
		}
	}

	public onChange(event: any): any {
		if (this.type === "float" && typeof event === "string" && Boolean(event.split(",")[1] || event.split(".")[1])) {
			this.valueChange.emit(event);
		}
		if (this.type === "int") {
			this.valueChange.emit(event);
		}
		
		// this.valueChange.emit(event);
		if (this.type === "float")
			this.inputValidatorFloat(event);
		else
			this.inputValidatorInt(event);
	}

	public inputValidatorInt(event: any): void {
		const pattern = /^[0-9]*$/;
		if (!pattern.test(event)) {
			this.value = event
			this.valueChange.emit(event);
		} else {
			this.previousValue = event;
		}
	}

	public inputValidatorFloat(event: any): void {
		const withDot = event.indexOf(".") !== -1;
		const withComma = event.indexOf(",") !== -1;
		if ((withDot || withComma) && this.isDotRemoved && this.maxLength) {
			this.maxLength++;
			this.isDotRemoved = false;
		} else if (!withDot && !withComma && !this.isDotRemoved && this.maxLength) {
			this.maxLength--;
			this.isDotRemoved = true;
		}
		if (this.wholeMaxLength && this.decimalMaxLength && this.maxLength) {
			const decimalPattern = new RegExp(`^\\d{0,${this.wholeMaxLength}}\\.{1}\\d{0,${this.decimalMaxLength}}$`);
			const decimalCommaPattern = new RegExp(`^\\d{0,${this.wholeMaxLength}}\\,{1}\\d{0,${this.decimalMaxLength}}$`);
			const pattern = /^[0-9.,]*$/;

			if (event.length <= this.maxLength && event.indexOf(".") === -1 && event.indexOf(",") === -1) {
				if (!pattern.test(event)) {
					event = this.previousValue;
				}
        		this.valueChange.emit(event.replace(",", "."));
				this.previousValue = event;
				return;
			}

			if (event === "." || event === ",") {
				event = "0" + event.slice(0, event.length);
			}

			if (!decimalPattern.test(event) && !decimalCommaPattern.test(event)) {
				event = event.slice(0, event.length - 1);
			}
			if (event === "" && this.withEmptyNull)
				event = null;
		}
		this.previousValue = event;
		this.value = this.previousValue;
		(this.inputElement as ElementRef).nativeElement.value = this.value;
    	this.valueChange.emit(this.previousValue.replace(",", "."));
	}
}
