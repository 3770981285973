import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, throttleTime } from "rxjs/operators";
import { createSelector, select, Store } from "@ngrx/store";
import { OverlayService } from "@core/overlay.service";
import { MarkingCode, MarkingCodesList } from "@app/user/marks/marks-store/marks-types";
import { UserState } from "@app/user/user.reducer";
import { DocumentProperty } from "@helper/abstraction/documents";
import { TemplateUtil } from "@helper/template-util";
import { notNull } from "@helper/operators";
import { pluralize } from "numeralize-ru";

export interface MassAction {
	action: string;
	markingCodes: MarkingCodesList;
}
@Component({
	selector: "app-tree-maker",
	templateUrl: "./tree-maker.component.html",
	styleUrls: ["./tree-maker.component.scss"]
})
export class TreeMakerComponent {
	@Input() public data$?: Observable<MarkingCodesList | any>;
	@Input() public withoutCheckBox = false;
	@Input() public withSelectedItemsBlock = false;
	@Input() public withoutFooter = false;
	@Input() public fromEwaybill = false;
	@Input() public properties?: DocumentProperty[];
	@Input() public selectedItems: MarkingCodesList = [];
	@Input() public stopScrolling = false;
	@Input() public filter: any; // filter from the parent component
	public gtin = undefined;
	@ViewChild("texts", { static: false })
	public set textsTemplate(template: ElementRef) {
		this.texts = TemplateUtil.getMap(template.nativeElement);
	}
	public texts: Map<string, string> = new Map<string, string>();
	public pending$?: Observable<boolean | undefined>;
	public popupPending$?: Observable<boolean | undefined>;
	public pluralize = pluralize;

	@Output() public massActions: EventEmitter<MassAction> = new EventEmitter<MassAction>();

	@Output() public showMore: EventEmitter<number> = new EventEmitter<number>();
	@Output() public selectedItemsList: EventEmitter<MarkingCodesList> = new EventEmitter<MarkingCodesList>();

	public allData?: MarkingCodesList;
	public treeData?: MarkingCodesList;
	public isIndeterminateCheckBox = false;
	public totalElements?: number;

	private scroll$: Subject<HTMLElement> = new EventEmitter<HTMLElement>();
	private masterItem?: MarkingCode;
	private unsubscribe$$ = new Subject<void>();
	private throttleTime = 300;

	constructor(
		private overlayService: OverlayService,
		private changeDetector: ChangeDetectorRef,
		private store: Store
	) {
		const selectUserState = (appState: any): UserState => appState.user;
		const getPending = createSelector(selectUserState, (state: UserState): boolean | undefined => state.pending);
		this.pending$ = this.store.pipe(select(getPending));
		const getPopupPending = createSelector(selectUserState, (state: UserState): boolean | undefined => state.popupPending);
		this.popupPending$ = this.store.pipe(select(getPopupPending));
	}

	public ngOnInit(): void {
		this.data$?.pipe(
			notNull(),
			map(data => {
				this.totalElements = data.totalElements;
				const dataMap = this.mapDocumentsList(data);
				return this.fromEwaybill ? this.updateDataListForEwaybillView(dataMap, false) : this.updateDataList(dataMap, false);
			}),
			takeUntil(this.unsubscribe$$)
		).subscribe(res => {
			this.isIndeterminateCheckBox = false;
			this.treeData = this.filter?.page !== 1 ? this.treeData?.concat(res) : res;
			this.stopScrolling = !(res.length === this.filter?.size);
			this.allData = [];
			this.updateAllData();
			this.changeDetector.detectChanges();
		});
		this.setScrollSubscription();
	}

	public mapDocumentsList(data: any): any[] {
		return data?.content || [];
	}

	public setSelectedPage(selectedPage: number): void {
		this.filter.page = selectedPage;
		this.treeData = [];
		this.selectedItems = [];
		this.showMore.emit(selectedPage);
	}

	public selectItem(data: MarkingCodesList): void {
		this.selectedItems = data;
		this.selectedItemsList.emit(this.selectedItems);
		this.updateMastersCheckState();
		if (this.selectedItems.length) {
			this.processSelectedItems();
		}
	}

	public viewCodes(item: { action: string; markingCode: MarkingCode }): void {
		this.isIndeterminateCheckBox = true;
		this.massActions.emit({ action: item.action, markingCodes: [item.markingCode] });
	}

	public onScroll(container: any): void {
		if ((container.scrollTop === 0 || this.stopScrolling) || (this.totalElements && this.totalElements <= 50)) {
			return;
		}
		if ((container.scrollHeight - (container.clientHeight + container.scrollTop)) <= 50) {
			this.scroll$.next(container);
		}
	}

	public selectAll(state: boolean, data = this.treeData): void {
		data?.forEach(listItem => {
			if (listItem?.hasOwnProperty("checkState")) {
				listItem.checkState = state;
			}
			if (state) {
				this.selectedItems.push(listItem);
			}
			else {
				this.selectedItems.splice(this.selectedItems.indexOf(listItem), 1);
			}
			if (listItem.markCodeSet.length) {
				this.selectAll(state, listItem.markCodeSet);
			}
		});
		this.selectedItemsList.emit(this.selectedItems);
	}
	//#region  Get Functions

	public selectionListActions(actionName: string): void {
		switch (actionName) {
			case "aggregate": {
				this.openConfirmPopup(actionName, 0, this.aggregationConfirmView);
				break;
			}
			case "writeOff": {
				this.openConfirmPopup(actionName, 0, this.writeOfConfirmView);
				break;
			}
			case "disAggregate": {
				this.openConfirmPopup(actionName, 1, this.disaggregationConfirmView);
				break;
			}
			default: this.massActions.emit({ action: actionName, markingCodes: this.selectedItems });
		}
	}

	private openConfirmPopup(actionName: string, level = 0, isAllElementsHasOneLevel = false): void {
		if (!isAllElementsHasOneLevel) {
			const message = `${this.texts.get(actionName === "disAggregate" ? "disaggregationConfirmText" : "aggregationConfirmText")}` + `${this.texts.get("messageSecondPart")}`;
			this.overlayService.showConfirmation$(message).then((agree: boolean) => {
				if (!agree) {
					return;
				}
				this.massActions.emit({
					action: actionName, markingCodes: this.selectedItems.filter(el => {
						return level === 0? el.level === level: el.hasParent === true;
					})
				});
			});
			return;
		}
		this.massActions.emit({
			action: actionName,
			markingCodes: this.selectedItems.filter(el => {
				return level === 0? el.level === level: el.hasParent === true;
			})
		});
	}


	public get isAllElementsInStatusIssued(): boolean {
		return this.isAllElementsInStatus(15);
	}

	public get aggregationButtonView(): boolean {
		return this.isAllElementsInStatus(50) && this.isElementsHasParent();
	}

	public get writeOfButtonView(): boolean {
		return this.isAllElementsInStatus(50) && this.isElementsHasParent() && this.allElementsWithoutChildren;
	}

	public get disaggregationButtonView(): boolean {
		return this.isAllElementsInStatus(50) && (this.isElementsFromLevel(1) || this.isElementsFromLevel(0)?  this.isElementsHasParent(true): false);
	}

	public get aggregationConfirmView(): boolean {
		return this.isAllElementsInStatus(50) && this.isAllElementsFromLevel(0);
	}
	public get writeOfConfirmView(): boolean {
		return this.isAllElementsInStatus(50) && this.isAllElementsFromLevel(0) && this.allElementsWithoutChildren;
	}

	public get disaggregationConfirmView(): boolean {
		return this.isAllElementsInStatus(50) && this.isAllElementsHasParent();
	}

	private get allElementsWithoutChildren(): boolean {
		return !this.selectedItems.some(el => el.markCodeSet.length);
	}

	private isAllElementsInStatus(statusId: number): boolean {
		return !this.selectedItems.some(el => el.status.id !== statusId);
	}

	private isElementsFromLevel(level: number): boolean {
		return this.selectedItems.some(el => el.level === level);
	}

	private isElementsHasParent(hasParent = false): boolean {
		return this.selectedItems.some(el => el.hasParent === hasParent);
	}

	private isAllElementsFromLevel(level: number): boolean {
		return !this.selectedItems.some(el => el.level !== level);
	}

	private isAllElementsHasParent(hasParent = true): boolean {
		return !this.selectedItems.some(el => el.hasParent !== hasParent);
	}
	//#endregion

	private updateAllData(data = this.treeData, checkState?: boolean): void {
		data?.forEach(el => {
			if (!this.allData?.some(item => JSON.stringify(item) === JSON.stringify(el))) this.allData?.push({ ...el, checkState: checkState || el.checkState });
			if (el.markCodeSet?.length) {
				this.updateAllData([...this.updateDataList(el.markCodeSet, checkState, el.id)]);
			}
		});
	}

	private updateDataList(data = this.treeData, checkState?: boolean, masterId?: number, level = 0): any {
		let dataResult: any = [];
		dataResult = data?.map((el: any) => {
			let dataItem = { ...el };
			if (el.markCodeSet?.length) {
				dataItem.markCodeSet = [...this.updateDataList(el.markCodeSet, checkState, el.id, level + 1)];
			}
			if (level < 2) {
				dataItem = {
					...dataItem,
					checkState: this.selectedItems?.find(el => el.id === dataItem.id)
						? true
						: typeof checkState === "boolean" ? checkState : dataItem.checkState,
				};
			}
			return {
				...dataItem,
				masterId,
				level
			};
		});
		return dataResult;
	}

	private updateDataListForEwaybillView(data = this.treeData, checkState?: boolean): any {
		let dataResult: any = [];
		dataResult = data?.map((el: any) => {
			if (!this.gtin){
				this.gtin = el.markCodeSet.length? el.markCodeSet[0].gtin : el.gtin;
			}

			const dataItem = { ...el };
			return {
				...dataItem,
				markCodeSet: [],
				checkState: this.selectedItems?.find(el => el.id === dataItem.id)
					? true
					: typeof checkState === "boolean" ? checkState : dataItem.checkState,
				masterId: undefined,
				level: 0,
			};
		});
		return dataResult;
	}

	private updateMastersCheckState(): void{
		this.treeData?.forEach(el => {
			if (el.checkState !== false) {
				el.checkState = this.getMasterCheckState(el);
			}
		});
	}

	private processSelectedItems(): void{
		this.selectedItems.forEach(item => {
			if (item.masterId) {
				this.masterItem = this.treeData?.find(el => el.id === item.masterId);
				if (!this.masterItem) { return; }
				this.masterItem.checkState = this.getMasterCheckState(this.masterItem);
			}
		});
	}

	private getMasterCheckState(master: MarkingCode): boolean | undefined {
		let selectedChildCount = 0;
		master.markCodeSet.forEach(child => {
			if (child.checkState) {
				selectedChildCount++;
			}
		});

		return master.markCodeSet.length === selectedChildCount || this.selectedItems.some(el => el.id === master.id)
			? true
			: selectedChildCount > 0
				? undefined
				: false;
	}

	private setScrollSubscription(): void {
		this.scroll$
			.pipe(
				throttleTime(this.throttleTime),
				takeUntil(this.unsubscribe$$)
			).subscribe(() => {
				this.stopScrolling = true;
				this.showMore.emit();
			});
	}
}
