<ng-container [ngSwitch]="description">
	<div *ngSwitchCase="'REQUEST_PROCESSING'">Обработка запроса</div>
	<div *ngSwitchCase="'SAVE'">Сохранение</div>
	<div *ngSwitchCase="'SEND'">Отправка</div>
	<div *ngSwitchCase="'SIGN'">Подписание</div>
	<div *ngSwitchCase="'DELETE'">Удаление</div>
	<div *ngSwitchCase="'CANCEL'">Отмена</div>
	<div *ngSwitchCase="'CREATE_REGISTRY'">Формирование реестра</div>
	<div *ngSwitchCase="'CHECK_VERSION'">Проверка версии <i>SC-CryptoProxy</i></div>
	<div *ngSwitchCase="'CREATE_INVITATION'">Оформление приглашений</div>
	<div *ngSwitchCase="'EDIT_INVITATION'">Редактирование приглашения</div>
	<div *ngSwitchCase="'GET_MARKS_PARAMS'">Получение параметров для маркировки</div>
	<div *ngSwitchCase="'LOADING_FILE'">Загрузка файла</div>
	<div *ngSwitchCase="'SC_CRYPTO_ANSWER'">Ожидание ответа SC-CryptoProxy</div>
	<div *ngSwitchCase="'AGGREGATION'">Агрегирование</div>
	<div *ngSwitchCase="'DISAGGREGATION'">Дезагрегирование</div>
	<div *ngSwitchCase="'WRITE_OF'">Списание</div>
	<div *ngSwitchCase="'SAVE_FILE'">Сохранение файла</div>
	<div *ngSwitchDefault>
		<ng-content></ng-content>
	</div>
</ng-container>

<div class="sk-fading-circle">
	<div class="sk-circle1" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle2" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle3" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle4" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle5" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle6" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle7" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle8" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle9" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle10" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle11" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
	<div class="sk-circle12" [ngClass]="isBlackTheme? 'sk-black-circle': 'sk-circle'"></div>
</div>
