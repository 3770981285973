
<div class="container">
	<header>
		<h3 class="text"> Отчет об импорте </h3>
		<app-icon (click)="close()">close</app-icon>
	</header>

	<main *ngIf="report">
		<section>
			<table class="short-info">
				<tbody>
				<tr>
					<td i18n>Всего:</td>
					<td>{{ report?.allCount || 0 }}</td>
				</tr>
				<tr>
					<td i18n>Импортировано:</td>
					<td>{{ report?.successfulCount || 0 }}</td>
				</tr>
				<tr>
					<td i18n>Не импортировано:</td>
					<td>{{ report?.failedCount || 0 }}</td>
				</tr>
				</tbody>
			</table>
		</section>
		<section class="additional" *ngIf="tradeItemList.length > 0">
			<button (click)="openTradeItemList = !openTradeItemList">{{openTradeItemList ? "Свернуть" : "Подробнее"}}</button>
			<div class="table-container">
				<table *ngIf="openTradeItemList">
					<thead>
					<th i18n>Код</th>
					<th i18n>Текст ошибки</th>
					</thead>
					<tbody>
					<tr *ngFor="let error of tradeItemList">
						<td>{{ error?.gtin }}</td>
						<td>{{ error?.errorDescription }}</td>
					</tr>
					</tbody>
				</table>
			</div>
		</section>
	</main>
	<footer>
		<button i18n type="button" appSkin="simple no-shadow bold bordered" (click)="close()">Закрыть</button>
	</footer>
</div>
