import { Component, OnChanges, Input, EventEmitter, Output } from "@angular/core";
import { trigger, transition, style, animate, keyframes } from "@angular/animations";

@Component({
	selector: "app-pagination",
	templateUrl: "./pagination.component.html",
	styleUrls: ["./pagination.component.scss"],
	animations: [
		trigger("pagesCarouselAnimation", [
			transition("void => *", [
				animate("0.3s",
					keyframes([
						style({ opacity: 0, offset: 0, display: "none" }),
						style({ opacity: 0, offset: 0.4, display: "block" }),
						style({ opacity: 1, offset: 1 }),
					]))
			]),
			transition("* => void", [
				animate("0.3s", style({ opacity: 0 }))
			])
		])
	]
})
export class PaginationComponent implements OnChanges {
	@Input() public filter: any;
	@Input() public totalElements?: number;
	@Input() public page?: number;
	@Output() public selectedPage$ = new EventEmitter<number>();

	public totalPages?: number;
	public currentPage?: number;
	public pages: number[] = [];
	public portionSize = 3;
	public totalPortions?: number;
	public portionNumber?: number;
	public leftPortionPageNumber?: number;
	public rightPortionPageNumber?: number;
	public currentPagesPortion: number[] = [];

	public ngOnChanges(): void {
		if (!this.totalElements || !this.page || !this.portionSize) {
			return;
		}
		this.currentPage = this.page;
		this.totalPages = Math.ceil(this.totalElements / this.filter?.size);
		this.setPagesArray();
		this.totalPortions = Math.ceil(this.totalPages / this.portionSize);
		this.portionNumber = Math.ceil(this.currentPage / this.portionSize);
		this.leftPortionPageNumber = (this.portionNumber - 1) * this.portionSize + 1;
		this.rightPortionPageNumber = this.portionNumber * this.portionSize;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.currentPagesPortion = this.pages.filter(p => p >= this.leftPortionPageNumber! && p <= this.rightPortionPageNumber!);
	}

	public setPagesArray(): void {
		if (!this.totalPages) {
			return;
		}
		if (!this.pages.length) {
			for (let i = 1; i <= this.totalPages; i++) {
				this.pages.push(i);
			}
		}
	}

	public setPortion(portion?: number): void {
		if (!this.currentPage) {
			return;
		}
		this.portionNumber = portion || Math.ceil(this.currentPage / this.portionSize);
		this.leftPortionPageNumber = (this.portionNumber - 1) * this.portionSize + 1;
		this.rightPortionPageNumber = this.portionNumber * this.portionSize;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.currentPagesPortion = this.pages.filter(p => p >= this.leftPortionPageNumber! && p <= this.rightPortionPageNumber!);
	}

	public setPage(page: number): void {
		this.currentPage = page;
		this.setPortion();
		this.selectedPage$.emit(page);
	}
}
