import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { of, Subject } from "rxjs";
import { delay, filter, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "app-list",
	templateUrl: "./list.component.html",
	styleUrls: ["./list.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements AfterViewInit {
	@Input() public showSearch = true;
	@Input() public data: [any, string][] = [];
	@Input() public fieldName?: string;
	@Input() public autoStopScroll?: boolean;
	@Input() public newStyle = false;
	@Output() public appInput = new EventEmitter<string>();
	@Output() public appSelect = new EventEmitter<[any, string]>();
	@Output() public appScrolled = new EventEmitter<void>();
	@ViewChild("input", { static: true }) public input?: ElementRef<HTMLInputElement>;
	public list: [any, string][] = [];
	public scroll$: Subject<HTMLElement> = new EventEmitter<HTMLElement>();
	public input$: Subject<string> = new EventEmitter<string>();
	public unsubscribe$$ = new Subject<void>();
	private stopScrolling?: boolean;

	public ngOnInit(): void {
		this.scroll$.pipe(
			filter(() => !this.stopScrolling),
			switchMap(el => of(el).pipe(delay(100))),
			takeUntil(this.unsubscribe$$)
		).subscribe(() => {
			this.appScrolled.emit();
		});
		this.input$.pipe(
			switchMap(el => of(el).pipe(delay(300))),
			takeUntil(this.unsubscribe$$)
		).subscribe((value) => {
			value = value.toLocaleLowerCase();
			if (value !== "") {
				this.list = this.data.filter(item => item[1].toLocaleLowerCase().includes(value));
			} else {
				this.list = this.data.slice(0);
			}
			this.appInput.emit(value);
		});
	}
	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data) {
			this.list = this.data.slice(0);
		}
		if (JSON.stringify(simpleChanges.data.currentValue) === JSON.stringify(simpleChanges.data.previousValue) && this.autoStopScroll) {
			this.stopScrolling = this.autoStopScroll;
		}
	}

	public ngAfterViewInit(): void {
		this.input && this.input.nativeElement.focus();
	}

	public onInput(value: string): void {
		this.input$.next(value);
	}

	public select(item: [any, string]): void {
		this.appSelect.emit(item);
	}

	public onScroll(scrollHeight: number, scrollTop: number, height: number): void {
		if (scrollTop === 0) {
			return;
		}
		if ((scrollHeight - (height + scrollTop)) <= 60) {
			this.scroll$.next();
		}
	}
}
