<div class="form">
	<label *ngIf="labelText" class="required" i18n>{{labelText}}</label>
	<div class="tooltipBlock">
		<ng-container [ngSwitch]="elementType">
			<div *ngSwitchCase="'textbox'">
				<input #input [class.error]="showError"
					[attr.title]="showTitle ? value : ''"
					[style.width]="blockWidth"
					[attr.maxlength]="maxLength" placeholder="{{placeholder}}"
					[attr.disabled]="disabled"
          type="text" [value]="value" (input)="onInput(input.value)" >
			</div>
			<div *ngSwitchCase="'select'"></div>
			<div *ngSwitchCase="'select-box-self-fetch'">
				<app-select-box-self-fetch
					[style.width]="blockWidth"
					class="small-height"
					[fieldName]="fieldName"
					[formControl]="control"
					[isDisabled]="disabled === ''"
					[option]="data">
				</app-select-box-self-fetch>
			</div>
			<div *ngSwitchCase="'multi-select'">
				<app-multiselect-box-self-fetch
					[style.width]="blockWidth"
					class="small-height"
					[formControl]="control"
					[isDisabled]="disabled === ''"
					[withoutPresetData]="withoutPresetData"
					[option]="data"
					[valueTransformFn]="valueTransformFn">
				</app-multiselect-box-self-fetch>
			</div>
			<div *ngSwitchDefault>
				<ng-content></ng-content>
			</div>
		</ng-container>
		<app-icon #tooltipElement *ngIf="showError || showTooltip"
			[appTooltip]="tooltip? tooltip : emailTooltip"
			[isDisable]="false"
			[appTooltipStyle]="position === 'bottom'? false : true"
			[position]="position">
				error
		</app-icon>
	</div>
</div>

<ng-template #emailTooltip>
	<div class="tooltip" i18n>
		Введите адрес электронной почты в формате: <локальная_часть>@<доменное_имя>
		<ul>
			<li class="mainLi">
				<ЛОКАЛЬНАЯ_ЧАСТЬ> может иметь длину не более 64 символов и включать:
				<ul>
					<li>
						латинские буквы;
					</li>
					<li>
						цифры;
					</li>
					<li>
						точку;
					</li>
					<li>
						дефис;
					</li>
					<li>
						нижнее подчеркивание.
					</li>
				</ul>
				Однако точка не должна повторяться и быть первым или последним символом.
			</li>
			<li class="mainLi">
				<ДОМЕННОЕ_ИМЯ>  может иметь длину не более 70 символов и включать:
				<ul>
					<li>
						латинские буквы;
					</li>
					<li>
						цифры;
					</li>
					<li>
						точку;
					</li>
					<li>
						дефис.
					</li>
				</ul>
				Однако дефис не должен быть первым или последним символом.
			</li>
		</ul>
	</div>
</ng-template>
