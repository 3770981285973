/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ChangeDetectorRef, ComponentFactory, ComponentFactoryResolver, Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { TooltipComponent } from "./tooltip.component";

@Directive({
	selector: "[appTooltip]",
})
export class TooltipDirective {
	@Input() public appTooltip: TemplateRef<any> | undefined;
	@Input() public offset?: number;
	@Input() public isDisable?: boolean = true;
	@Input() public position = "top";
	@Input() public appTooltipStyle?: boolean;

	private tooltipComponentFactory: ComponentFactory<TooltipComponent>;
	private isVisible = false;
	constructor(
		private readonly elementRef: ElementRef,
		private readonly viewContainerRef: ViewContainerRef,
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {
		const conf = {
			position: "relative"
		};
		Object.assign(this.elementRef.nativeElement.style, conf);
		this.tooltipComponentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
	}

	@HostListener("mouseenter")
	public showTooltip() {
		if (!this.isDisable && !this.isVisible) {
			const parentHeight = (this.elementRef.nativeElement.clientHeight || 0) + (this.elementRef.nativeElement.marginTop || 0);
			const component = this.viewContainerRef.createComponent(this.tooltipComponentFactory);
			component.instance.position = this.position;
			component.instance.appTooltipStyle = this.appTooltipStyle;
			if(this.appTooltip)component.instance.templateRef = this.appTooltip;
			component.instance.parentHeight = parentHeight;
			component.instance.offset = this.offset;
			this.isVisible = true;
			this.changeDetectorRef.detectChanges();
		}
	}

	@HostListener("mouseleave")
	public removeTooltip() {
		this.viewContainerRef.clear();
		this.isVisible = false;
	}

	public disableTooltip() {
		this.isDisable = true;
	}

	public enableTooltip() {
		this.isDisable = false;
	}
}
