import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, of, Subject } from "rxjs";
import { delay, switchMap, takeUntil } from "rxjs/operators";

import { DocumentProperty } from "@helper/abstraction/documents";

@Component({
	selector: "app-table-in-select",
	templateUrl: "./table-in-select.component.html",
	styleUrls: ["./table-in-select.component.scss"]
})
export class TableInSelectComponent {
	@Input("requestObs") public request$?: Observable<any>;
	@Input() public headers: DocumentProperty[] = [];
	@Input() public setFilterValue?: string;

	@Output() public filterChange = new EventEmitter<string>();
	@Output() public showMore = new EventEmitter<void>();
	@Output() public selectItem = new EventEmitter<any>();
	@Output() public closeTable = new EventEmitter<void>();
	public filterForm?: FormGroup;
	public list: any[] = [];
	public pending = false;
	private scroll$: Subject<HTMLElement> = new EventEmitter<HTMLElement>();
	private unsubscribe$$ = new Subject<void>();

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private formBulder: FormBuilder
	) {
		this.filterForm = this.formBulder.group({
			filterControl: null
		});
	}

	public ngOnInit(): void {
		this.request$?.pipe(takeUntil(this.unsubscribe$$)).subscribe(list => {
			this.list = list;
			this.pending = false;
			this.changeDetectorRef.detectChanges();
		});

		this.scroll$.pipe(
			switchMap(el => of(el).pipe(delay(100))),
			takeUntil(this.unsubscribe$$)
		).subscribe(() => {
			this.showMore.emit();
			this.pending = true;
		});

		this.filterForm?.get("filterControl")?.valueChanges
			.pipe(
				switchMap(el => of(el).pipe(delay(500))),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe(value => {
				this.pending = !!value;
				this.filterChange.emit(value);
			});

		if(this.setFilterValue && this.setFilterValue !== ""){
			this.filterForm?.patchValue({
				filterControl: this.setFilterValue
			}, { emitEvent: true });
		}
	}

	public isShowClearButton(): boolean {
		return !Object.values(this.filterForm?.value).every(value => value === null || value === "");
	}

	public resetFilter(): void {
		this.filterForm?.reset();
		this.filterForm?.updateValueAndValidity();
	}

	public select(item: any): void {
		this.selectItem.emit(item);
	}

	public onScroll(scrollHeight: number, scrollTop: number, height: number): void {
		if (scrollTop === 0) {
			return;
		}
		if ((scrollHeight - (height + scrollTop)) <= 60) {
			this.scroll$.next();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
		this.resetFilter();
	}

	public close(): void {
		this.closeTable.emit();
	}
}
