{{ textValue }}
<div (appClickOutside)="onClickOutside()">
	<input type="text" [class.error]="showError" (click)="expand ? collapse() : unfold()" [title]="selected ? selected[selectedArrayIdx] : ''"
		[value]="(selected && selected[selectedArrayIdx]) || presetData[0][1]" readonly="readonly" [attr.class]="expand ? 'expand': null"
		placeholder="{{placeholder}}" [disabled]="isLoadData">
	<app-icon *ngIf="!isLoadData" (click)="expand ? collapse() : unfold()">unfold_more</app-icon>
	<div *ngIf="isLoadData" class="loading">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
				style="margin: auto; background: rgba(0, 0, 0, 0); display: block;" width="45px" height="45px" viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid">
					<circle cx="50" cy="50" fill="none" stroke="rgb(0, 102, 209)" stroke-width="7" r="32" stroke-dasharray="150.79644737231007 52.26548245743669" transform="rotate(334.662 50 50)">
						<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9900990099009901s" values="0 50 50;360 50 50" keyTimes="0;1">

						</animateTransform>
					</circle>
			</svg>
	</div>

	<app-list
		*ngIf="expand && !isLoadData" [data]="data" [fieldName]="fieldName" [class]="listClass" [showSearch]="showSearch"
		(appInput)="input$$.next($event)" (appSelect)="select($event)" (appScrolled)="onScrolled()">
	</app-list>
</div>

<template #preset>
	<dl>
		<dt json>null</dt>
		<dd i18n>Нет значения</dd>
	</dl>
</template>
