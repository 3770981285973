import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public icon?: string = "pi-info-circle";
  public successButtonName = "OK";
  public cancelButtonName = "Закрыть";
  public message?: string;
  public checkedStatus = false;
  public showStatus = false;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.config.header = "Предупреждение";
  }

  public ngOnInit(): void {
    this.setData();
  }

  public setData(): void {
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
  }

  public close(successButtonHandle?: boolean): void {
    this.ref.close({ successButtonHandle, checked: this.checkedStatus });
  }
}
