import { AbstractControl, ControlContainer, FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, TemplateRef, Type, ViewChild } from "@angular/core";
import { TooltipDirective } from "@shared/tooltip/tooltip.directive";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

export type ElementType = "textbox" | "select" | "select-box-self-fetch" | "multi-select";
@Component({
	selector: "app-element-with-tooltip",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<ElementWithTooltipComponent> => ElementWithTooltipComponent)
	}],
	templateUrl: "./element-with-tooltip.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ["./element-with-tooltip.component.scss"]
})
export class ElementWithTooltipComponent extends BoxValueAccessor implements OnInit, OnDestroy{
	@ViewChild("tooltipElement", { read: TooltipDirective, static: false }) public tooltipElement: TooltipDirective | undefined;
	@Input() public labelText?: string;
	@Input() public position? = "top";
	@Input() public blockWidth?: string;
	@Input() public placeholder?: string;
	@Input() public fieldName?: string;
	@Input() public showTooltip? = false;
	@Input() public withoutPresetData? = false;
	@Input() public maxLength?: number;
	@Input() public error = false;
	@Input() public elementType?: ElementType;
	@Input() public tooltip?: TemplateRef<HTMLElement>;
	@Input() public formControl?: any;
	@Input() public formControlName?: string;
	@Input() public data: [] = [];
	@Input() public set isDisabled(value: boolean) {
		this.disabled = value ? "" : null;
	}
	@Input() public valueTransformFn?: (value: any) => any;

	@Output() public changeValue: EventEmitter<string> = new EventEmitter<string>();

	public control?: AbstractControl | null;
	public unsubscribe$$ = new Subject<void>();
	public showError = false;
	public disabled: "" | null = null;

	constructor(
		private fb: FormBuilder,
		public readonly changeDetectorRef: ChangeDetectorRef,
		public readonly controlContainer: ControlContainer
	){
		super();
	}

	public ngOnInit(): void{
		if(this.formControl || this.formControlName){
			if (this.formControl)
				this.control = this.formControl;

			if (this.controlContainer && this.formControlName && this.controlContainer.control)
				this.control = this.controlContainer.control.get(this.formControlName);

			this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
				if (!this.error)
					this.showError = this.control && this.control.touched && this.control.invalid || false;
				else
					this.showError = this.error;
				this.changeDetectorRef.detectChanges();
			});
		}
	}

	public writeValue(value: string): void {
		this.value = value;
		this.changeDetectorRef.markForCheck();
	}

	public onInput(newValue: string): void {
		this.writeValue(newValue);
		if (this.onChange)
			this.onChange(newValue);
		if (this.onTouched)
			this.onTouched();
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.changeDetectorRef.markForCheck();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
