<section>
	<div class="main">
		<header>
			<label i18n>Подключение к ГИС "Электронный знак"</label>
			<app-icon (click)="close()">close</app-icon>
		</header>

		<div class="form" [formGroup]="form">
			<label i18n>Логин:</label>
			<app-textbox formControlName="username"></app-textbox>
			<label i18n>Пароль:</label>
			<app-passwordbox formControlName="password"></app-passwordbox>
		</div>
	</div>

	<footer>
		<button appSkin="action" class="green" (click)="bindSymbol()">Привязать</button>
		<button appSkin="simple-cancel" (click)="close()">Отмена</button>
	</footer>
</section>

<app-pending *ngIf="(pending$ | async)"></app-pending>

<app-overlay></app-overlay>
