<div (appClickOutside)="expandList(false)">
	<main (click)="!isDisabled ? switchExpandState($event) : null" [class.error]="showError" [class.expand]="expand">
		<div class="container" [class.without-icon]="value?.length >= 2">
			<ng-container *ngIf="!value?.length">
				<div class="element">{{ placeholder }}</div>
			</ng-container>
			<div class="element" *ngFor="let el of value; let i = index; trackBy: trackByFn">
				<div [title]="fieldName === 'storages' ? '' : el ? valueTransformFn ? valueTransformFn(el) : el : ''">
					{{ valueTransformFn ? valueTransformFn(el) : "" }}
				</div>
				<app-icon *ngIf="!isDisabled" (click)="deleteItem(i, $event)">close</app-icon>

				<div *ngIf="fieldName === 'storages' && el" class="tooltip-text" (click)="$event.stopPropagation()">
					<span class="item-name">{{ el.storageName }}</span>
					<span class="item-address">{{ el.name || el.addressFull  }}</span>
					<span class="item-gln">{{ el.gln }}</span>
				</div>
			</div>
		</div>
		<app-icon [class.disable]="isDisabled" *ngIf="(!pending || data?.length) && value?.length < 2" (click)="switchExpandState($event)">{{ !expand? "unfold_more": "unfold_less" }}</app-icon>
		<div *ngIf="(pending && !data?.length) && value?.length < 2" class="loading">
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
					style="margin: auto; background: rgba(0, 0, 0, 0); display: block;" width="45px" height="45px" viewBox="0 0 100 100"
					preserveAspectRatio="xMidYMid">
						<circle cx="50" cy="50" fill="none" stroke="rgb(0, 102, 209)" stroke-width="7" r="32" stroke-dasharray="150.79644737231007 52.26548245743669" transform="rotate(334.662 50 50)">
							<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9900990099009901s" values="0 50 50;360 50 50" keyTimes="0;1">

							</animateTransform>
						</circle>
				</svg>
		</div>
	</main>

	<app-list
		*ngIf="expand" [data]="data" [fieldName]="fieldName" [showSearch]="showSearch" [class]="listClass"
		(appInput)="input$$.next($event)" (appSelect)="addItem($event)" (appScrolled)="onScrolled()">
	</app-list>
</div>

<template #preset>
	<dl>
		<dt json>null</dt>
		<dd i18n>Нет значения</dd>
	</dl>
</template>
