import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MnsManagementUserDto } from '@helper/abstraction/mns-management';
import { ValidatorsUtil } from '@helper/validators-util';
import { TemplateUtil } from '@helper/template-util';
import { DefaultPopupComponent } from '@shared/overlay/default-pop-up.component';

@Component({
  selector: 'app-process-password-popup',
  templateUrl: './process-password-popup.component.html',
  styleUrls: ['./process-password-popup.component.scss']
})
export class ProcessPasswordPopupComponent extends DefaultPopupComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public user?: MnsManagementUserDto;
	@Input() public mode?: "create" | "edit";
	public process: Subject<{ password: string, userId: number }> = new Subject<{ password: string, userId: number }>();
	@ViewChild("messagesErrors", { static: true }) public messagesErrors?: ElementRef<HTMLTemplateElement>;
	public validatorsUtil = ValidatorsUtil;
	public messages?: Map<string, string>;
	public form?: FormGroup;
	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(private formBuilder: FormBuilder) { super(); }

	public ngOnInit(): void {
		this.initForm();
	}

	public initForm(): void {
		this.form = this.formBuilder.group({
			login: [this.user?.login],
			psw: [null],
			pswConfirm: [null],
		});
	}

	public ngAfterViewInit(): void {
		if (!this.messagesErrors)
			throw Error("No messagesErrors");
		this.messages = TemplateUtil.getMap(this.messagesErrors.nativeElement);
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public save(): void {
		if (!this.form)
			throw Error("No form");
		const validators: ValidatorFn[] = [
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(32),
			this.validatorsUtil.noOneNumberCharacters(),
			this.validatorsUtil.noOneUppercaseLatinCharacters(),
			this.validatorsUtil.noOneLowercaseLatinCharacters(),
			this.validatorsUtil.onlyLatinOrSpecialCharactersAllowed()
		];
		this.form.controls["psw"].setValidators(validators);
		this.form.controls["pswConfirm"].setValidators(validators);
		this.form.setValidators([this.validatorsUtil.doNotMatch("psw", "pswConfirm")]);
		this.updateFormValueAndValidity();
		if (this.form.valid) {
			this.process.next({ password: this.form.get("psw")?.value, userId: this.user?.id! });
		}
	}

	private updateFormValueAndValidity(): void {
		if (!this.form)
			throw Error("No form");
		this.form.controls["psw"].updateValueAndValidity();
		this.form.controls["pswConfirm"].updateValueAndValidity();
		this.form.updateValueAndValidity();
	}
}
