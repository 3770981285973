<div class="container">
	<input *ngIf="showSearch" #input type="text" (input)="onInput(input.value)">
</div>
<ul *ngIf="fieldName !== 'storages'" #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)" [class.new-style-ul]="newStyle">
	<li *ngFor="let item of list" (click)="select(item)">{{item[1]}}</li>
</ul>
<ul *ngIf="fieldName === 'storages'" #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)">
	<li class="storage-block" *ngFor="let item of list" (click)="select(item)">
		<span *ngIf="item[0] === null; else storageInfo">{{item[1]}}</span>
		<ng-template #storageInfo>
			<span class="item-name">{{ item[0]?.storageName }}</span>
			<span class="item-address">{{ item[0]?.name || item[0]?.addressFull }}</span>
			<span class="item-gln">{{ item[0]?.gln }}</span>
		</ng-template>

	</li>
</ul>
