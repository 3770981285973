import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, Type } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DocumentProperty } from "@helper/abstraction/documents";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-select-goods",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<SelectGoodsComponent> => SelectGoodsComponent)
	}],
	templateUrl: "./select-goods.component.html",
	styleUrls: ["./select-goods.component.scss"]
})
export class SelectGoodsComponent extends BoxValueAccessor implements OnInit {
	//#region Input variables
	@Input() public placeholder?: string = "";
	@Input() public formControlName?: string;
	@Input() public displayValidation = true;
	@Input() public withoutSelection = false;
	@Input() public stopScrolling = false;
	@Input() public formControl?: AbstractControl;
	@Input() public error = false;
	@Input() public isRowView = false;
	@Input() public maxLength?: number;
	@Input() public set isDisabled(value: boolean) {
		this.disabled = typeof value === "boolean"? value : null; //todo: remove it
	}
	@Input() public set selectionFromList(value: boolean) {
		this.isValueFromList = value;
	}
	@Input() public reset$?: Observable<any>;
	//#endregion

	//#region Input variables for table view
	@Input() public headers: DocumentProperty[] = [];
	@Input("requestObs") public request$?: Observable<any>;
	@Input() public selectItemField?: string;
	//#endregion

	//#region public variables
	public disabled: boolean | null = null;
	public expand = false;
	public showError = false;
	public isValueFromList = false;
	public control?: AbstractControl | null;
	public unsubscribe$$ = new Subject<void>();
	//#endregion

	@Output() public appFilterChanges: EventEmitter<string> = new EventEmitter<string>();
	@Output() public appNextPage: EventEmitter<void> = new EventEmitter<void>();
	@Output() public appCollapse = new EventEmitter<void>();
	@Output() public selectedValue = new EventEmitter<any | undefined>();
	@Output() public setDisabledField: EventEmitter<void> = new EventEmitter<void>();
	private previousValue = "";

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		public readonly controlContainer: ControlContainer
	) {
		super();
		this.value = null;
	}

	public ngOnInit(): void {
		if (!this.displayValidation){
			return;
		}

		if (this.formControl){
			this.control = this.formControl;
		}

		if (this.controlContainer && this.formControlName && this.controlContainer.control){
			this.control = this.controlContainer.control.get(this.formControlName);
		}

		// Subscribe need to change control touched state
		this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error){
				this.showError = this.control && this.control.touched && this.control.invalid || false;
			} else{
				this.showError = this.error;
			}

			this.changeDetectorRef.detectChanges();
		});
		this.reset$?.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			this.setDisabledField?.next();
			this.resetField();
		});
	}

	public deleteItem(event: Event): void {
		event.stopPropagation();
		this.resetField();
	}

	public onScrolled(): void {
		if (!this.stopScrolling) {
			this.appNextPage.emit();
		}
	}

	public switchExpandState(event: Event): void {
		event.stopPropagation();
		if (this.disabled) {
			return;
		}
		this.expand = !this.expand;
		if (this.expand) {
			this.appCollapse.emit();
		}
	}

	public onClickOutside(): void {
		if (this.expand){
			this.expand = false;
		}
	}

	public handInput(event: any): void {
		if (this.isValueFromList) {
			this.isValueFromList = false;
		}
		if (this.expand) {
			this.expand = false;
		}
		const pattern = /^[0-9]*$/;
		if (!pattern.test(event.target.value)) {
			event.target.value = this.previousValue;
		} else {
			this.previousValue = event.target.value;
		}
		this.writeValue(event.target.value);
		if (this.onChange)
			this.onChange(event.target.value);
		if (this.onTouched)
			this.onTouched();
	}

	public onInput(value: string): void {
		this.appFilterChanges.emit(value);
	}

	public selectItem(item: any): void {
		this.value = this.selectItemField ? item[this.selectItemField] : item;
		this.selectedValue.emit(item);
		this.isValueFromList = true;
		this.changeAndTouch();
		this.expand = false;
		this.changeDetectorRef.markForCheck();
	}

	private resetField(): void {
		this.value = null;
		this.isValueFromList = false;
		this.selectedValue.emit(undefined);
		this.changeAndTouch();
		this.changeDetectorRef.markForCheck();
	}

	private changeAndTouch(): void {
		if (this.onChange) {
			this.onChange(this.value);
		}
		if (this.onTouched){
			this.onTouched();
		}
	}
}
