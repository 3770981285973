import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
	selector: "app-drop-error",
	templateUrl: "./drop-error.component.html",
	styleUrls: ["./drop-error.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropErrorComponent {
	@Input() public error?: string;
	@Input() public isTriangle = true;
}
