import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { OverlayRootService } from "@core/overlay-root.service";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { openInactionPopup } from "./app.actions";
import { InactionPopupComponent } from "./inaction-popup/inaction-popup.component";
import { UserBackendService } from "./user/user-core/services/user-backend.service";
import { getProfile } from "./mns/mns-store/mns.actions";
import { PostMessageService } from "@core/post-message.service";
import { UserNotifyPopupComponent } from "@shared/shared-popups/user-notify-popup/user-notify-popup.component";
import { PrimeNGConfig } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";

@Component({
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: "body",
	styleUrls: ["./app.component.scss"],
	templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(OverlayComponent, { static: true }) public overlayComponent?: OverlayComponent;
	@ViewChild("userNotification", { static: true }) public userNotification?: OverlayComponent;
	@ViewChild("inaction", { static: true }) public inaction?: OverlayComponent;
	public addNoscript = false;
	private unsubscribe$$ = new Subject<void>();

	constructor(
		private dialog: DialogService,
		private actions: Actions,
		private store: Store,
		private backendService: UserBackendService,
		private overlayRootService: OverlayRootService,
		private title: Title,
		private router: Router,
		private postMessageService: PostMessageService,
		private renderer: Renderer2,
		private primengConfig: PrimeNGConfig
	) {
		switch (environment.project) {
			case "BBV":
				title.setTitle("BBV");
				break;
			case "CTT":
				title.setTitle("EDI-система СТТ");
				break;
			case "BM":
				title.setTitle("Bidmart EDI");
				break;
			case "ME":
				title.setTitle("MetaEra.EDI");
				break;
			default:
				this.title.setTitle("EDI");
		}
		window.addEventListener("storage", this.listener, true);
	}

	public ngOnInit(): void {
		this.primengConfig.ripple = true;
		this.openInactionPopup();
		this.loadWidget();
		this.postMessageService.userNotification$$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe((data) => this.openUserNotification(data));
	}

	public ngAfterViewInit(): void {
		if (!this.overlayComponent)
			throw Error("No overlayComponent");
		this.overlayRootService.init(this.overlayComponent);
	}

	public openInactionPopup(): void {
		if (!!window.sessionStorage?.getItem("token.user.v1") && !!localStorage.getItem("timer_end")) {
			this.router.navigateByUrl(`${this.router.url.split("/")[1] === "gov" ? "gov" : "user"}/logout`);
			return;
		}
		this.actions.pipe(
			ofType(openInactionPopup),
			filter(() => !!window.sessionStorage?.getItem("token.user.v1")),
			takeUntil(this.unsubscribe$$)
		).subscribe(() => {
			const component = this.dialog.open(InactionPopupComponent, {
				data: { endDate: new Date().setMinutes(new Date().getMinutes() + 1) },
				closeOnEscape: false,
				showHeader: false,
				closable: false
			});
			component.onClose.pipe(takeUntil(this.unsubscribe$$)).subscribe(result => {
				if (result) {
					if (this.userNotification) {
						this.userNotification.destroy();
					}
					if (this.overlayComponent) {
						this.overlayComponent.destroy();
					}
					this.router.navigateByUrl(`${this.router.url.split("/")[1] === "gov" ? "gov" : "user"}/logout`);
				} else {
					this.router.url.split("/")[1] === "gov"
						? this.store.dispatch(getProfile())
						: this.backendService.user.profile.get$().pipe(take(1)).subscribe();
				}
			});
		});
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
		window.removeEventListener("storage", this.listener);
	}

	private loadWidget(): void {
		if (environment.project === "BM") {
			const script = this.renderer.createElement("script");
			this.renderer.setAttribute(script, "src", "https://lib.usedesk.ru/secure.usedesk.ru/widget_165134_48613.js");
			this.renderer.appendChild(document.body, script);
		}
	}

	private listener = (): any => {
		let url = this.router.url.toString().split('?');
		const isFirstPage = [
			"/registration",
			"/registration/activation",
			"/registration/status",
			"/registration/confirmation",
			"/registration/registration-done",
			"/registration-simple",
			"/registration-simple/status",
			"/registration-simple/confirmation",
			"/registration-simple/activation",
			"/gov/login",
			"/gov/restore",
			"/gov/recovering",
			"/gov/activation",
			"/user/login",
			"/user/restore",
			"/user/recovering",
			"/user/activation",
			"/user/invintation"].includes(url[0]);
		if (!window.sessionStorage?.getItem("token.user.v1") && !isFirstPage) {
			this.router.navigateByUrl(`${this.router.url.split("/")[1] === "gov" ? "gov" : "user"}/logout`);
		}
	};

	private openUserNotification(data?: any): void {
		const systemData = data;
		const component = this.userNotification?.show(UserNotifyPopupComponent, {
			inputs: { data: systemData },
			centerPosition: true
		});
		component?.instance.close$.pipe(takeUntil(this.unsubscribe$$)).subscribe((data) => {
			this.checkNotification(systemData.id, systemData.type);
			if (data) {
				this.overlayComponent?.showNotification$(data, "error");
			}
			this.userNotification?.destroy();
		});
	}

	private checkNotification(id: number, type?: string): void {
		this.backendService.notificationController.read.post$([id])
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => this.postMessageService.updateNotification$$.next(type === "SYS_INVITE"));
	}
}
