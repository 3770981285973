import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { Component, Input } from "@angular/core";

@Component({
	selector: "app-sccrypto-documents-popup",
	templateUrl: "./sccrypto-documents-popup.component.html",
	styleUrls: []
})
export class SccryptoDocumentsPopupComponent extends DefaultPopupComponent{
	@Input() public actualVersion = "";
}
