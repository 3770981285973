import { Component, Input } from "@angular/core";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";

@Component({
	selector: "app-user-debet-popup",
	templateUrl: "./user-debet-popup.component.html",
	styleUrls: ["./user-debet-popup.component.scss"]
})
export class UserDebetPopupComponent extends DefaultPopupComponent {
	@Input() public debetSum?: number;
}
