import { Injectable } from "@angular/core";
import { scanData } from "@helper/operators";
import { createSelector, select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class StoreService {
	public page?: number;
	private storeName = "";
	constructor(protected readonly store: Store) {}

	public selectFn = (appState: any): any => appState[this.storeName];

	public set storageName(name: string) {
		this.storeName = name;
	}

	public select$<T>(fn: (state: any) => any): Observable<T> {
		const selector = createSelector(this.selectFn, fn);
		return this.store.pipe(
			select(selector)
		);
	}

	public selectDictionariesFromStore$<T>(view: (text: T) => string, fn: (state: any) => any[] | undefined): Observable<[any, string][]> {
		const selector = createSelector(this.selectFn, fn);
		return this.store.pipe(
			select(selector),
			scanData<T>(),
			map((obj): [any, string][] => obj ? obj.map((p): [any, string] => ([p, view(p)])) : []),
		);
	}
}
