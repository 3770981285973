
<main #mainRef>
    <ul>
        <li>Уважаемый клиент, вы работаете в режиме ограниченного функционала.</li>
        <li>На вашем счете имеется задолженность или отсутствует предоплата.</li>
        <li>
            Для включения всех функций вам необходимо оплатить {{ debetSum | number : '1.2-2' || 0 }} бел. руб.
            и прислать копию платежного поручения через форму обращений <a href="https://ctt.by/support/" target="_blank">https://ctt.by/support/</a>,
            выбрав тему запроса "Блокировка доступа, оплата услуг".
        </li>
    </ul>
    <div>
        <button type="button" appSkin="simple-cancel" (click)="close()">Закрыть</button>
    </div>
</main>
