import { Subject } from "rxjs";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-notification-sequence",
	styleUrls: ["./notification-sequence.component.scss"],
	templateUrl: "./notification-sequence.component.html"
})

export class NotificationSequenceComponent implements OnChanges {
	public close$ = new Subject<void>();
	@Input() public style?: "hidden" |"show";
	@Input() public message?: string;
	@Input() public type: "error" | "warning" | "success" = "success";

	constructor(private cdr: ChangeDetectorRef) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if ("style" in changes){
			this.cdr.detectChanges();
		}
	}
}

