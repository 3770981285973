<p-table *ngIf="data && updateDependentFields(data)"
  [value]="data.editCells"
  dataKey="headerName"
  [scrollable]="true"
  scrollHeight="flex"
  (onEditComplete)="editInternalTableComplete(data)"
  scrollDirection="both"
  rowExpandMode="single"
  (onRowExpand)="expand($event, dt)">
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let header of headerMetadataTable">
        <th [style]="fieldWidth(header.width)">{{ header.headerName }}</th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contentRow let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
    <tr class="raw-cell" [class.expanded-raw]="expanded">
      <td [style]="fieldWidth(contentRow.width)">{{ contentRow.headerName }}</td>
      <ng-container *ngFor="let key of keys(contentRow?.fields)">
        <td
          *ngIf="contentRow.fields[key].editable && !isViewMode"
            [pEditableColumnRowIndex]="index"
            [pEditableColumn]="contentRow.fields" [pEditableColumnField]="key"
            [style]="fieldWidth(contentRow.fields[key]?.width)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <app-number
                [value]="data[key]"
                (valueChange)="editInputComplete($event, key)"
                [type]="contentRow.fields[key].numberType ?? 'float'"
                [wholeMaxLength]="contentRow.fields[key].wholeMaxLength"
                [decimalMaxLength]="contentRow.fields[key].decimalMaxLength"
                [maxLength]="contentRow.fields[key].maxLength">
              </app-number>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="fixed-cell" [tooltipDisabled]="checkTooltipLength(data[key])"
                pTooltip="{{ checkTooltipText(data[key]) }}" tooltipPosition="bottom">{{ data[key] || "-" }}</span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td *ngIf="(!contentRow.fields[key].editable || isViewMode) && !contentRow.fields[key].handleMarkCodes" [style]="fieldWidth(contentRow.fields[key]?.width)"
          [tooltipDisabled]="checkTooltipLength(contentRow.fields[key].objectFieldName && data[key] ? data[key][contentRow.fields[key].objectFieldName] : data[key])"
          pTooltip="{{ checkTooltipText(contentRow.fields[key].objectFieldName && data[key] ? data[key][contentRow.fields[key].objectFieldName] : data[key]) }}" tooltipPosition="bottom">
          <span class="overflow-ellips">
            {{ (contentRow.fields[key].objectFieldName && data[key] ? data[key][contentRow.fields[key].objectFieldName] : data[key]) || handleEmptyField(key) }}
          </span>
        </td>
        <td *ngIf="contentRow.fields[key].handleMarkCodes" class="discrepancies-btn">
            <button *ngIf="contentRow.fields[key].handleMarkCodesMode === 'EDIT' && getCodesLength(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]) === 0" pButton pRipple icon="pi pi-plus" type="button" label="" class="add-discrepancies-btn" [pRowToggler]="contentRow"></button>
            <button *ngIf="contentRow.fields[key].handleMarkCodesMode === 'VIEW' && checkExpandedRaw(contentRow.fields, getCodesLength(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]))" pButton pRipple type="button" label="Просмотр" class="add-discrepancies-btn" [pRowToggler]="contentRow"></button>
            <button *ngIf="contentRow.fields[key].handleMarkCodesMode === 'EDIT' && getCodesLength(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]) !== 0" pButton pRipple icon="pi pi-pencil" type="button" label="" class="add-discrepancies-btn" [pRowToggler]="contentRow"></button>
        </td>
      </ng-container>
      <td class="expand-button" style="width: 2rem">
        <button *ngIf="checkExpandedRaw(contentRow.fields, getCodesLength(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]))" type="button" pButton pRipple [pRowToggler]="contentRow" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-right'"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-contentRow>
    <tr styleClass="edit-raw-cell" class="{{ isOrderedList(contentRow) ? 'view-expanded-cell' : 'expanded-cell' }}">
      <td class="edit-raw">
        <div class="p-p-3" [@rowExpansionTrigger]="'active'">
          <div *ngIf="!isOrderedList(contentRow)" class="action-block">
            <div class="input-block">
              <label for="global-filter">Код маркировки</label>
              <span class="p-input-icon-left">
                <input class="p-inputtext-lg" pInputText type="text" id="global-filter" [(ngModel)]="markCode"/>
              </span>
            </div>
            <div class="button-block">
              <div class="button-action-block">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-success p-button-outlined" (click)="setMarkCode(markCode, contentRow)"></button>
                <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined" (click)="resetFilter()"></button>
              </div>
              <button pButton pRipple type="button" label="Импортировать коды" class="p-button-outlined p-button-secondary" (click)="importCodes(contentRow.fields)"></button>
            </div>
          </div>
          <div class="list-block">
            <div *ngIf="!isOrderedList(contentRow)" class="list-label">
              <label>Добавленные коды</label>
            </div>
            <div class="codes-list">
              <div class="codes-list-action-panel {{ isOrderedList(contentRow) ? 'view-action-panel' : '' }}">
                <button *ngIf="!isOrderedList(contentRow)" pButton pRipple type="button" label="Очистить все" class="p-button-outlined p-button-secondary" (click)="removeAll(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)])"></button>
                <span class="counter-codes">
                  <span class="counter-label">{{ isOrderedList(contentRow) ? 'Всего' : 'Добавлено' }}:</span>
                  {{ getCodesLength(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]) }}
                  {{ pluralize(getCodesLength(data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]), 'код', 'кода', 'кодов') }}
                </span>
              </div>
              <div class="codes-list-block">
                <ng-container *ngFor="let item of data.markCodeDtoList[0][getFiledListKey(contentRow.fields)]">
                  <ng-container *ngFor="let list of item.list">
                    <div class="codes-list-item-block">
                      <span class="codes-list-icon">
                        <button *ngIf="!isOrderedList(contentRow)" type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" [icon]="'pi pi-trash'" (click)="clearMarkCode(list, contentRow)"></button>
                      </span>
                      <span class="codes-list-item {{ isOrderedList(contentRow) ? 'view-item' : '' }}">{{ list }}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
