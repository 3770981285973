import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TemplateUtil } from "@helper/template-util";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-multiselect-box-with-checkbox",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<MultiSelectBoxWithCheckboxComponent> => MultiSelectBoxWithCheckboxComponent)
	}],
	styleUrls: ["./multiselect-box-with-checkbox.component.scss"],
	templateUrl: "./multiselect-box-with-checkbox.component.html"
})
export class MultiSelectBoxWithCheckboxComponent extends BoxValueAccessor implements OnChanges, OnInit {
	@Input() public data: [any, string][] | HTMLElement = [];
	@Input() public valueTransformFn?: (value: any) => string;
	@Input() public formControlName?: string;
	@Input() public displayValidation = true;
	@Input() public userStatusesList = false;
	@Input() public formControl?: AbstractControl;
	@Input() public error = false;
	@Input() public set otherEmptyValue(element: HTMLElement) {
		this.presetValues = TemplateUtil.getArray(element);
	}
	public presetValues: [any, string][] = [];
	public selectedElements: [any, string][] = [];
	public list: [any, string][] = [];
	public expand = false;
	public showError = false;
	public control?: AbstractControl | null;

	public unsubscribe$$ = new Subject<void>();
	@Output() public appFilterChanges: EventEmitter<{ search: string }> = new EventEmitter<{ search: string }>();
	@Output() public appNextPage: EventEmitter<void> = new EventEmitter<void>();
	@Output() public appCollapse = new EventEmitter<void>();
	private templateUtil = TemplateUtil;

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		public readonly controlContainer: ControlContainer
	) {
		super();
	}

	public ngOnInit(): void {
		if (!this.displayValidation)
			return;

		if (this.formControl)
			this.control = this.formControl;

		if (this.controlContainer && this.formControlName && this.controlContainer.control)
			this.control = this.controlContainer.control.get(this.formControlName);

		// Subscribe need to change control touched state
		this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error)
				this.showError = this.control && this.control.touched && this.control.invalid || false;
			else
				this.showError = this.error;

			this.changeDetectorRef.detectChanges();
		});
	}
	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data) {
			if (this.data instanceof Element || this.data instanceof HTMLDocument)
				this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.templateUtil.getArray(this.data as HTMLElement));
			else if (Array.isArray(this.data))
				this.list = this.presetValues.reduce((acc, value) => [value, ...acc], this.data.slice(0));
			else
				throw Error("Invalid data format");
		}
		if (this.value) {
			this.selectedElements = this.value;
		}
	}

	public switchExpandState(event: Event): void {
		event.stopPropagation();
		this.expand = !this.expand;
	}

	public readonly trackByFn = (index: number, item: Storage): any => item.id;

	public deleteItem(index: number, event: Event): void {
		event.stopPropagation();
		const arr = this.value ? [...this.value] : [];
		arr.splice(index, 1);
		this.value = arr;
		this.selectedElements = arr;
		if (this.onChange)
			this.onChange(this.value);

		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public addItems(itemsList: {id: any; name: string}[]): void {
		if (!this.value) {
			this.value = [];
		}
		if(itemsList.length){
			if(this.value){
				const arr = [...this.value];
				this.value = arr.filter(f => itemsList.includes(f));
			}

			itemsList.forEach(item => {
				const itemExist: boolean = this.value.some((el: any) => el.id === item.id);
				if (!itemExist) {
					const arr = this.value ? [...this.value] : [];
					arr.push(item);
					this.value = arr;
					this.selectedElements = arr;
				}
			});
		}
		else{
			this.value = [];
			this.selectedElements = [];
		}

		if (this.onChange)
			this.onChange(this.value);

		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public writeValue(value: any): void {
		if (!value) {
			this.value = [];
		} else {
			this.value = value;
		}

		if (this.onChange)
			this.onChange(this.value);
		if (this.onTouched)
			this.onTouched();

		this.changeDetectorRef.markForCheck();
	}

	public onScrolled(): void {
		this.appNextPage.emit();
	}

	public onClickOutside(): void {
		if (this.expand)
			this.collapse();
	}

	public collapse(): void {
		this.expand = false;
		this.appCollapse.emit();
	}

	public onInput(value: string): void {
		this.appFilterChanges.emit({ search: value });
	}
}

