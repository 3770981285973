import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appWindowResize]'
})
export class WindowResizeDirective {
  @Input() public set resizeValue(size: number) {
    this.resizeWidth = size;
    this.resize();
    this.changeDetector.detectChanges();
  }

  private resizeWidth: number = 1320;

  constructor(
    private el: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.resize();
  }

	@HostListener("window:resize", ["$event"])
	public onResize(): void {
    this.resize();
	}

  private resize(): void {
    this.el.nativeElement.style.display = window.innerWidth < this.resizeWidth ? "none" : "block";
  }
}
