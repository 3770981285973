import { Subject } from "rxjs";

import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory,
	ComponentFactoryResolver, ComponentRef, HostBinding, Injector, Input, OnChanges, OnDestroy,
	SimpleChange, SimpleChanges, Type, ViewChild, ViewContainerRef
} from "@angular/core";

import { ConfirmationPopupComponent } from "@shared/shared-popups/confirmation-popup/confirmation-popup.component";
import { takeUntil } from "rxjs/operators";
import { NotificationSequenceComponent } from "@shared/notification-sequence/notification-sequence.component";
import { environment } from "src/environments/environment";

// Возвращает тип параметров функции опуская первый параметр
type SkipFirstParametrs<T> = T extends (arg1: any, ...args: infer U) => any ? U : any;

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-overlay",
	styleUrls: ["./overlay.component.scss"],
	template: "<ng-template #anchor></ng-template>"
})
export class OverlayComponent implements OnDestroy {
	public notificationArray: ComponentRef<NotificationSequenceComponent>[] = [];
	@HostBinding("class.center") public isCenter = false;
	@HostBinding("class.custom-z-index") public zIndex = false;
	@ViewChild("anchor", { read: ViewContainerRef, static: true }) private viewContainerRef?: ViewContainerRef;
	private env = environment;
	private unsubscribe$$ = new Subject<void>();
	private maxNotification = 3;

	constructor(
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		public readonly changeDetectorRef: ChangeDetectorRef
	) { }


	public show<T>(component: Type<T>, ...args: SkipFirstParametrs<OverlayComponent["showByFactory"]>): ComponentRef<T> {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		return this.showByFactory(componentFactory, ...args);
	}

	public showByFactory<T>(componentFactory: ComponentFactory<T>, configuration?: {
		inputs?: { [key: string]: any };
		centerPosition?: boolean;
		injector?: Injector;
	}): ComponentRef<T> {
		if (!this.viewContainerRef)
			throw Error("No viewContainerRef");

		this.isCenter = configuration ? !!configuration.centerPosition : false;
		this.viewContainerRef.clear();
		const componentRef = this.viewContainerRef.createComponent(componentFactory, 0, configuration && configuration.injector);
		if (configuration)
			this.setInputs(componentRef, configuration);

		this.changeDetectorRef.markForCheck();
		return componentRef;
	}

	public showNotification$(message: string, type: "error" | "warning" | "success", delay?: number, notReload = false, changeZIndex = false): Promise<NotificationSequenceComponent> {
		return new Promise<NotificationSequenceComponent>((resolve): void => {
			if (!this.viewContainerRef)
				throw Error("No viewContainerRef");
			this.isCenter = false;
			if (this.notificationArray.length === this.maxNotification) {
				// if ntf count is the maximum - delete the latest ntf
				this.notificationArray.shift();
				this.viewContainerRef.remove(0);
			}
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationSequenceComponent);
			const componentRef = this.viewContainerRef.createComponent(componentFactory);
			this.notificationArray.push(componentRef);
			const subscription = componentRef.instance.close$.subscribe(
				() => {
					const index = this.viewContainerRef?.indexOf(componentRef.hostView) || 0;
					this.viewContainerRef && this.viewContainerRef.remove(index);
					this.notificationArray.splice(index, 1);
					if (index === this.notificationArray.length && this.notificationArray.length) {
						// show hidden ntf after deleting new
						this.setInputs(this.notificationArray[index - 1], { inputs: { style: "show" } });
					}
					if (this.notificationArray.length === 1) {
						this.setInputs(this.notificationArray[0], { inputs: { style: "show" } });
					}
					if (type === "warning" && !notReload) {
						window.location.reload();
					}
				});

			componentRef.onDestroy(() => subscription.unsubscribe());
			this.setInputs(componentRef, {
				inputs: {
					message, type,
					style: "show"
				}
			});
			if (this.notificationArray.length > 1) {
				const previousIndex = this.notificationArray.length - 2;
				this.setInputs(this.notificationArray[previousIndex], { inputs: { style: "hidden" } });
			}
			setTimeout(() => {
				// delete expired ntf
				const isExistInArray = this.notificationArray.includes(componentRef);
				componentRef.destroy();
				if (type === "warning" && !notReload) {
					window.location.reload();
				}
				if (isExistInArray) {
					this.notificationArray.shift();
					if (this.notificationArray.length) {
						// think
						this.setInputs(this.notificationArray[0], { inputs: { style: this.notificationArray.length === 1 ? "show" : "hidden" } });
					}
				}
			}, delay || this.env.project === "BM" ? 8000 : 5000);
			this.zIndex = changeZIndex;
			this.changeDetectorRef.markForCheck();
			resolve(componentRef.instance);
		});
	}

	public showConfirmation$(title: string, agreeButtonText?: string, disagreeButtonText?: string, withoutDisagree?: boolean, message?: string): Promise<boolean> {
		return new Promise<boolean>((resolve): void => {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConfirmationPopupComponent);
			const componentRef = this.showByFactory(componentFactory, {
				inputs: {
					title,
					agreeButtonText,
					disagreeButtonText,
					withoutDisagree,
					message,
				},
				centerPosition: true
			});
			componentRef.instance.appConfirm.pipe(
				takeUntil(this.unsubscribe$$)
			).subscribe(isAgree => {
				if (!this.viewContainerRef)
					throw Error("No viewContainerRef");
				this.viewContainerRef.clear();
				resolve(isAgree);
			});
			this.changeDetectorRef.markForCheck();
		});
	}

	public destroy(): void {
		if (!this.viewContainerRef)
			throw Error("No viewContainerRef");
		this.viewContainerRef.clear();
		this.changeDetectorRef.markForCheck();
	}

	@HostBinding("style.display")
	public get display(): string | undefined {
		return this.viewContainerRef && this.viewContainerRef.length > 0 ? undefined : "none";
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	private setInputs<T>(component: ComponentRef<T>, configuration: {
		inputs?: { [key: string]: any };
		injector?: Injector;
	}): void {
		if (configuration.inputs) {
			for (const key of Object.keys(configuration.inputs)) {
				(component.instance as any)[key] = configuration.inputs[key];
			}
			const onChanges = component.instance as any as OnChanges;
			if (onChanges.ngOnChanges) {
				const changes: SimpleChanges = {};
				for (const key of Object.keys(configuration.inputs)) {
					(onChanges as any)[key] = configuration.inputs[key];
					changes[key] = new SimpleChange(undefined, configuration.inputs[key], false);
				}
				onChanges.ngOnChanges(changes);
			}
		}
	}
}
