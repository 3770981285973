import { NgModule } from "@angular/core";
import { RouterModule as AngularRouterModule, Routes } from "@angular/router";

import { UserModule } from "../user/user.module";
import { RegistrationModule } from "../registration/registration.module";
import { RegistrationSimpleModule } from "@app/registration-simple/registration-simple.module";
import { BMEnvironmentGuard } from "@app/user/user-core/guardians/BM-environment.guard";
import { HelpModule } from "@app/user/customization/help/help.module";
import { MnsModule } from "@app/mns/mns.module";
import { CTTEnvironmentGuard } from "@app/user/user-core/guardians/CTT-environmnet.guard";
import { AdminGuard } from "@app/user/user-core/guardians/admin.guard";
import { AdminModule } from "../admin/admin.module";

const appRoutes: Routes = [
	{
		path: "user",
		loadChildren: (): Promise<UserModule> => import("../user/user.module").then((e): UserModule => e.UserModule)
	},
	{
		path: "gov",
		loadChildren: (): Promise<MnsModule> => import("../mns/mns.module").then((e): MnsModule => e.MnsModule)
	},
	{
		path: "registration-simple",
		canActivate: [BMEnvironmentGuard],
		loadChildren: (): Promise<RegistrationSimpleModule> => import("../registration-simple/registration-simple.module").then((e): RegistrationSimpleModule => e.RegistrationSimpleModule)
	},
	{
		path: "registration",
		canActivate: [CTTEnvironmentGuard],
		loadChildren: (): Promise<RegistrationModule> => import("../registration/registration.module").then((e): RegistrationModule => e.RegistrationModule)
	},
	{
		path: "admin",
		canActivate: [AdminGuard],
		loadChildren: (): Promise<AdminModule> => import("../admin/admin.module").then((e): AdminModule => e.AdminModule)
	},
	{
		path: "user/help",
		loadChildren: (): Promise<HelpModule> => import("../user/customization/help/help.module").then((e): HelpModule => e.HelpModule)
	},
	{
		path: "",
		redirectTo: "user/documents",
		pathMatch: "full"
	}
];

@NgModule({
	exports: [AngularRouterModule],
	imports: [AngularRouterModule.forRoot(appRoutes)],
	providers: [BMEnvironmentGuard, CTTEnvironmentGuard]
})
export class RouterModule { }
