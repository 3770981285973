import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Type } from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, map, take, takeUntil } from "rxjs/operators";
import { DefaultFormControl } from "@shared/default-form-control/default-form-control";
import { GeneralGLN } from "@helper/abstraction/generalGLN";
import { StorageCreateDto } from "@helper/abstraction/storages";

export interface SelectBoxSelfFetchState {
	[key: string]: any;
	page: number;
	size: number;
	search?: string;
}

export interface SelfFetchOption<T> {
	getInitialState: () => SelectBoxSelfFetchState;
	nextChunk: (state: SelectBoxSelfFetchState) => SelectBoxSelfFetchState; // if search exist, it must be considered
	getData$?: (state: SelectBoxSelfFetchState) => Observable<T>;
	mapData?: (data: T) => [any, string][];
}

@Component({
	selector: "app-storage-grid",
	templateUrl: "./storage-grid.component.html",
	styleUrls: ["./storage-grid.component.scss"],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef(<T>(): Type<StorageGridComponent<T>> => StorageGridComponent)
	}],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageGridComponent<T> extends DefaultFormControl implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input("option") public selfFetchOption: SelfFetchOption<T>;
	@Input() public updateList$?: Subject<void> = new Subject<void>();
	@Output() public appFormValue = new EventEmitter<Storage | GeneralGLN | StorageCreateDto>();
	public data: [any, string][] = [];
	public input$$ = new Subject<string>();
	private initialState: SelectBoxSelfFetchState = { page: 1, size: 30 };
	private state$$: BehaviorSubject<SelectBoxSelfFetchState>;
	private stateTracker$$?: Subject<void>;

	private defaultOption: SelfFetchOption<T> = {
		getInitialState: (): SelectBoxSelfFetchState => ({ ...this.initialState }),
		nextChunk: (state): SelectBoxSelfFetchState => ({ ...state, page: state.page + 1 })
	};

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
		public controlContainer: ControlContainer,
	) {
		super(controlContainer, changeDetectorRef);
		this.selfFetchOption = { ...this.defaultOption };
		this.state$$ = new BehaviorSubject<SelectBoxSelfFetchState>(this.selfFetchOption.getInitialState());
		this.input$$.pipe(
			debounceTime(250),
			takeUntil(this.unsubscribe$$)
		).subscribe(text => this.onInput(text));
	}

	public ngOnInit(): void {
		this.updateList$?.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => this.onInput(""));
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
			this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
		}
	}

	public ngAfterViewInit(): void {
		this.setData(this.data);
		this.unfold();
		this.changeDetectorRef.markForCheck();
	}

	public select(item: [any, string]): void {
		this.collapse();
		if (this.onChange)
			this.onChange(item[0]);
		this.appFormValue.emit(item[0]);
		this.changeDetectorRef.markForCheck();
	}

	public onInput(search: string): void {
		this.resetData();
		this.cleanStateTracker();
		const newState = { ...this.selfFetchOption.getInitialState(), search };
		this.state$$.next(newState);
		this.stateTracker$$ = this.trackState$$();
	}

	public onScrolled(): void {
		this.state$$.next(this.selfFetchOption.nextChunk(this.state$$.value));
	}

	public collapse(): void {
		this.cleanStateTracker();
		this.resetData();
	}

	public unfold(): void {
		this.state$$.next(this.selfFetchOption.getInitialState());
		this.stateTracker$$ = this.trackState$$();
	}

	public ngOnDestroy(): void {
		this.cleanStateTracker();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public trackState$$(): Subject<void> {
		const unsubscribe$$ = new Subject<void>();
		this.state$$.pipe(
			takeUntil(this.unsubscribe$$),
			takeUntil(unsubscribe$$)
		).subscribe(state => {
			if (!this.selfFetchOption.getData$)
				throw Error("No getData$ using for enrich date");

			this.selfFetchOption.getData$(state).pipe(
				take(1),
				map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
				takeUntil(this.unsubscribe$$),
				takeUntil(unsubscribe$$)
			).subscribe(data => {
				if (Array.isArray(data) && !data.length) {
					unsubscribe$$.next();
					unsubscribe$$.complete();
				}
				this.appendData(data);
				this.changeDetectorRef.detectChanges();
			});
		});
		return unsubscribe$$;
	}

	private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

	private resetData(): void {
		this.data = [];
	}

	private appendData(newData: [any, string][]): void {
		this.data = [...this.data, ...newData];
	}

	private setData(newData: [any, string][]): void {
		this.data = newData;
	}
}
