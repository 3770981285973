import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";

@Component({
	selector: "app-list-with-checkbox",
	templateUrl: "./list-with-checkbox.component.html",
	styleUrls: ["./list-with-checkbox.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListWithCheckboxComponent {
	@Input() public data: [any, string][] = [];
	@Input() public selectedElements: [any, string][] = [];
	@Output() public appSelect = new EventEmitter<{id: any; name: string}[]>();
	@Output() public appScrolled = new EventEmitter<void>();
	public list: any[] = [];
	public selectedItems: {index?: number; items?: {id: any; name: string}[]}[] = [];

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data){
			this.list = this.data.slice(0);
			if(!this.selectedItems.length){
				this.initSelectItems();
			}
		}
		this.updateSelectItems();
	}

	public onScroll(scrollHeight: number, scrollTop: number, height: number): void {
		if (scrollTop === 0)
			return;
		if ((scrollHeight - (height + scrollTop)) / scrollHeight <= 0)
			this.appScrolled.emit();
	}

	public selectAllItems(indexElement: number, items: any[]): void {
		if (!items.length) return;
		this.setNewListByIndex(indexElement, items);
	}

	public selectItem(event: MouseEvent, indexElement: number, item: any): void {
		event.stopPropagation();
		let itemsList = this.getSelectedItemsByIndex(indexElement);
		if(!itemsList){
			this.initSelectItems();
			itemsList = [];
		}
		if(!itemsList.find(el => el.id === item.id)){
			itemsList.push(item);
		}
		else{
			const newListItems = itemsList.filter(el => el.id !== item.id);
			itemsList = newListItems;
		}
		this.setNewListByIndex(indexElement, itemsList);
	}

	public checkedItem(indexElement: number, item: any): boolean {
		let itemsList = this.getSelectedItemsByIndex(indexElement);
		if(!itemsList){
			this.initSelectItems();
			itemsList = [];
		}

		if (itemsList.length){
			return itemsList.some((selectItem: any): boolean => selectItem.id === item.id);
		}
		else
			return false;
	}

	public getSelectedItemsByIndex(indexElement: number): any[] | undefined {
		return this.selectedItems.find(el => el.index === indexElement)?.items;
	}

	public clearSelectedItems(indexElement: number): void {
		this.setNewListByIndex(indexElement, []);
	}

	private initSelectItems(): void{
		this.list.forEach((_el, i) => {
			this.selectedItems.push({
				index: i,
				items: []
			});
		});
	}

	private setNewListByIndex(indexElement: number, itemsList: any[] | undefined): void {
		this.selectedItems.splice(indexElement, 1, {
			index: indexElement,
			items: itemsList
		});
		this.sendSelectedItems();
	}

	private sendSelectedItems(): void{
		const list: any[] = [];
		this.selectedItems.forEach(el => {
			if(el.items && el.items.length){
				Array.prototype.push.apply(list, el.items);
			}
		});
		this.appSelect.emit(list);
	}

	private updateSelectItems(): void{
		const arr: {index: number; items: any[]}[] = [];
		this.list.forEach((_el, i) => {
			arr.push({
				index: i,
				items: []
			});
		});
		this.selectedElements.forEach((element: any) => {
			this.list.forEach((item, i) => {
				if(item[0].items.find((ch: {id: any; name: string }) => ch.id === element.id)){
					if(!arr.length){
						arr.push({
							index: i,
							items: [],
						});
					}
					arr.find(el => el.index === i)?.items.push(element);
				}
			});
		});
		this.selectedItems = arr;
	}
}
