<div class="container">
	<input #input type="text" (input)="onInput(input.value)" placeholder="Поиск">
</div>
<ul #ul (scroll)="onScroll(ul.scrollHeight, ul.scrollTop, ul.clientHeight)">
	<li *ngFor="let item of list" (click)="select(item)">
		<span class="item-name">{{ item[0].storageName }}</span>
		<span class="item-address">{{ item[0].addressFull || item[0].address }}</span>
		<span class="item-gln">{{ item[0].gln }}</span>
	</li>
</ul>
